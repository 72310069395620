<!-- <p>CurrentPage {{ currentPage }}</p>
<p>pageSize {{ pageSize }}</p>
<p>totalRecords {{ totalRecords }}</p>
<p>MaxPage {{ getMaxPage() }}</p> -->
<nav *ngIf="totalRecords > pageSize" class="pager">
  <ul class="pagination">
    <!-- PREVIOUS PAGE-->
    <li class="page-item" [ngClass]="{ 'disabled': currentPage == 1}">
      <button
        [disabled]="currentPage == 1"
        (click)="setPage(+currentPage - 1)"
        class="page-link"
        aria-label="Previous"
      >
        <span aria-hidden="true">«</span>
      </button>
    </li>
    <li
      class="page-item"
      (click)="setPage(+currentPage - 1)"
      *ngIf="currentPage != 1"
    >
      <a class="page-link">{{ +currentPage - 1 }}</a>
    </li>
    <!-- <li *ngIf="currentPage == 1" class="page-item disabled"><a class="page-link disabled" >Previous</a></li> -->
    <li class="page-item" class="active">
      <button class="page-link">{{ +currentPage }}</button>
    </li>
    <li
      class="page-item"
      (click)="setPage(+currentPage + 1)"
      *ngIf="currentPage != getMaxPage()"
    >
      <button class="page-link">{{ +currentPage + 1 }}</button>
    </li>
    <li
      class="page-item"
      *ngIf="currentPage != getMaxPage() && getMaxPage() != currentPage + 1"
    >&nbsp;&nbsp;</li>
    <li
      class="page-item"
      (click)="setPage(getMaxPage())"
      *ngIf="currentPage != getMaxPage() && getMaxPage() != currentPage + 1"
    >
      <a class="page-link">{{ getMaxPage() }}</a>
      
    </li>
    <!-- NEXT PAGE-->
    <li class="page-item" [ngClass]="{ disabled: currentPage == getMaxPage() }">
      <button
        (click)="setPage(+currentPage + 1)"
        [disabled]="currentPage == getMaxPage()"
        class="page-link"
        aria-label="Next"
      >
        <span aria-hidden="true">»</span>
      </button>
    </li>
  </ul>
</nav>
