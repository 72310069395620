import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ContactRequest } from '../models/contact-request';
import { ShopService } from '../shop/shop.service';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(
    private readonly http: HttpClient,
    private readonly shopService: ShopService
    ) {}

  async sendContactRequest(request: ContactRequest): Promise<any> {
    request.shopId = this.shopService?.shopDetails?.id;
    return lastValueFrom(this.http.post(environment.API_URL +'/contact/admin', request));
  }
}
