import { Component, OnInit } from '@angular/core';
import { ContactService } from '../../services/contact/contact.service';
import { ContentService } from '../../services/content/content.service';
import { ShopService } from '../../services/shop/shop.service';
import { ToasterService } from '../../services/toaster/toaster.service';
import { ValidationService } from '../../services/validation/validation.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  constructor(
    private readonly contactService: ContactService,
    public readonly contentService: ContentService,
    private readonly validation: ValidationService,
    private readonly toasterService: ToasterService,
    public readonly shopService: ShopService
  ) {}

  name: string | null = null;
  phone: string | null = null;
  email: string | null = null;
  message: string | null = null;
  errorMessage: string | null = null;
  loading: boolean = false;
  capturePhone: boolean | undefined =
    this.contentService?.content?.contactFormSettings?.capturePhone;
  ngOnInit(): void {}

  sendContactRequest() {
    this.errorMessage = this.validateRequest();

    if (!this.errorMessage) {
      this.loading = true;
      this.contactService
        .sendContactRequest({
          name: this.name,
          phone: this.phone,
          email: this.email,
          message: this.message,
        })
        .then((response) => {
          console.log('successfully sent message', response);
          this.loading = false;
          this.clearInputs();
          this.toasterService.success(
            'Message sent',
            'We will get in contact with you soon',
            4000
          );
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage =
            'Oops, something went wrong. Please try again later.';
          console.error('Failed to send message: ', error);
        });
    }
  }

  validateRequest(): string | null {
    return this.validation.validateCustomerContactRequest(
      this.name,
      this.email,
      this.message,
      this.phone,
      this.contentService.content?.contactFormSettings.capturePhone || false
    );
  }

  clearInputs() {
    this.name = this.email = this.phone = this.message = null;
  }
}
