import { Component, Input } from '@angular/core';
import { ContentService } from '../../../services/content/content.service';
import { ShopContact } from '../../../services/models/shop-details';

@Component({
  selector: 'app-shop-address',
  templateUrl: './shop-address.component.html',
  styleUrls: ['./shop-address.component.scss'],
})
export class ShopAddressComponent {
  @Input('shopContact')
  shopContact: ShopContact | undefined;

  @Input('textColour')
  textColour: string = 'white';

  constructor(public contentService: ContentService) {}
}
