<app-login-card
  [loading]="loading"
  [errorMessage]="errorMessage"
  (login)="login($event)"
></app-login-card>
<div id="mobile-warning" class="container">
  <div class="row">
    <div class="col-md-12">
      <h3>
        Unfortunately, our merchant portal is not currently optimized for
        mobile. Please log in on a computer or tablet.
      </h3>
    </div>
  </div>
</div>
