<section
  *ngIf="!success"
  class="clean-block clean-form dark"
  style="min-height: 700px"
>
  <div class="container">
    <div class="block-heading">
      <h2 class="text-dark">Payment Cancelled.</h2>
      <i class="far fa-times-circle order-confirm-icon error"></i>
      <p>
        Your payment was cancelled. The order has also been cancelled. Should
        you wish to continue with a purchase please create a new order. For any
        more information or support contact us:
        <strong>{{ shopService.shopDetails?.shopContact?.adminEmail }}</strong>
      </p>
    </div>
  </div>
</section>

<section
  *ngIf="success"
  class="clean-block clean-form dark"
  style="min-height: 700px"
>
  <div class="container">
    <div class="block-heading">
      <h2 class="text-dark">Thank you for your order</h2>
      <i class="far fa-check-circle order-confirm-icon success"></i>
      <p>
        All the order details have been sent to the email address provided
        during checkout.&nbsp;
      </p>
      <button
        [ngStyle]="{
          'background-color':
            contentService.content?.colourPalette?.buttonBackground,
          color: contentService.content?.colourPalette?.buttonText
        }"
        [routerLink]="'/storefront'"
        class="btn btn-primary mt-5"
      >
        Continue Shopping
      </button>
    </div>
  </div>
</section>
