<div *ngIf="shopContact" class="shopContact">
  <p
    [ngStyle]="{
      color: textColour
    }"
  >
    {{ shopContact.addressLine1 | titlecase }}
  </p>
  <p
    [ngStyle]="{
      color: textColour
    }"
  >
    {{ shopContact.addressLine2 | titlecase }}
  </p>
  <p
    [ngStyle]="{
      color: textColour
    }"
  >
    {{ shopContact.postalCode }}
    {{ shopContact.province | titlecase }}
  </p>
  <p
    [ngStyle]="{
      color: textColour
    }"
  >
    {{
      shopContact.landingContactPhone
        ? "Tel: " + shopContact.landingContactPhone
        : ""
    }}
  </p>
</div>
