<section class="clean-block clean-cart dark">
  <div class="container">
    <div class="block-heading">
      <h2 class="text-dark">
        {{ orderCreated == true ? "Payment" : "Order Summary" }}
      </h2>
    </div>
    <div class="content">
      <div *ngIf="orderCreated" class="col-12 paystack-payment">
        <app-cart-summary
          [installationPrice]="checkoutService.calculateInstallationPrice()"
        ></app-cart-summary>
        <angular4-paystack
          [key]="shopService.shopDetails?.paymentAppKey || ''"
          [email]="checkoutService.order.clientInformation?.email || ''"
          [amount]="
            checkoutService.calculateOrderTotals().totalOrderDueNow * 100
          "
          [ref]="orderRef ? orderRef : '0'"
          [channels]="['card']"
          [class]="'btn btn-primary'"
          [currency]="'ZAR'"
          (onClose)="paymentCancel()"
          (callback)="paymentDone($event)"
          #paystack
        >
          <app-spinner *ngIf="loading"></app-spinner>
          <span *ngIf="!loading">Pay For Order</span>
        </angular4-paystack>
      </div>
      <div *ngIf="!orderCreated" class="row g-0">
        <div
          class="col-md-12 col-lg-8 offset-lg-0"
          style="padding-right: 30px; padding-left: 30px; padding-top: 30px"
        >
          <h3
            class="text-dark"
            style="margin-bottom: 12px; font-size: 22.752px"
          >
            Summary
          </h3>
          <div class="row">
            <div class="col d-inline-flex align-content-stretch">
              <span class="fw-bold">Customer Name</span>
            </div>
            <div class="col">
              <span>{{ checkoutService.order.clientInformation?.name }}</span>
            </div>
          </div>
          <div class="row" style="margin-top: 12px">
            <div class="col d-inline-flex align-content-stretch">
              <span class="fw-bold">Contact Number</span>
            </div>
            <div class="col">
              <span>{{ getPhone() }}</span>
            </div>
          </div>
          <div class="row" style="margin-top: 12px">
            <div class="col d-inline-flex align-content-stretch">
              <span class="fw-bold">Email</span>
            </div>
            <div class="col">
              <span>{{ checkoutService.order.clientInformation?.email }}</span>
            </div>
          </div>
          <div class="row" style="margin-top: 12px">
            <div class="col d-inline-flex align-content-stretch">
              <span class="fw-bold">Address</span>
            </div>
            <div class="col address">{{ getAddress() }}</div>
          </div>
          <div class="row" style="margin-top: 12px">
            <div class="col d-inline-flex align-content-stretch">
              <span class="fw-bold">Order Items</span>
            </div>
          </div>
          <div class="row" style="margin-top: 12px">
            <div class="col">
              <table class="w-100">
                <tr *ngFor="let item of checkoutService.cart.lineItems">
                  <td>
                    <img
                      *ngIf="
                        item.product.images && item.product.images.length > 0
                      "
                      class="summary-photo"
                      [src]="item.product.images[0].link"
                    />
                    <img
                      *ngIf="
                        !item.product.images || item.product.images.length == 0
                      "
                      class="summary-photo"
                      src="https://storage.googleapis.com/cypress-online-static/placeholder.png"
                    />
                  </td>
                  <td>{{ item.product.title }}({{ item.purchaseType }}):</td>
                  <td>
                    {{ item.quantity }} x
                    {{
                      (item.product.price || 0) * (item.quantity || 0)
                        | currency : "R"
                    }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="row">
            <p class="error">{{ errorMessage }}</p>
            <div class="col text-end mt-3 mb-3">
              <button
                [hidden]="orderCreated"
                (click)="createOrder()"
                class="btn btn-dark"
              >
                <span *ngIf="!loading">Confirm Order</span>
                <app-spinner *ngIf="loading"></app-spinner>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-4">
          <app-cart-summary
            [installationPrice]="checkoutService.calculateInstallationPrice()"
          ></app-cart-summary>
        </div>
      </div>
    </div>
  </div>
</section>
