import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Order } from '../models/order';
import { OrderSearchFilter } from '../models/order-search-filter';
import { Product } from '../models/product';
import { ProductSearchFilter } from '../models/product-search-filter';
import { ShopService } from '../shop/shop.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(
    private readonly http: HttpClient,
    private readonly shopService: ShopService
  ) {}
  apiUrl = environment.API_URL;

  getProducts(searchFilter: ProductSearchFilter): Observable<any> {
    if (!this.shopService?.shopDetails?.id) {
      console.error('Cannot get products for shop. No shop ID present.');
    } else {
      searchFilter.shopId = this.shopService?.shopDetails?.id;
    }
    return this.http.post(this.apiUrl + '/product', searchFilter);
  }

  createProduct(product: Product): any {
    return this.http.post(this.apiUrl + '/product/create', product);
  }

  updateProduct(product: Product): any {
    return this.http.patch(this.apiUrl + '/product', product);
  }

  deleteProduct(productId: string) {
    return this.http.delete(this.apiUrl + `/product/${productId}`);
  }

  getCategories(): Observable<any> {
    const shopId = this.getShopId();
    return this.http.post(this.apiUrl + '/category/search', { shopId });
  }

  private getShopId() {
    const shopId = this.shopService?.shopDetails?.id;
    if (!shopId) {
      console.error('Cannot get products for shop. No shop ID present.');
      throw new Error('No shop ID present.');
    }
    return shopId;
  }

  getProductById(id: string): Observable<Product> {
    return this.http.get<Product>(this.apiUrl + `/product/${id}`);
  }

  deleteCategory(categoryId: string) {
    return this.http.delete(this.apiUrl + `/category/${categoryId}`);
  }

  createCategory(categoryToAdd: string) {
    const shopId = this.getShopId();
    return this.http.post(this.apiUrl + `/category`, {
      name: categoryToAdd,
      shopId,
    });
  }

  getAllProductsStatic(): Product[] {
    return this.products;
  }

  getProductByIdStatic(id: string): Product | undefined {
    return this.products.find((product) => product.id === id);
  }

  products: Product[] = [
    {
      id: 'a3d510a6-1dc6-4470-a5c5-23fcfd0cc98d',
      productCode: 'IOT-100',
      title: 'IOT-100',
      subscriptionPrice: {
        12: 199,
        24: 149,
        36: 99,
      },
      buyNowPrice: {
        12: 1899,
        24: 2699,
        36: 3499,
      },
      description:
        'IOT-100 offers real-time vehicle tracking along with various  features such as remote fuel cut-off, geo-fence and  over-speed alerts. It can be used to track a personal vehicle  (car, motorcycle, scooter...) and is also suitable for fleet  management.',
      installationPrice: 300,
      minOrderQuantity: 1,
      maxOrderQuantity: 100,
      stockLevel: 'IN_STOCK',
    },
    {
      id: '315fff5c-b41c-4199-8c26-d409f0608d29',
      productCode: 'IOT-200',
      title: 'IOT-200',
      subscriptionPrice: {
        12: 199,
        24: 149,
        36: 99,
      },
      buyNowPrice: {
        12: 2099,
        24: 2799,
        36: 3599,
      },
      description:
        'IOT-200 is a self-powered asset tracker. This compact and  light device can be carried by a person, placed inside a  parcel or underneath a vehicle. It can include a strong  magnetic mount for various installation options.',
      minOrderQuantity: 1,
      maxOrderQuantity: 100,
      stockLevel: 'IN_STOCK',
    },
    {
      id: 'f7fb3c9a-a296-4f0f-9a6d-7468fae61c9c',
      productCode: 'IOT-220',
      title: 'IOT-220',
      subscriptionPrice: {
        12: 199,
        24: 149,
        36: 99,
      },
      buyNowPrice: {
        12: 1899,
        24: 2699,
        36: 3499,
      },
      description:
        'IOT-220 is an easy-to-install plug & play OBD port tracker  with a excellent location, tracking, reporting, logging, and  security capabilities. IOT-220 uses the GSM and GPRS  communication networks along with GPS technology,  making it an ideal solution for leased vehicles or large fleets.',
      installationPrice: 300,
      minOrderQuantity: 1,
      maxOrderQuantity: 100,
      stockLevel: 'IN_STOCK',
    },
    {
      id: '3a373296-a062-4a0b-b59f-8d54a0b303b7',
      productCode: 'IOT-400',
      title: 'IOT-400',
      subscriptionPrice: {
        12: 219,
        24: 159,
        36: 129,
      },
      buyNowPrice: {
        12: 2299,
        24: 3199,
        36: 3999,
      },
      description:
        "The advanced features. rugged construction, and industry-leading battery life make the AT 4 the new state of the art in asset GPS tracking. Using GPS and LBS positioning, the magnet-mounted AT4 gives detailed reports telling you exactly where a vehicle has been, where it's headed and how fast it's traveling. The internal mic allows for audio monitoring of the environment. With a 1 OOOOmAh battery and IPX5 waterproof rating, the AT4 puts you in total control of individual vehicles or fleets.",
      minOrderQuantity: 1,
      maxOrderQuantity: 100,
      stockLevel: 'IN_STOCK',
    },
    {
      id: 'dd60c739-5679-4713-997b-0c45a2d75044',
      productCode: 'IOT-501',
      title: 'IOT-501',
      subscriptionPrice: {
        12: 199,
        24: 149,
        36: 119,
      },
      buyNowPrice: {
        12: 1999,
        24: 2999,
        36: 3499,
      },
      description:
        'GPS, WiFi & LBS positioning , 2500 mAh rechargeable battery , Multiple alarms : low battery, vibration, overspeed, geo-fence , Ultra-thin body : 8mm.',
      minOrderQuantity: 1,
      maxOrderQuantity: 100,
      stockLevel: 'IN_STOCK',
    },
    {
      id: 'b472c4c3-24b5-4641-a320-4107448c3b6f',
      productCode: 'IOT-700',
      title: 'IOT-700',
      subscriptionPrice: {
        12: 199,
        24: 149,
        36: 99,
      },
      buyNowPrice: {
        12: 1899,
        24: 2699,
        36: 3499,
      },
      description:
        'IOT-700 is a discreet vehicle tracker integrated into a USB  cigarette lighter socker charger. The tracker provides  real-time positioning and multiple alerts such as overspeed  alerts and geofencing, ideal for individual vehicles tracking  or fleet management.',
      minOrderQuantity: 1,
      maxOrderQuantity: 100,
      stockLevel: 'IN_STOCK',
    },
    {
      id: '577fa381-4693-457c-b0d6-61c5c065f829',
      productCode: 'IOT-710',
      title: 'IOT-710',
      subscriptionPrice: {
        12: 199,
        24: 149,
        36: 99,
      },
      buyNowPrice: {
        12: 1699,
        24: 2199,
        36: 2499,
      },
      description:
        'IOT-710 is a compact and waterproof GPS tracker. The  device is self-powered and can be used to localize vehicles,  parcels, construction equipments... using a smartphone or a  computer.  It comes with a powerful magnetic mount and can be  installed almost everywhere.',
      installationPrice: 300,
      minOrderQuantity: 1,
      maxOrderQuantity: 100,
      stockLevel: 'IN_STOCK',
    },
    {
      id: '032f4243-567f-4a97-88f8-6533de92a66b',
      productCode: 'IOT-740',
      title: 'IOT-740',
      subscriptionPrice: {
        12: 169,
        24: 119,
      },
      buyNowPrice: {
        12: 1499,
        24: 1999,
      },
      description:
        'IOT-740 is a small size self-powered tracker with a battery  life of up to 36 months. Really easy to use and resistant, it  can be placed on almost all types of assets : vehicles,  containers, parcels ...  This tracker includes a powerful magnetic mount, for  multiple installation options.',
      installationPrice: 300,
      minOrderQuantity: 1,
      maxOrderQuantity: 100,
      stockLevel: 'IN_STOCK',
    },
    {
      id: '898b00b7-4887-44a2-8656-52adae23e256',
      productCode: 'IOT-911',
      title: 'IOT-911',
      subscriptionPrice: {
        36: 99,
      },
      buyNowPrice: {
        36: 3499,
      },
      description:
        'IOT-911 will give you a completely new level of awareness  and convenience. Loaded with GPS and WiFi positioning,    IOT-911 enables you to view real-time location from a com-  puter, tablet or smartphone. Being stylish, tough and accu-  rate, the IOT-911 is an ideal for kids, the elderly, and people    with special needs.',
      minOrderQuantity: 1,
      maxOrderQuantity: 100,
      stockLevel: 'IN_STOCK',
    },
  ];
}
