import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss'],
})
export class PagingComponent implements OnInit {
  constructor() {}

  @Input()
  currentPage!: number;
  @Input()
  totalRecords!: number;
  @Input()
  pageSize!: number;
  @Output()
  pageChange = new EventEmitter();

  ngOnInit(): void {}

  setPage(pageNumber: number) {
    window.scroll(0, 0);
    this.pageChange.emit(pageNumber.toString());
  }

  getMaxPage() {
    return Math.ceil(this.totalRecords / this.pageSize);
  }
}
