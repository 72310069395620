<div class="landing">
  <div
    class="hero-order"
    [style.order]="positioner.positions.get('hero')"
    (click)="emitEditItem('item-3')"
    [ngClass]="{ editable: editMode }"
    [@fadeIn]
    [@fadeOut]
    [@.disabled]="!editMode"
  >
    <div class="reorder">
      <i
        (click)="moveSectionUp('hero')"
        class="far fa-caret-square-up icon move-icon"
      ></i>
      <i
        (click)="moveSectionDown('hero')"
        class="far fa-caret-square-down icon move-icon"
      ></i>
    </div>
    <section
      *ngIf="
        content.heroSection?.enabled &&
        content.heroSection?.imageUrl &&
        positioner.positions.get('hero')
      "
      class="clean-block clean-hero"
      [ngStyle]="{
        'background-image': 'url(' + content.heroSection.imageUrl + ')',
        color: 'rgba(0, 0, 0, 0.65',
        'height.px': content.heroSection.height
          ? content.heroSection.height
          : 680,
        'background-position': content.heroSection.backgroundPosition,
        'z-index': 0
      }"
    >
      <div class=""></div>
      <div class="text">
        <h2
          [ngStyle]="{
            color: content.colourPalette.heroText
          }"
        >
          {{ content.heroSection.title }}
        </h2>
        <p
          [ngStyle]="{
            color: content.colourPalette.heroText
          }"
        >
          {{ content.heroSection.subtitle }}
        </p>
      </div>
    </section>
  </div>

  <!-- Services Section-->
  <section
    [style.order]="positioner.positions.get('services')"
    *ngIf="
      content.serviceSection?.enabled && positioner.positions.get('services')
    "
    (click)="emitEditItem('item-4')"
    [ngClass]="{ editable: editMode }"
    class="clean-block features"
    [@fadeIn]
    [@fadeOut]
    [@.disabled]="!editMode"
  >
    <div class="reorder">
      <i
        (click)="moveSectionUp('services')"
        class="far fa-caret-square-up icon move-icon"
      ></i>
      <i
        (click)="moveSectionDown('services')"
        class="far fa-caret-square-down icon move-icon"
      ></i>
    </div>
    <div class="container">
      <div class="block-heading">
        <h2 [ngStyle]="{ color: content.colourPalette.sectionTitle }">
          {{ content.serviceSection.title }}
        </h2>
      </div>
      <div class="row">
        <ng-container *ngFor="let service of content.serviceSection?.services">
          <div class="col-md-6 feature-box">
            <i
              [ngStyle]="{ color: content.colourPalette.icon }"
              [class]="service.icon + ' icon'"
            ></i>
            <h4 [ngStyle]="{ color: content.colourPalette.sectionTitle }">
              <strong>{{ service.title }}</strong>
            </h4>
            <p [ngStyle]="{ color: content.colourPalette.sectionText }">
              {{ service.description }}
            </p>
          </div>
        </ng-container>
      </div>
    </div>
  </section>
  <!-- Gallery Section-->
  <section
    [style.order]="positioner.positions.get('gallery')"
    *ngIf="
      content.gallerySection?.enabled && positioner.positions.get('gallery')
    "
    (click)="emitEditItem('item-5')"
    [ngClass]="{ editable: editMode }"
    class="clean-block features"
    [@fadeIn]
    [@fadeOut]
  >
    <div class="reorder">
      <i
        (click)="moveSectionUp('gallery')"
        class="far fa-caret-square-up icon move-icon"
      ></i>
      <i
        (click)="moveSectionDown('gallery')"
        class="far fa-caret-square-down icon move-icon"
      ></i>
    </div>
    <div
      *ngIf="
        content.gallerySection.slides.length > 0 ||
        content.gallerySection.title ||
        content.gallerySection.subtitle
      "
      class="block-heading"
    >
      <h2
        [ngStyle]="{ color: content.colourPalette.sectionTitle }"
        *ngIf="content.gallerySection.title"
      >
        {{ content.gallerySection.title }}
      </h2>
      <h6
        [ngStyle]="{ color: content.colourPalette.sectionText }"
        *ngIf="true || content.gallerySection.subtitle"
      >
        {{ content.gallerySection.subtitle }}
      </h6>
    </div>
    <div class="justify-content-center">
      <div class="w-100">
        <carousel
          [height]="content.gallerySection.height"
          [editMode]="editMode"
          [slides]="content.gallerySection.slides"
          [activeSlide]="activeSlide"
          [controlColour]="content.colourPalette.sectionText"
          [imageHeadlineColours]="content.colourPalette.galleryText"
        ></carousel>
      </div>
    </div>
  </section>
  <!-- featured products  Section-->
  <section
    [style.order]="positioner.positions.get('featuredProduct')"
    *ngIf="
      content.featuredProductsSection?.enabled &&
      positioner.positions.get('featuredProduct')
    "
    [ngClass]="{ editable: editMode }"
    (click)="emitEditItem('item-6')"
    class="clean-block dark featured-products"
    [@fadeIn]
    [@fadeOut]
    [@.disabled]="!editMode"
  >
    <div class="reorder">
      <i
        (click)="moveSectionUp('featuredProduct')"
        class="far fa-caret-square-up icon move-icon"
      ></i>
      <i
        (click)="moveSectionDown('featuredProduct')"
        class="far fa-caret-square-down icon move-icon"
      ></i>
    </div>
    <div class="container">
      <div class="block-heading">
        <h2
          [ngStyle]="{ color: content.colourPalette.sectionTitle }"
          style="margin-bottom: 19.2px; margin-top: 10px"
        >
          {{ content.featuredProductsSection.title }}<br />
        </h2>
        <p
          [ngStyle]="{ color: content.colourPalette.sectionText }"
          style="max-width: 555px"
        >
          {{ content.featuredProductsSection.subtitle }}
        </p>

        <div class="row justify-content-center">
          <ng-container
            *ngFor="
              let product of content.featuredProductsSection?.featuredProducts
            "
          >
            <div class="col-sm-6 col-lg-3 prod-col">
              <app-product-card
                [style.pointer-events]="editMode ? 'none' : 'auto'"
                [product]="product"
              ></app-product-card>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </section>
  <!-- About us section -->
  <section
    [style.order]="positioner.positions.get('about')"
    *ngIf="content.aboutUsSection.enabled && positioner.positions.get('about')"
    [ngClass]="{ editable: editMode }"
    (click)="emitEditItem('item-8')"
    class="pt-4 clean-block dark"
    [@fadeIn]
    [@fadeOut]
    [@.disabled]="!editMode"
  >
    <div class="reorder">
      <i
        (click)="moveSectionUp('about')"
        class="far fa-caret-square-up icon move-icon"
      ></i>
      <i
        (click)="moveSectionDown('about')"
        class="far fa-caret-square-down icon move-icon"
      ></i>
    </div>
    <app-about-us
      [title]="content.aboutUsSection.title"
      [content]="content.aboutUsSection.content"
      [imageHeight]="content.aboutUsSection.imageHeight"
      [image]="content.aboutUsSection.image"
      [layoutType]="content.aboutUsSection.layoutType"
      [style.pointer-events]="editMode ? 'none' : 'auto'"
      [textColour]="content.colourPalette.sectionText"
      [sectionTitleColour]="content.colourPalette.sectionTitle"
    ></app-about-us>
  </section>
</div>
