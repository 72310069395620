import { Component } from '@angular/core';
import { CheckoutService } from '../../../services/checkout/checkout.service';
import { DeliveryInformation } from '../../../services/models/order';
import { ShopService } from '../../../services/shop/shop.service';
import { ValidationService } from '../../../services/validation/validation.service';

@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss'],
})
export class AdditionalInformationComponent {
  constructor(
    public readonly checkoutService: CheckoutService,
    private readonly validation: ValidationService,
    public readonly shopService: ShopService
  ) {}

  errorMessage: string | null = null;
  ngOnInit(): void {}

  proceedToNextStep() {
    this.errorMessage = this.validateCustomField();
    if (!this.errorMessage) {
      this.checkoutService.checkoutStep = 4;
    }
  }

  validateCustomField(): string | null {
    if (
      this.shopService.shopDetails?.customField.enabled &&
      !this.shopService.shopDetails?.customField.optional
    ) {
      if (
        this.validation.isNullOrEmpty(
          this.checkoutService.order.customFieldValue
        )
      ) {
        return 'Please enter ' + this.shopService.shopDetails.customField.label;
      }
    }

    return null;
  }
}
