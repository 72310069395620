import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../services/content/content.service';
import { ShopService } from '../../services/shop/shop.service';

@Component({
  selector: 'app-shop-closed',
  templateUrl: './shop-closed.component.html',
  styleUrls: ['./shop-closed.component.scss'],
})
export class ShopClosedComponent implements OnInit {
  constructor(
    readonly shopService: ShopService,
    public readonly contentService: ContentService
  ) {}

  ngOnInit(): void {}
}
