<section class="clean-block clean-cart dark">
  <div class="container">
    <div class="block-heading">
      <h2 class="text-dark">Customer Information</h2>
    </div>
    <div class="content">
      <div class="row g-0">
        <div
          class="col-md-12 col-lg-8"
          style="padding-right: 30px; padding-left: 30px; padding-top: 30px"
        >
          <form class="text-dark border-dark">
            <div class="mb-3">
              <label class="form-label" for="name">Name</label
              ><input
                [(ngModel)]="clientInformation.name"
                class="form-control"
                placeholder="e.g. John"
                type="text"
                id="name"
                name="name"
              />
            </div>
            <div class="mb-3">
              <label class="form-label" for="surname">Surname</label
              ><input
                [(ngModel)]="clientInformation.surname"
                class="form-control"
                placeholder="e.g. Smith"
                type="text"
                id="surname"
                name="surname"
              />
            </div>
            <div class="mb-3">
              <label class="form-label" for="phone">Phone Number</label
              ><input
                [(ngModel)]="clientInformation.phone"
                class="form-control"
                type="text"
                placeholder="e.g. 0822222312"
                id="phone"
                name="phone"
              />
            </div>
            <div class="mb-3">
              <label class="form-label" for="email">Email</label
              ><input
                [(ngModel)]="clientInformation.email"
                class="form-control"
                type="email"
                placeholder="e.g. buyer@lots.com"
                id="email"
                name="email"
              />
            </div>
            <p class="error">{{ errorMessage }}</p>
            <div class="d-lg-flex justify-content-lg-end mb-3">
              <button
                class="btn btn-dark"
                role="button"
                (click)="proceedToNextStep()"
                style="width: 177.1719px"
              >
                Next
              </button>
            </div>
          </form>
        </div>
        <div class="col-md-12 col-lg-4">
          <app-cart-summary
            [installationPrice]="checkoutService.calculateInstallationPrice()"
          ></app-cart-summary>
        </div>
      </div>
    </div>
  </div>
</section>
