import { Component, OnInit } from '@angular/core';
import { CheckoutService } from '../../../services/checkout/checkout.service';
import { DeliveryInformation } from '../../../services/models/order';
import { ShopService } from '../../../services/shop/shop.service';
import { ValidationService } from '../../../services/validation/validation.service';

@Component({
  selector: 'app-delivery-info-form',
  templateUrl: './delivery-info-form.component.html',
  styleUrls: ['./delivery-info-form.component.scss'],
})
export class DeliveryInfoFormComponent implements OnInit {
  constructor(
    public readonly checkoutService: CheckoutService,
    private readonly validation: ValidationService,
    public readonly shopService: ShopService
  ) {}

  errorMessage: string | null = null;
  deliveryInformation: DeliveryInformation = new DeliveryInformation();
  ngOnInit(): void {}

  proceedToNextStep() {
    this.errorMessage = this.validation.validateDeliveryAddress(
      this.deliveryInformation
    );
    if (!this.errorMessage) {
      this.checkoutService.order.deliveryInformation = this.deliveryInformation;

      this.nextStep();
    }
  }
  nextStep() {
    if (this.shopService.shopDetails?.customField.enabled) {
      this.checkoutService.checkoutStep = 3;
    } else {
      this.checkoutService.checkoutStep = 4;
    }
  }
}
