import { animate, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { take } from 'rxjs';
import { ContentService } from '../../services/content/content.service';
import { Product } from '../../services/models/product';
import { ProductSearchFilter } from '../../services/models/product-search-filter';
import {
  FeaturedProduct,
  GallerySlide,
  Service,
  ShopTemplateContent,
} from '../../services/models/shop-template-content';
import { ProductService } from '../../services/product/product.service';
import { ToasterService } from '../../services/toaster/toaster.service';
import { ActivatedRoute, Router } from '@angular/router';

const enterTransition = transition(':enter', [
  style({
    opacity: 0,
  }),
  animate('0.5s ease', style({ opacity: 1 })),
]);
const exitTransition = transition(':leave', [
  style({
    opacity: 1,
  }),
  animate('0.5s ease', style({ opacity: 0 })),
]);
const fadeIn = trigger('fadeIn', [enterTransition]);
const fadeOut = trigger('fadeOut', [exitTransition]);

@Component({
  selector: 'app-storefront-editor',
  templateUrl: './storefront-editor.component.html',
  styleUrls: ['./storefront-editor.component.scss'],
  animations: [fadeIn, fadeOut],
})
export class StorefrontEditorComponent implements OnInit, AfterViewInit {
  // Accordion item buttons - applies to different sections of landing and footer
  @ViewChild('item2') item2!: ElementRef;
  @ViewChild('item3') item3!: ElementRef;
  @ViewChild('item4') item4!: ElementRef;
  @ViewChild('item5') item5!: ElementRef;
  @ViewChild('item6') item6!: ElementRef;
  @ViewChild('item7') item7!: ElementRef;
  @ViewChild('item8') item8!: ElementRef;
  @ViewChild('item9') item9!: ElementRef;

  // View children for the icon pickers
  @ViewChildren('iconPickerInput')
  iconPickerInputs!: QueryList<ElementRef>;

  constructor(
    public readonly contentService: ContentService,
    private readonly productService: ProductService,
    private readonly toaster: ToasterService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {}

  loading: boolean = false;
  filesLoading: boolean = false;
  saved: boolean = true;
  productList: Product[] = [];
  colour: string = '';
  MAX_GALLERY_SLIDES = 5;
  MAX_HEIGHT_LIMITS = 1000;
  activePage: 'landing' | 'contact' = 'landing';
  hideContact = false;
  activeSlide: number = 0;

  ngOnInit(): void {
    this.fetchProducts();
  }

  ngAfterViewInit(): void {
    this.checkForScrollAndActiveTab();
  }

  checkForScrollAndActiveTab(): void {
    this.route.queryParams.subscribe((params) => {
      const { activeTab, scrollTo } = params;
      if (activeTab && activeTab !== 'null') {
        Promise.resolve().then(() => {
          if (activeTab === 'featured-products') {
            this.editItemClicked('item-6');
          }
        });
      }

      if (scrollTo && scrollTo !== 'null') {
        this.scrollToElement(scrollTo);
      }
    });
  }

  scrollToElement(className: string): void {
    const element = document.querySelector(`.${className}`);

    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }
  }

  saveContent(): void {
    if (this.saved) {
      this.toaster.success(
        'All is up to date',
        `No changes to be saved.`,
        4000
      );
      return;
    }

    this.loading = true;
    this.contentService
      .saveShopContent()
      .then((res) => {
        this.contentService.content = res;
        this.loading = false;
        this.saved = true;
        this.toaster.success(
          'Content updated',
          `Updated storefront content.`,
          4000
        );
      })
      .catch((err) => {
        this.loading = false;
        this.toaster.error(
          'Failed to update storefront',
          `Contact system admin for help`
        );
        console.error('Failed to update storefront content', err);
      });
  }

  resetContent(): void {
    if (confirm('Are you sure you want to discard unsaved changes?')) {
      this.loading = true;
      this.saved = true;
      this.contentService
        .refreshShopContent()
        .then((content: ShopTemplateContent) => {
          this.contentService.content = content;
          this.loading = false;
          this.toaster.success('Changes are discarded.', 'Content Reset', 4000);
          console.log('content updated', content);
        })
        .catch((error) => {
          this.loading = false;
          this.toaster.error(
            'Failed to refresh shop content',
            `Contact system admin for help`
          );
          console.error('Failed to refresh shop content', error);
        });
    }
  }

  logoUpload(imageUrl: string) {
    this.dirtyContext();
    this.contentService.content.logo = imageUrl;
    this.toaster.success('Logo Selected', 'Logo selected successfully', 4000);
    this.filesLoading = false;
  }

  async faviconUpload(imageUrl: string) {
    this.dirtyContext();
    this.contentService.content.favicon = imageUrl;
    this.toaster.success(
      'Favicon uploaded',
      'Favicon uploaded successfully',
      4000
    );
    this.filesLoading = false;
  }

  heroUpload(imageUrl: string) {
    this.dirtyContext();
    this.contentService.content.heroSection.imageUrl = imageUrl;
    this.toaster.success(
      'Hero Image uploaded',
      'Hero Image uploaded successfully',
      4000
    );
    this.filesLoading = false;
  }

  featuredProductImageUpload(imageUrl: string, index: number) {
    this.dirtyContext();
    this.contentService.content.featuredProductsSection.featuredProducts[
      index
    ].productImage = imageUrl;
    this.toaster.success(
      'Product Image uploaded',
      'Product Image uploaded successfully',
      4000
    );
    this.filesLoading = false;
  }

  gallerySlideImageUpload(imageUrl: string, index: number) {
    this.dirtyContext();
    this.contentService.content.gallerySection.slides[index].src = imageUrl;
    this.toaster.success(
      'Gallery Image uploaded',
      'Gallery Image uploaded successfully',
      4000
    );
    this.filesLoading = false;
  }

  aboutUsUpload(imageUrl: string) {
    this.dirtyContext();
    this.contentService.content.aboutUsSection.image = imageUrl;
    this.toaster.success(
      'About Us Image uploaded',
      'About Us Image uploaded successfully',
      4000
    );
    this.filesLoading = false;
  }

  addFeaturedProduct() {
    this.dirtyContext();
    this.contentService.content.featuredProductsSection.featuredProducts.push(
      new FeaturedProduct()
    );
  }

  addService() {
    this.dirtyContext();
    this.contentService.content.serviceSection.services.push(new Service());
  }

  addGallerySlide() {
    const maxed = this.checkForMaxSlides();
    if (maxed) {
      return;
    }
    this.dirtyContext();
    this.contentService.content.gallerySection.slides.push(new GallerySlide());
    this.contentService.content.colourPalette.galleryText.push('#FFFFFF');
  }

  checkForMaxSlides() {
    if (
      this.contentService.content.gallerySection.slides.length >=
      this.MAX_GALLERY_SLIDES
    ) {
      this.toaster.error(
        'Maximum number of slides reached',
        'You are only allowed ' + this.MAX_GALLERY_SLIDES + ' slides'
      );
      return true;
    }
    return false;
  }

  fetchProducts() {
    const filter = new ProductSearchFilter();
    filter.pageSize = 10000;
    this.productService
      .getProducts(filter)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.productList = res.products;
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  removeFeaturedProduct(object: FeaturedProduct) {
    this.dirtyContext();
    this.contentService.content.featuredProductsSection.featuredProducts =
      this.contentService.content.featuredProductsSection.featuredProducts.filter(
        (obj: any) => obj !== object
      );
  }

  removeService(service: Service) {
    this.dirtyContext();
    this.contentService.content.serviceSection.services =
      this.contentService.content.serviceSection.services.filter(
        (obj: any) => obj !== service
      );
  }

  removeGallerySlide(slide: GallerySlide) {
    this.dirtyContext();
    const index =
      this.contentService.content.gallerySection.slides.indexOf(slide);
    this.contentService.content.colourPalette.galleryText =
      this.contentService.content.colourPalette.galleryText.filter(
        (val: any, i: any) => i !== index
      );
    this.contentService.content.gallerySection.slides =
      this.contentService.content.gallerySection.slides.filter(
        (obj: any) => obj !== slide
      );
  }

  onIconPickerSelect(icon: string, index: number) {
    this.dirtyContext();
    this.contentService.content.serviceSection.services[index].icon = icon;
  }

  dirtyContext() {
    this.saved = false;
  }

  editItemClicked(section: string) {
    if (section === 'item-2') {
      this.openAccordion(this.item2.nativeElement);
      return;
    }
    if (section === 'item-3') {
      this.openAccordion(this.item3.nativeElement);
      return;
    }
    if (section === 'item-4') {
      this.openAccordion(this.item4.nativeElement);
      return;
    }
    if (section === 'item-5') {
      this.openAccordion(this.item5.nativeElement);
      return;
    }
    if (section === 'item-6') {
      this.openAccordion(this.item6.nativeElement);

      return;
    }

    if (section === 'item-7') {
      this.openAccordion(this.item7.nativeElement);

      return;
    }

    if (section === 'item-8') {
      this.openAccordion(this.item8.nativeElement);
      return;
    }

    if (section === 'item-9') {
      this.openAccordion(this.item9.nativeElement);
      return;
    }
  }
  openAccordion(nativeElement: any) {
    if (nativeElement.getAttribute('class').indexOf('collapsed') !== -1) {
      nativeElement.click();
    }
  }

  toggleHeroSection() {
    this.contentService.content.heroSection.enabled =
      !this.contentService.content.heroSection.enabled;
  }

  toggleServiceSection() {
    this.contentService.content.serviceSection.enabled =
      !this.contentService.content.serviceSection.enabled;
  }

  toggleFeaturedSection() {
    this.contentService.content.featuredProductsSection.enabled =
      !this.contentService.content.featuredProductsSection.enabled;
  }

  toggleGallerySection() {
    this.contentService.content.gallerySection.enabled =
      !this.contentService.content.gallerySection.enabled;
  }

  toggleAboutUsSection() {
    this.contentService.content.aboutUsSection.enabled =
      !this.contentService.content.aboutUsSection.enabled;
  }

  toggleShowShopName() {
    this.dirtyContext();
    this.contentService.content.showShopName =
      !this.contentService.content.showShopName;
  }

  toggleShopAddress() {
    this.dirtyContext();
    this.contentService.content.footerSection.showShopAddress =
      !this.contentService.content.footerSection.showShopAddress;
  }

  preview() {
    if (confirm('Changes need to be published before viewing. Continue?')) {
      this.router.navigateByUrl('/storefront');
    }
  }

  restrictHeights() {
    this.contentService.content.heroSection.height = this.restrict(
      this.contentService.content.heroSection.height
    );
    this.contentService.content.gallerySection.height = this.restrict(
      this.contentService.content.gallerySection.height
    );
    this.contentService.content.aboutUsSection.imageHeight = this.restrict(
      this.contentService.content.aboutUsSection.imageHeight
    );
  }

  public restrict(height: any): number {
    if (height && height > this.MAX_HEIGHT_LIMITS) {
      return this.MAX_HEIGHT_LIMITS;
    }
    return height;
  }

  async changeEditorView(event: Event) {
    const targetVal = (event.target as HTMLSelectElement).value;
    if (targetVal !== 'landing' && targetVal !== 'contact') {
      throw new Error('Invalid target');
    }
    this.activePage = targetVal;
    if (this.activePage == 'contact') {
      await new Promise((r) => setTimeout(r, 100));
      this.openAccordion(this.item9.nativeElement);
    }
  }

  async changeCapturePhone() {
    this.hideContact = true;
    await new Promise((r) => setTimeout(r, 100));
    this.hideContact = false;
    this.dirtyContext();
  }

  clickedIconPreview(indexOfElement: number) {
    // Trigger the click event on the specific input element by its index
    const inputElement = this.iconPickerInputs.toArray()[indexOfElement];
    inputElement.nativeElement.click();
  }

  editingSlide(index: number) {
    this.activeSlide = index;
  }

  preventUserInput(event: any): void {
    event.preventDefault();
  }
}
