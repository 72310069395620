<section class="clean-block clean-form dark">
  <div class="container">
    <div class="block-heading">
      <h2 class="text-dark">Log In</h2>
    </div>
    <form class="border-dark">
      <div class="mb-3">
        <label class="form-label" for="email">Email</label
        ><input
          [(ngModel)]="email"
          name="email"
          class="form-control item"
          type="email"
          id="email"
        />
      </div>
      <div class="mb-3">
        <label class="form-label" for="password">Password</label
        ><input
          [(ngModel)]="password"
          name="password"
          class="form-control"
          type="password"
          id="password"
        />
      </div>
      <a [routerLink]="'/forgot-password'">Forgot password?</a>
      <div class="mb-3"></div>
      <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
      <button
        class="btn btn-dark w-100"
        role="button"
        [disabled]="loading"
        (click)="login()"
        [ngStyle]="{
          'background-color':
            contentService.content?.colourPalette?.buttonBackground,
          color: contentService.content?.colourPalette?.buttonText
        }"
      >
        {{ loading ? "Loading..." : "Log In" }}
      </button>
      <!-- <button
      class="btn btn-dark w-100"
      role="button"
      [disabled]="loading"
      (click)="loginTest()"
      >{{ loading ? 'Loading...' : 'Test'}}</button
    > -->
    </form>
  </div>
</section>
