<section class="clean-block clean-form dark pt-5">
  <div class="container">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title"><strong>My Orders</strong></h4>
        <div class="order-search-controls mb-4">
          <div
            class="d-flex align-items-center input-group-text-input border-50 search-box mt-4">
            <input
              (change)="changeTerm($event)"
              class="no-border me-2"
              style="flex: 1;"
              type="text"
              placeholder="Search by email or reference number..."
            />
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.9 17.3L10.3 11.7C9.8 12.1 9.225 12.4167 8.575 12.65C7.925 12.8833 7.23333 13 6.5 13C4.68333 13 3.146 12.3707 1.888 11.112C0.63 9.85333 0.000666667 8.316 0 6.5C0 4.68333 0.629333 3.146 1.888 1.888C3.14667 0.63 4.684 0.000666667 6.5 0C8.31667 0 9.854 0.629333 11.112 1.888C12.37 3.14667 12.9993 4.684 13 6.5C13 7.23333 12.8833 7.925 12.65 8.575C12.4167 9.225 12.1 9.8 11.7 10.3L17.325 15.925C17.5083 16.1083 17.6 16.3333 17.6 16.6C17.6 16.8667 17.5 17.1 17.3 17.3C17.1167 17.4833 16.8833 17.575 16.6 17.575C16.3167 17.575 16.0833 17.4833 15.9 17.3ZM6.5 11C7.75 11 8.81267 10.5623 9.688 9.687C10.5633 8.81167 11.0007 7.74933 11 6.5C11 5.25 10.5623 4.18733 9.687 3.312C8.81167 2.43667 7.74933 1.99933 6.5 2C5.25 2 4.18733 2.43767 3.312 3.313C2.43667 4.18833 1.99933 5.25067 2 6.5C2 7.75 2.43767 8.81267 3.313 9.688C4.18833 10.5633 5.25067 11.0007 6.5 11Z" fill="#EF4136"/>
            </svg>
          </div>
          <div class="status-filter">
            <div
              class="d-flex align-items-center input-group-text-input border-50 search-box">
              <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 12C7.71667 12 7.479 11.904 7.287 11.712C7.095 11.52 6.99934 11.2827 7 11C7 10.7167 7.096 10.479 7.288 10.287C7.48 10.095 7.71734 9.99934 8 10H10C10.2833 10 10.521 10.096 10.713 10.288C10.905 10.48 11.0007 10.7173 11 11C11 11.2833 10.904 11.521 10.712 11.713C10.52 11.905 10.2827 12.0007 10 12H8ZM1 2C0.71667 2 0.479003 1.904 0.287003 1.712C0.0950034 1.52 -0.000663206 1.28267 3.46021e-06 1C3.46021e-06 0.71667 0.0960036 0.479004 0.288004 0.287004C0.480004 0.0950036 0.717337 -0.000663206 1 3.4602e-06H17C17.2833 3.4602e-06 17.521 0.0960036 17.713 0.288004C17.905 0.480004 18.0007 0.717337 18 1C18 1.28334 17.904 1.521 17.712 1.713C17.52 1.905 17.2827 2.00067 17 2H1ZM4 7C3.71667 7 3.479 6.904 3.287 6.712C3.095 6.52 2.99934 6.28267 3 6C3 5.71667 3.096 5.479 3.288 5.287C3.48 5.095 3.71734 4.99934 4 5H14C14.2833 5 14.521 5.096 14.713 5.288C14.905 5.48 15.0007 5.71734 15 6C15 6.28334 14.904 6.521 14.712 6.713C14.52 6.905 14.2827 7.00067 14 7H4Z" fill="#EF4136"/>
              </svg>
              <select
                [(ngModel)]="selectedStatus"
                (change)="changeStatus()"
              >
                <option [value]="null">All Statuses</option>
                <option value="WAITING_FOR_PAYMENT">Waiting For Payment</option>
                <option value="PAID">Paid</option>
                <option value="SHIPPING">Shipping</option>
                <option value="DELIVERED">Delivered</option>
                <option value="CANCELLED">Cancelled</option>
              </select>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Order Ref</th>
                <th>Date</th>
                <th>Email</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Amount P.M.</th>
              </tr>
            </thead>
            <tbody>
              <tr class="loader-track" *ngIf="loading">
                <app-spinner></app-spinner>
              </tr>
              <ng-container *ngIf="!loading">
                <tr
                  class="order-track"
                  *ngFor="let order of orders"
                  [routerLink]="['/order-detail', order.id]"
                >
                  <td class="id-row" class="text-center pr-2">
                    <p *ngIf="order.testMode">
                      <span class="badge bg-secondary">Test Order</span>
                    </p>
                    {{ order.orderRef }}
                  </td>
                  <td class="date-row">
                    {{
                      isOrderPlacedToday(order)
                        ? "Today " + (order.createdAt | date : "HH:mm")
                        : (order.createdAt | date : "YYYY-MM-dd HH:mm")
                    }}
                  </td>
                  <td class="email-row">
                    {{ order.clientInformation?.email }}
                  </td>
                  <td class="status-column">
                    <i
                      *ngIf="orderService.orderPaymentNotCompleted(order)"
                      class="fas fa-exclamation-triangle icon error"
                    ></i>
                    <span
                      class="badge"
                      [ngClass]="getBadgeClasses(order.orderStatus)"
                      >{{
                        order.orderStatus?.replace(regReplace, " ") | titlecase
                      }}</span
                    >
                  </td>
                  <td style="text-align: center">
                    {{ order.totalDueNow | currency : "R" }}
                  </td>
                  <td style="text-align: right">
                    {{ order.totalDueMonthly | currency : "R" }}
                  </td>
                </tr>
              </ng-container>
              <h2 class="p-3" *ngIf="!loading && orders.length == 0">
                No orders to display
              </h2>
            </tbody>
          </table>
        </div>
      </div>
      <app-paging
        class="m-auto"
        *ngIf="!loading"
        [currentPage]="searchOrderFilter.page"
        [totalRecords]="count"
        [pageSize]="searchOrderFilter.pageSize"
        (pageChange)="setPage($event)"
      ></app-paging>
    </div>
  </div>
</section>
