<div class="container py-4 py-xl-5">
  <div class="row gy-4 row-cols-2 row-cols-md-4">
    <div class="col">
      <div
        class="text-center d-flex flex-column justify-content-center align-items-center py-3"
      >
        <div
          class="bs-icon-xl bs-icon-circle bs-icon-primary-light d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="currentColor"
            viewBox="0 0 16 16"
            class="bi bi-cash-stack"
          >
            <path
              d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
            ></path>
            <path
              d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"
            ></path>
          </svg>
        </div>
        <div class="px-3">
          <h2 class="fw-bold mb-0">R 23 210</h2>
          <p class="mb-0">Month Earnings</p>
        </div>
      </div>
    </div>
    <div class="col">
      <div
        class="text-center d-flex flex-column justify-content-center align-items-center py-3"
      >
        <div
          class="bs-icon-xl bs-icon-circle bs-icon-primary-light d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="currentColor"
            viewBox="0 0 16 16"
            class="bi bi-credit-card"
          >
            <path
              d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"
            ></path>
            <path
              d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"
            ></path>
          </svg>
        </div>
        <div class="px-3">
          <h2 class="fw-bold mb-0">34</h2>
          <p class="mb-0">Total Subscriptions</p>
        </div>
      </div>
    </div>
    <div class="col">
      <div
        class="text-center d-flex flex-column justify-content-center align-items-center py-3"
      >
        <div
          class="bs-icon-xl bs-icon-circle bs-icon-primary-light d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="currentColor"
            viewBox="0 0 16 16"
            class="bi bi-cart2"
          >
            <path
              d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"
            ></path>
          </svg>
        </div>
        <div class="px-3">
          <h2 class="fw-bold mb-0">89</h2>
          <p class="mb-0">Orders this month</p>
        </div>
      </div>
    </div>
    <div class="col">
      <div
        class="text-center d-flex flex-column justify-content-center align-items-center py-3"
      >
        <div
          class="bs-icon-xl bs-icon-circle bs-icon-primary-light d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"
        >
          <i class="fas fa-times"></i>
        </div>
        <div class="px-3">
          <h2 class="fw-bold mb-0">4</h2>
          <p class="mb-0">Cancellations</p>
        </div>
      </div>
    </div>
  </div>
</div>
