<app-customer-info-form
  *ngIf="checkoutService.checkoutStep == 1"
></app-customer-info-form>

<app-delivery-info-form
  *ngIf="checkoutService.checkoutStep == 2"
></app-delivery-info-form>

<app-additional-information
  *ngIf="checkoutService.checkoutStep == 3"
></app-additional-information>

<app-order-summary
  *ngIf="checkoutService.checkoutStep == 4"
></app-order-summary>
