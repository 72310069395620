import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FeedbackFormSubmission } from '../../components/feedback-form/feedback-submission';
import { ShopService } from '../shop/shop.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private readonly http: HttpClient) {}

  async sendUserFeedback(feedback: FeedbackFormSubmission): Promise<any> {
    return lastValueFrom(
      this.http.post(environment.API_URL + '/feedback', feedback)
    );
  }
}
