import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { lastValueFrom, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Order } from '../models/order';
import { OrderSearchFilter } from '../models/order-search-filter';
import { ShopService } from '../shop/shop.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(
    private readonly http: HttpClient,
    private readonly shopService: ShopService
  ) {}
  apiUrl = environment.API_URL;

  getOrders(searchFilter: OrderSearchFilter): Observable<any> {
    if (!this.shopService?.shopDetails?.id) {
      console.error('Cannot get orders for shop. No shop ID present.');
    } else {
      searchFilter.shopId = this.shopService?.shopDetails?.id;
    }
    return this.http.post(this.apiUrl + '/order', searchFilter);
  }

  getOrderById(id: string): Observable<Order> {
    return this.http.get<Order>(this.apiUrl + `/order/${id}`);
  }

  updateOrderStatus(id: string, status: string) {
    return this.http.patch(this.apiUrl + `/order/${id}/status`, {
      status: status,
    });
  }

  saveTrackingLink(id: string, trackingLink: string | null) {
    return lastValueFrom(
      this.http.patch(this.apiUrl + `/order/${id}/tracking-link`, {
        trackingLink,
      })
    );
  }

  paymentCancelled(orderId: string) {
    return lastValueFrom(
      this.http.get(this.apiUrl + `/payment/cancel/${orderId}`)
    );
  }

  requestPayment(orderId: string) {
    return lastValueFrom(
      this.http.get(this.apiUrl + `/payment/request/${orderId}`)
    );
  }

  isOrderPlacedToday(order: Order | null) {
    if (order?.createdAt) {
      const date = new Date(Date.parse(order?.createdAt));
      return (
        new Date().toISOString().slice(0, 10) ===
        date.toISOString().slice(0, 10)
      );
    }
    return false;
  }

  orderPaymentNotCompleted(order: Order) {
    var d = order.createdAt;
    if (!moment(d).isValid()) return;
    if (
      moment(d).add(1, 'hour').isBefore(/*now*/) &&
      order.orderStatus === 'WAITING_FOR_PAYMENT'
    ) {
      return true;
    }
    return false;
  }
}
