import { Component, OnInit } from '@angular/core';
import { debounce, Subject, take, timer } from 'rxjs';
import { Order } from '../../services/models/order';
import { OrderSearchFilter } from '../../services/models/order-search-filter';
import { OrderService } from '../../services/order/order.service';

@Component({
  selector: 'app-order-overview',
  templateUrl: './order-overview.component.html',
  styleUrls: ['./order-overview.component.scss'],
})
export class OrderOverviewComponent implements OnInit {
  constructor(public readonly orderService: OrderService) {}

  searchOrderFilter: OrderSearchFilter = new OrderSearchFilter();
  searchTerm: Subject<string> = new Subject();
  selectedStatus: string | null = null;
  orders: Order[] = [];
  count: number = 0;
  loading: boolean = false;
  regReplace = /\_/gi;
  ngOnInit(): void {
    this.getOrders();
  }

  getOrders() {
    this.loading = true;
    this.orderService
      .getOrders(this.searchOrderFilter)
      .pipe(
        take(1),
        debounce(() => timer(500))
      )
      .subscribe({
        next: (res) => {
          this.orders = res.orders;
          this.count = res.count;
          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          console.error(error);
        },
      });
  }

  setPage(event: number) {
    this.searchOrderFilter.page = +event;
    this.getOrders();
  }

  getBadgeClasses(orderStatus: string | undefined) {
    if (!orderStatus) {
      return [];
    }

    return {
      'bg-primary': orderStatus == 'WAITING_FOR_PAYMENT',
      'bg-dark': orderStatus == 'PAID',
      'bg-danger': orderStatus == 'CANCELLED',
      'bg-warning': orderStatus == 'SHIPPING',
      'bg-success': orderStatus == 'DELIVERED',
    };
  }

  changeTerm(term: any) {
    const value = term?.target?.value;
    if (value && value !== '') {
      this.searchOrderFilter.page = 1;
      this.searchOrderFilter.searchTerm = value;
      this.getOrders();
    }

    if (value == '') {
      this.searchOrderFilter.searchTerm = null;
      this.getOrders();
    }
  }

  isOrderPlacedToday(order: Order) {
    return this.orderService.isOrderPlacedToday(order);
  }

  changeStatus() {
    this.searchOrderFilter.status =
      this.selectedStatus == 'null' ? null : this.selectedStatus;
    this.getOrders();
  }
}
