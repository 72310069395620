import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product/product.service';
import { lastValueFrom } from 'rxjs';
import { Category, Product } from '../../services/models/product';
import { ProductSearchFilter } from '../../services/models/product-search-filter';
import { ShopService } from '../../services/shop/shop.service';

@Component({
  selector: 'app-product-catalogue',
  templateUrl: './product-catalogue.component.html',
  styleUrls: ['./product-catalogue.component.scss'],
})
export class ProductCatalogueComponent implements OnInit {
  categories: Category[] = [];
  products: Product[] = [];
  searchProductFilter: ProductSearchFilter = new ProductSearchFilter();
  count: number = 0;
  loading: boolean = true;

  constructor(
    private productService: ProductService,
    public readonly shopService: ShopService
  ) {}

  ngOnInit(): void {
    this.getProducts();
    this.getCategories();
  }

  // Fetch the categories
  async getCategories() {
    try {
      const categories = await lastValueFrom(
        this.productService.getCategories()
      );
      this.categories = categories;
    } catch (error) {
      console.error(error);
    }
  }

  // fetch products whilst filtering by category, searchterm and sortOrder
  async getProducts() {
    this.loading = true;
    const response = await lastValueFrom(
      this.productService.getProducts(this.searchProductFilter)
    );
    this.products = response.products;
    this.count = response.count;
    this.loading = false;
  }

  changeSort($event: Event) {
    const value = ($event.target as HTMLSelectElement).value;
    this.searchProductFilter.sort = value;
    this.getProducts();
  }

  setPage(event: number) {
    this.searchProductFilter.page = +event;
    this.getProducts();
  }

  getImage(product: Product) {
    return product.images && product.images.length > 0
      ? product.images[0].link
      : 'https://storage.googleapis.com/cypress-online-static/placeholder.png';
  }

  changeCategory(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.checked) {
      this.searchProductFilter.categories.indexOf(target.value) === -1 &&
        this.searchProductFilter.categories.push(target.value);
    } else {
      this.searchProductFilter.categories.indexOf(target.value) !== -1 &&
        this.searchProductFilter.categories.splice(
          this.searchProductFilter.categories.indexOf(target.value),
          1
        );
    }
    this.getProducts();
  }

  changeTerm($event: Event) {
    const value = ($event.target as HTMLSelectElement).value;
    if (value && value !== '') {
      this.searchProductFilter.title = value;
      this.getProducts();
    }

    if (value == '') {
      this.searchProductFilter.title = null;
      this.getProducts();
    }
  }

  changeOnlyInStock($event: Event) {
    const value = ($event.target as HTMLInputElement).checked;
    this.searchProductFilter.onlyInStock = value;
    this.getProducts();
  }
}
