import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { CheckoutService } from '../../services/checkout/checkout.service';
import { LineItem } from '../../services/models/cart';
import { Product } from '../../services/models/product';
import { ProductService } from '../../services/product/product.service';
import { ShopService } from '../../services/shop/shop.service';
import { ToasterService } from '../../services/toaster/toaster.service';
import { TrackingService } from '../../services/tracking/tracking.service';

@Component({
  selector: 'app-product-detail-standard',
  templateUrl: './product-detail-standard.component.html',
  styleUrls: ['./product-detail-standard.component.scss'],
})
export class ProductDetailStandardComponent implements OnInit {
  constructor(
    private activateRoute: ActivatedRoute,
    private productService: ProductService,
    public checkoutService: CheckoutService,
    private toasterService: ToasterService,
    private shopService: ShopService,
    private readonly trackingService: TrackingService
  ) {}

  product?: Product;
  purchaseType: 'BUY-NOW' | 'SUBSCRIPTION' = 'SUBSCRIPTION';
  quantity: number = 1;
  errorMessage: string | null = null;
  loading: boolean = false;
  ngOnInit(): void {
    this.activateRoute.paramMap.subscribe((paramMap) => {
      const id = paramMap.get('id');
      if (id) {
        this.loading = true;
        this.productService
          .getProductById(id)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              this.product = res;
              this.loading = false;
            },
            error: (error) => {
              this.loading = false;
              console.error(error);
            },
          });
      }
    });
  }

  clickAddProductToCartStandard() {
    this.errorMessage = this.validateAddProduct();

    if (this.errorMessage) {
      this.toasterService.error('Failed to add product', this.errorMessage);
      return;
    }
    this.checkoutService.addLineItem({
      product: this.product || new Product(),
      purchaseType: this.product?.type || this.purchaseType,
      quantity: this.quantity,
    });
    this.trackingService.trackProductAddedToCart(
      this.product?.productCode || 'none'
    );
    this.toasterService.success(
      'Product added',
      'Product added to cart successfully',
      4000
    );
  }

  validateAddProduct(): string | null {
    const maxNumberInCart = this.moreThanMaxForCart();
    const minNumberInCart = this.lessThanMinForCart();

    if (!this.quantity) {
      return `Please enter a quantity between ${this.product?.minOrderQuantity} and ${this.product?.maxOrderQuantity}`;
    } else if (maxNumberInCart) {
      return `You cannot have more than ${this.product?.maxOrderQuantity} of one product in the cart, you already have: ${maxNumberInCart}`;
    } else if (minNumberInCart) {
      return `You must order at least ${this.product?.minOrderQuantity} of this product.`;
    } else if (!this.product) {
      return 'Error product null.';
    }

    return null;
  }

  moreThanMaxForCart() {
    const lineItem: LineItem | null = this.getLineItemFromProduct();
    return lineItem
      ? this.checkoutService.cart.maxForCartReached(lineItem)
      : null;
  }

  lessThanMinForCart() {
    const lineItem: LineItem | null = this.getLineItemFromProduct();
    return lineItem
      ? this.checkoutService.cart.lessThanMinForLineItem(lineItem)
      : null;
  }

  getLineItemFromProduct(): LineItem | null {
    if (!this.product) {
      return null;
    }
    return {
      product: this.product,
      purchaseType: this.product.type || 'BUY-NOW',
      quantity: this.quantity,
    };
  }

  getShopCheckoutEnabled(): boolean {
    return this.shopService?.shopDetails?.checkoutEnabled || false;
  }

  getDeliveryFee(): number {
    return this.shopService.getDeliveryFee();
  }

  isOutOfStock(): any {
    return this.product?.stockLevel === 'NO_STOCK';
  }
}
