import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProductDetailComponent } from './views/product-detail/product-detail.component';
import { ContactComponent } from './views/contact/contact.component';
import { LoginComponent } from './views/login/login.component';
import { CheckoutComponent } from './views/checkout/checkout.component';
import { PaymentStatusComponent } from './views/payment-status/payment-status.component';
import { OrderOverviewComponent } from './views/order-overview/order-overview.component';
import { OrderDetailComponent } from './views/order-detail/order-detail.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { LoginCardComponent } from './components/login-card/login-card.component';
import { CustomerInfoFormComponent } from './components/checkout/customer-info-form/customer-info-form.component';
import { DeliveryInfoFormComponent } from './components/checkout/delivery-info-form/delivery-info-form.component';
import { OrderSummaryComponent } from './components/checkout/order-summary/order-summary.component';
import { PaymentResultComponent } from './components/checkout/payment-result/payment-result.component';
import { PagingComponent } from './components/common/paging/paging.component';
import { BusinessStatsComponent } from './components/business-stats/business-stats.component';
import { LandingComponent } from './views/landing/landing.component';
import { CartComponent } from './views/cart/cart.component';
import { CartSummaryComponent } from './components/checkout/cart-summary/cart-summary.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CartEditComponent } from './components/cart-edit/cart-edit.component';
import { ToastNoAnimationModule, ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { authInterceptorProviders } from './interceptors/auth.interceptor';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { StorefrontEditorComponent } from './views/storefront-editor/storefront-editor.component';
import { FooterComponent } from './components/footer/footer.component';
import { ShopNotFoundComponent } from './components/shop-not-found/shop-not-found.component';
import { ShopClosedComponent } from './components/shop-closed/shop-closed.component';
import { ShopLockedComponent } from './components/shop-locked/shop-locked.component';
import { AdminProductListComponent } from './views/admin-product-list/admin-product-list.component';
import { AdminProductDetailComponent } from './views/admin-product-detail/admin-product-detail.component';
import { AdminShopSettingsComponent } from './views/admin-shop-settings/admin-shop-settings.component';
import { ProductDetailTraxsitComponent } from './components/product-detail-traxsit/product-detail-traxsit.component';
import { ProductDetailStandardComponent } from './components/product-detail-standard/product-detail-standard.component';
import { ShopSettingsComponent } from './components/shop-settings/shop-settings.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ConfirmPasswordComponent } from './components/confirm-password/confirm-password.component';
import { IconPickerModule } from 'ngx-icon-picker';
import { FeedbackFormComponent } from './components/feedback-form/feedback-form.component';
import { FeedbackComponent } from './views/feedback/feedback.component';
import { FeedbackBannerComponent } from './components/feedback-banner/feedback-banner.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CarouselComponent } from './components/carousel/carousel.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { AdditionalInformationComponent } from './components/checkout/additional-information/additional-information.component';
import { ShopAddressComponent } from './components/common/shop-address/shop-address.component';
import { ImageSelectorComponent } from './components/image-selector/image-selector.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Angular4PaystackModule } from 'angular4-paystack';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { environment } from '../environments/environment';
import { SeoSettingsComponent } from './components/seo-settings/seo-settings.component';
import { ProductCatalogueViewComponent } from './views/product-catalogue-view/product-catalogue-view.component';
import { ProductCatalogueComponent } from './components/product-catalogue/product-catalogue.component';
import { WelcomeViewComponent } from './views/welcome-view/welcome-view.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { WelcomeFloaterComponent } from './components/welcome-floater/welcome-floater.component';
import { HelpComponent } from './views/help/help.component';
@NgModule({
  declarations: [
    AppComponent,
    ProductDetailComponent,
    ContactComponent,
    LoginComponent,
    CheckoutComponent,
    PaymentStatusComponent,
    CartSummaryComponent,
    OrderOverviewComponent,
    OrderDetailComponent,
    ProductCardComponent,
    ContactFormComponent,
    LoginCardComponent,
    CustomerInfoFormComponent,
    DeliveryInfoFormComponent,
    OrderSummaryComponent,
    PaymentResultComponent,
    PagingComponent,
    BusinessStatsComponent,
    LandingComponent,
    CartComponent,
    CartEditComponent,
    SpinnerComponent,
    StorefrontEditorComponent,
    FooterComponent,
    ShopNotFoundComponent,
    ShopClosedComponent,
    ShopLockedComponent,
    AdminProductListComponent,
    AdminProductDetailComponent,
    AdminShopSettingsComponent,
    ProductDetailTraxsitComponent,
    ProductDetailStandardComponent,
    ShopSettingsComponent,
    ForgotPasswordComponent,
    ConfirmPasswordComponent,
    FeedbackFormComponent,
    FeedbackComponent,
    FeedbackBannerComponent,
    CarouselComponent,
    AboutUsComponent,
    AdditionalInformationComponent,
    ShopAddressComponent,
    ImageSelectorComponent,
    SeoSettingsComponent,
    ProductCatalogueViewComponent,
    ProductCatalogueComponent,
    WelcomeViewComponent,
    WelcomeComponent,
    WelcomeFloaterComponent,
    HelpComponent,
  ],
  imports: [
    BrowserModule,
    ColorPickerModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    IconPickerModule,
    BrowserAnimationsModule,
    ToastNoAnimationModule.forRoot(),
    Angular4PaystackModule.forRoot('pk_test_123'),
    FontAwesomeModule,
    NgbModule,
    NgxGoogleAnalyticsModule.forRoot(environment.gaTrackingCode),
  ],
  providers: [authInterceptorProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
