import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { take } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { LoginEvent } from '../../services/models/events/login-event';
import { ShopService } from '../../services/shop/shop.service';
import { TrackingService } from '../../services/tracking/tracking.service';
import { ShopTaskService } from '../../services/shop-task/shop-task.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private readonly auth: AuthService,
    private readonly router: Router,
    private readonly shopService: ShopService,
    private readonly taskService: ShopTaskService,
    private readonly trackingService: TrackingService
  ) {}

  loading: boolean = false;
  errorMessage: string | null = null;
  ngOnInit(): void {
    this.checkForToken();
  }

  login(loginEvent: LoginEvent) {
    this.errorMessage = null;
    this.loading = true;
    this.auth
      .login(loginEvent.email, loginEvent.password)
      .pipe(take(1))
      .subscribe({
        next: async (result) => {
          await this.taskService.getShopTasks();
          const route = await this.resolveRoute();
          this.trackingService.trackLogin(loginEvent.email || 'none');
          this.loading = false;
          this.router.navigateByUrl(route);
          console.log(result);
        },
        error: (error) => {
          this.errorMessage = 'Please check credentials and try again';
          console.error(error);
          this.loading = false;
        },
      });
  }

  private async resolveRoute() {
    await this.taskService.getShopTasks();
    const incompleteTasks = this.taskService.shopTasks.some(
      (task) => task.status === 'NOT_STARTED'
    );
    if (incompleteTasks) {
      return 'welcome';
    }
    return this.shopService.shopDetails?.checkoutEnabled
      ? '/order-overview'
      : 'editor';
  }

  checkForToken() {
    const expiration: moment.Moment | null = this.auth.getExpiration();
    if (expiration && expiration.isAfter(new Date())) {
      this.router.navigateByUrl('/order-overview');
    }
  }
}
