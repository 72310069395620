import { Injectable } from '@angular/core';
import { FeedbackFormSubmission } from '../../components/feedback-form/feedback-submission';
import { ClientInformation, DeliveryInformation } from '../models/order';
import { Product } from '../models/product';
import { ShopDetails } from '../models/shop-details';
import { ShopTemplateContent } from '../models/shop-template-content';
import { ShopService } from '../shop/shop.service';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor() {}

  emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  productTypes = ['BUY-NOW', 'SUBSCRIPTION'];

  validateEmail(email: string | null) {
    if (!email || !this.emailRegex.test(email.toLowerCase())) {
      return 'Please enter a valid email.';
    }
    return null;
  }

  validateCustomerInfo(clientInformation: ClientInformation): string | null {
    if (!clientInformation.name || clientInformation.name.trim().length <= 2) {
      return 'Please enter a name.';
    } else if (
      !clientInformation.surname ||
      clientInformation.surname.trim().length <= 2
    ) {
      return 'Please enter a surname.';
    } else if (
      !clientInformation.phone ||
      clientInformation.phone.trim().length <= 2
    ) {
      return 'Please enter a phone number.';
    } else if (
      !clientInformation.email ||
      !this.emailRegex.test(clientInformation.email.toLowerCase())
    ) {
      return 'Please enter a valid email.';
    }
    return null;
  }

  validateDeliveryAddress(
    deliveryInformation: DeliveryInformation
  ): string | null {
    if (
      !deliveryInformation.addressLine1 ||
      deliveryInformation.addressLine1.trim().length < 10
    ) {
      return 'Please enter an address line 1 of at least 10 characters.';
    } else if (
      !deliveryInformation.addressLine2 ||
      deliveryInformation.addressLine2.trim().length < 5
    ) {
      return 'Please enter an address line 2 of at least 5 characters.';
    } else if (!deliveryInformation.city) {
      return 'Please enter a city.';
    } else if (!deliveryInformation.postcode) {
      return 'Please enter a postcode.';
    } else if (!deliveryInformation.suburb) {
      return 'Please enter a suburb.';
    }

    return null;
  }

  validateCustomerContactRequest(
    name: string | null,
    email: string | null,
    message: string | null,
    phone: string | null,
    phoneEnabled: boolean
  ) {
    if (!name || name.trim().length <= 2) {
      return 'Please enter a name.';
    } else if (!email || !this.emailRegex.test(email.toLowerCase())) {
      return 'Please enter a valid email.';
    } else if (!message || message.trim().length <= 10) {
      return 'Please enter a message longer than 10 characters.';
    } else if (phoneEnabled && (!phone || phone.trim().length < 10)) {
      return 'Please enter a valid phone number.';
    }
    return null;
  }

  validProduct(product: Product | null) {
    if (!product) {
      return 'Product not initialized';
    } else if (this.isNullOrEmpty(product.productCode)) {
      return 'Please enter a valid product code.';
    } else if (this.isNullOrEmpty(product.title)) {
      return 'Please enter a valid title.';
    } else if (this.isNullOrEmpty(product.stockLevel)) {
      return 'Please enter a stock level.';
    } else if (
      !product.price ||
      +product.price <= 0 ||
      +product.price >= 1000000
    ) {
      return 'Please enter a valid, postitive price value less than 1 000 000';
    } else if (
      product.installationPrice &&
      (+product.installationPrice < 0 || +product.installationPrice >= 1000000)
    ) {
      return 'Please enter a valid, postitive installation price value less than 1 000 000';
    } else if (!product.type || this.productTypes.indexOf(product.type) == -1) {
      return 'Please enter a valid product type';
    } else if (
      !!product.descriptionPdf &&
      !this.isValidUrl(product.descriptionPdf)
    ) {
      return 'Please enter a valid URL for description PDF';
    } else if (
      !!product.additionaInfoPdf &&
      !this.isValidUrl(product.additionaInfoPdf)
    ) {
      return 'Please enter a valid URL for additional info PDF';
    }

    return null;
  }

  isValidUrl(url: string) {
    return (
      url.length > 5 &&
      (url.indexOf('http') !== -1 || url.indexOf('https') !== -1)
    );
  }

  isValidShopDetails(shopDetails: ShopDetails) {
    if (!shopDetails.name) {
      return 'Please enter a name.';
    } else if (
      !shopDetails.shopContact.adminEmail ||
      !this.emailRegex.test(shopDetails.shopContact.adminEmail.toLowerCase())
    ) {
      return 'Please enter a valid admin email.';
    } else if (!shopDetails.status) {
      return 'Please enter a valid status.';
    } else if (
      shopDetails.status === 'LOCKED' &&
      !shopDetails.storefrontPassword
    ) {
      return 'Please enter password for the locked shop or unlock the shop.';
    } else if (
      shopDetails.checkoutEnabled &&
      (!shopDetails.paymentAppId || !shopDetails.paymentAppKey)
    ) {
      return 'Please connect your PayStack account or disable checkout.';
    } else if (
      shopDetails.customField.enabled &&
      (!shopDetails.customField.label ||
        !shopDetails.customField.description ||
        !shopDetails.customField.value)
    ) {
      return 'Please enter a label, description and value for the custom field or disable it.';
    } else if (
      shopDetails.deliveryFlatRate === null ||
      shopDetails.deliveryFlatRate < 0
    ) {
      return 'Please enter a valid delivery rate.';
    }
    return null;
  }

  isNullOrEmpty(text: string | undefined | null) {
    return (
      text == undefined ||
      text == 'undefined' ||
      !text ||
      text.trim().length < 1
    );
  }

  validatePassword(password: string | null) {
    if (this.isNullOrEmpty(password)) {
      return 'Please enter a password.';
    } else if (!this.passwordRegex.test(password!)) {
      return 'Your password must be at least 8 characters long and contain a number and special character.';
    }
    return null;
  }

  validateFeedback(feedback: FeedbackFormSubmission) {
    if (!feedback.type) {
      return 'Please select a type.';
    } else if (
      !feedback.description ||
      feedback.description.trim().length < 10
    ) {
      return 'Please enter a description of at least 10 characters.';
    }
    return null;
  }

  objectHasAllValues(object: any) {
    for (let key of object.keys()) {
      if (object[key] === undefined) {
        return false;
      }
    }

    return true;
  }

  isValidPaymentDetails(
    paymentAppId: string | null,
    paymentAppKey: string | null
  ) {
    if (
      this.isNullOrEmpty(paymentAppId) ||
      this.isNullOrEmpty(paymentAppKey) ||
      !paymentAppId ||
      paymentAppId.length < 5 ||
      !paymentAppKey ||
      paymentAppKey.length < 5
    ) {
      return 'Please enter paystack credentials.';
    } else {
      return null;
    }
  }

  validateTrackingLink(trackingLink: any) {
    if (trackingLink === null || trackingLink === '') {
      return null;
    } else if (!this.isValidUrl(trackingLink)) {
      return 'Please enter a valid tracking link url.';
    }
    return null;
  }
}
