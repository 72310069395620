import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShopTemplateContent } from '../models/shop-template-content';
import { environment } from '../../../environments/environment';
import { lastValueFrom, Observable } from 'rxjs';
import { ShopService } from '../shop/shop.service';
@Injectable({
  providedIn: 'root',
})
export class ContentService {
  constructor(
    private readonly http: HttpClient,
    private readonly shopService: ShopService
  ) {}
  apiUrl = environment.API_URL;
  content: ShopTemplateContent | any = null;
  editingMode: boolean = false;
  async initializeShopTemplateContent(shopId: string): Promise<void> {
    if (!shopId) {
      console.error('Cannot get template for shop, no shop id present');
      throw new Error('Cannot get template for shop');
    }

    this.content = await lastValueFrom(
      this.http.get<ShopTemplateContent>(this.apiUrl + `/content/${shopId}`)
    );
  }

  refreshShopContent() {
    return lastValueFrom(
      this.http.get<ShopTemplateContent>(
        this.apiUrl +
          `/content/${this.shopService.shopDetails?.id}?skipCache=true`
      )
    );
  }

  async saveShopContent() {
    return lastValueFrom(
      this.http.patch<ShopTemplateContent>(
        this.apiUrl + `/content`,
        this.content
      )
    );
  }

  async uploadFile(file: any): Promise<Object> {
    if (!file) {
      throw new Error('No file selected');
    }
    if (file.size >= 3000000) {
      throw new Error('Image too large. Max image size is 3mb');
    }
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return lastValueFrom(
      this.http.post(this.apiUrl + `/content/upload-file`, formData)
    );
  }

  unsplashImageSearch(searchTerm: string, page: number): Observable<any> {
    return this.http.post<any>(this.apiUrl + `/content/search-unsplash`, {
      page,
      searchTerm,
    });
  }
}
