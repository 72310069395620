export class FeaturedProduct {
  productTitle!: string;
  productSubtitle!: string;
  productImage!: string;
  productId: string | null = null;
}

class FeaturedProductsSection {
  enabled!: boolean;
  position!: number;
  title!: string;
  subtitle!: string;
  featuredProducts!: FeaturedProduct[];
}
class FooterSection {
  privacyPolicyLink!: string;
  termsAndConditionsLink!: string;
  additionalText!: string;
  twitterProfileLink!: string;
  facebookProfileLink!: string;
  instagramProfileLink!: string;
  showShopAddress!: boolean;
}

export class Service {
  icon!: string;
  title!: string;
  description!: string;
}
class ServiceSection {
  enabled!: boolean;
  position!: number;
  services!: Service[];
  title!: string;
  subtitle!: string;
}

class HeroSection {
  enabled!: boolean;
  position!: number;
  type!: 'textOver' | 'textUnder';
  height?: number = 680;
  imageUrl!: string;
  title!: string;
  subtitle!: string;
  backgroundPosition!: 'top' | 'center' | 'bottom';
}

export class ContactFormSettings {
  capturePhone!: boolean;
  contactFormTitle!: string;
  contactFormSubtitle!: string;
  additionalText!: string;
}

export class ColourPalette {
  sectionTitle!: string;
  sectionText!: string;
  icon!: string;
  buttonBackground!: string;
  buttonText!: string;

  navBackground!: string;
  navText!: string;
  heroText!: string;
  galleryText!: string[];
  footerBackground!: string;
  footerText!: string;
}

export class GallerySlide {
  headline?: string;
  fit?: 'cover' | 'contain' | 'fill' = 'cover';
  src: string =
    'https://storage.googleapis.com/cypress-online-static/placeholder.png';
}
export class GallerySection {
  enabled!: boolean;
  position!: number;
  title?: string;
  subtitle?: string;
  height?: number;
  slides!: GallerySlide[];
}

class AboutUsSection {
  enabled!: boolean;
  position!: number;
  layoutType!:
    | 'image-right'
    | 'image-left'
    | 'image-bottom'
    | 'image-top'
    | 'no-image';
  image!: string;
  imageHeight!: number;
  content!: string;
  title!: string;
  contactCallToActionTitle!: string;
}

export class ShopTemplateContent {
  _id!: string;
  shopId!: string;
  logo!: string;
  favicon!: string;
  colourPalette!: ColourPalette;
  heroSection!: HeroSection;
  serviceSection!: ServiceSection;
  gallerySection!: GallerySection;
  featuredProductsSection!: FeaturedProductsSection;
  aboutUsSection!: AboutUsSection;
  footerSection!: FooterSection;
  contactFormSettings!: ContactFormSettings;
  showShopName!: boolean;
}
