<app-spinner *ngIf="loading"></app-spinner>

<section class="clean-block clean-form dark">
  <div class="container">
    <div class="block-heading">
      <h2 class="text-dark">Confirm New Password</h2>
    </div>
    <form class="border-dark">
      <div class="mb-3">
        <label class="form-label" for="password">New Password</label
        ><input
          [(ngModel)]="password"
          name="password"
          class="form-control item"
          type="password"
          id="password"
        />
      </div>
      <div class="mb-3">
        <label class="form-label" for="confirmPassword"
          >Confirm New Password</label
        ><input
          [(ngModel)]="confirmPassword"
          name="confirmPassword"
          class="form-control item"
          type="password"
          id="confirmPassword"
        />
      </div>
      <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
      <button
        class="btn btn-dark w-100"
        role="button"
        [disabled]="loading"
        (click)="confirmPasswordRequest()"
        [ngStyle]="{
          'background-color':
            contentService.content?.colourPalette?.buttonBackground,
          color: contentService.content?.colourPalette?.buttonText
        }"
      >
        {{ loading ? "Loading..." : "Change Password" }}
      </button>
    </form>
  </div>
</section>
