<footer
  [ngStyle]="{
    'background-color': contentService.content?.colourPalette?.footerBackground
  }"
  (click)="editItem.emit('item-7')"
  [ngClass]="{ editable: editMode }"
  class="page-footer"
>
  <div class="container">
    <div class="footer-container">
      <div class="legal">
        <ng-container *ngIf="hasAtLeastOneSocialMediaLink()">
          <h5
            [ngStyle]="{
              color: contentService.content?.colourPalette?.footerText
            }"
          >
            Social Media
          </h5>
          <ul [style.pointer-events]="editMode ? 'none' : 'auto'">
            <li>
              <a
                *ngIf="
                  contentService.content?.footerSection?.instagramProfileLink
                "
                [ngStyle]="{
                  color: contentService.content?.colourPalette?.footerText
                }"
                target="_blank"
                [href]="
                  contentService.content?.footerSection?.instagramProfileLink
                "
              >
                <i class="fab fa-instagram icon move-icon"></i>
              </a>
              <a
                *ngIf="
                  contentService.content?.footerSection?.facebookProfileLink
                "
                [ngStyle]="{
                  color: contentService.content?.colourPalette?.footerText
                }"
                target="_blank"
                [href]="
                  contentService.content?.footerSection?.facebookProfileLink
                "
              >
                <i class="fab fa-facebook icon move-icon"></i>
              </a>
              <a
                *ngIf="
                  contentService.content?.footerSection?.twitterProfileLink
                "
                [ngStyle]="{
                  color: contentService.content?.colourPalette?.footerText
                }"
                target="_blank"
                [href]="
                  contentService.content?.footerSection?.twitterProfileLink
                "
              >
                <i class="fab fa-twitter icon move-icon"></i>
              </a>
            </li>
          </ul>
        </ng-container>

        <h5
          [ngStyle]="{
            color: contentService.content?.colourPalette?.footerText
          }"
        >
          Legal
        </h5>
        <ul [style.pointer-events]="editMode ? 'none' : 'auto'">
          <li
            *ngIf="
              contentService.content?.footerSection?.termsAndConditionsLink
            "
          >
            <a
              [ngStyle]="{
                color: contentService.content?.colourPalette?.footerText
              }"
              target="_blank"
              [href]="
                contentService.content?.footerSection?.termsAndConditionsLink
              "
              >Terms of Service</a
            >
          </li>
          <li *ngIf="contentService.content?.footerSection?.privacyPolicyLink">
            <a
              [ngStyle]="{
                color: contentService.content?.colourPalette?.footerText
              }"
              target="_blank"
              [href]="contentService.content?.footerSection?.privacyPolicyLink"
              >Privacy Policy</a
            >
          </li>
        </ul>
      </div>
      <div class="address">
        <app-shop-address
          *ngIf="contentService.content?.footerSection?.showShopAddress"
          [shopContact]="shopService.shopDetails?.shopContact"
          [textColour]="
            contentService.content?.colourPalette?.footerText || 'white'
          "
        ></app-shop-address>
      </div>
    </div>
  </div>
  <div class="footer-copyright">
    <p>
      © 2022 Cypress Solutions --
      <a href="https://www.speedysell.co.za" target="_blank"
        >Need an online store? Click here</a
      >
    </p>
  </div>
</footer>
