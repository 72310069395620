import { ContentChild } from '@angular/core';
import { ShopTemplateContent } from '../../services/models/shop-template-content';

export class LandingPositioner {
  positions: Map<string, number> = new Map();

  loadPositions(content: ShopTemplateContent) {
    this.positions.set('hero', content.heroSection.position || 1);
    this.positions.set('services', content.serviceSection.position || 2);
    this.positions.set('gallery', content.gallerySection.position || 3);
    this.positions.set(
      'featuredProduct',
      content.featuredProductsSection.position || 4
    );
    this.positions.set('about', content.aboutUsSection.position || 5);
  }

  swapPositions(key1: string, key2: string) {
    if (!this.positions.get(key1)) {
      throw new Error(key1 + ' not found');
    } else if (!this.positions.get(key2)) {
      throw new Error(key2 + ' not found');
    } else {
      const key1Val: number = this.positions.get(key1) || 1;
      const key2Val: number = this.positions.get(key2) || 2;
      this.positions.set(key2, key1Val);
      this.positions.set(key1, key2Val);
    }
  }

  moveSection(section: string, direction: 'up' | 'down') {
    // get list of keys sorted by position
    let arrKeys = Array.from(
      new Map([...this.positions.entries()].sort((a, b) => a[1] - b[1])).keys()
    );
    // get index of current section
    const index = arrKeys.indexOf(section);

    if (direction == 'up') {
      if (index == -1 || index == 0) {
        console.debug('Not moving up section: ' + index);
        return;
      }
      this.swapPositions(arrKeys[index], arrKeys[index - 1]);
    } else if (direction == 'down') {
      if (index == -1 || index == arrKeys.length) {
        console.debug('Not moving down section: ' + index);
        return;
      }
      this.swapPositions(arrKeys[index], arrKeys[index + 1]);
    }
  }
}
