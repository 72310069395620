import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { ContentService } from '../../services/content/content.service';
import { ToasterService } from '../../services/toaster/toaster.service';
import { ValidationService } from '../../services/validation/validation.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  constructor(
    private readonly auth: AuthService,
    private readonly toasterService: ToasterService,
    public readonly contentService: ContentService,
    private readonly validationService: ValidationService
  ) {}
  ngOnInit(): void {}

  loading = false;
  email: string | null = null;
  errorMessage: string | null = null;

  forgotPassword() {
    const error = this.validationService.validateEmail(this.email);
    if (error) {
      this.toasterService.error(error);
      return;
    }
    this.loading = true;
    this.auth
      .forgotPassword(this.email || '')
      .then((res) => {
        this.toasterService.success('Password reset email sent');
        this.loading = false;
      })
      .catch((err) => {
        console.error('Failed to send password reset email', err);
        this.toasterService.error('An error has occurred');
        this.loading = false;
      });
  }
}
