import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentService } from '../../services/content/content.service';
import { ShopService } from '../../services/shop/shop.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(
    public contentService: ContentService,
    public shopService: ShopService
  ) {}

  @Input('editMode')
  editMode: boolean = false;

  @Output('editItem')
  public editItem: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {}

  hasAtLeastOneSocialMediaLink(): any {
    return (
      this.contentService.content.footerSection.instagramProfileLink ||
      this.contentService.content.footerSection.facebookProfileLink ||
      this.contentService.content.footerSection.twitterProfileLink
    );
  }
}
