import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartEditComponent } from './components/cart-edit/cart-edit.component';
import { ConfirmPasswordComponent } from './components/confirm-password/confirm-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ShopClosedComponent } from './components/shop-closed/shop-closed.component';
import { ShopLockedComponent } from './components/shop-locked/shop-locked.component';
import { ShopSettingsComponent } from './components/shop-settings/shop-settings.component';
import { AuthGuard } from './guards/auth.guard';
import { PendingChangesGuard } from './guards/changes.guard';
import { AdminProductDetailComponent } from './views/admin-product-detail/admin-product-detail.component';
import { AdminProductListComponent } from './views/admin-product-list/admin-product-list.component';
import { CheckoutComponent } from './views/checkout/checkout.component';
import { ContactComponent } from './views/contact/contact.component';
import { FeedbackComponent } from './views/feedback/feedback.component';
import { LandingComponent } from './views/landing/landing.component';
import { LoginComponent } from './views/login/login.component';
import { OrderDetailComponent } from './views/order-detail/order-detail.component';
import { OrderOverviewComponent } from './views/order-overview/order-overview.component';
import { PaymentStatusComponent } from './views/payment-status/payment-status.component';
import { ProductDetailComponent } from './views/product-detail/product-detail.component';
import { StorefrontEditorComponent } from './views/storefront-editor/storefront-editor.component';
import { ProductCatalogueViewComponent } from './views/product-catalogue-view/product-catalogue-view.component';
import { WelcomeViewComponent } from './views/welcome-view/welcome-view.component';
import { HelpComponent } from './views/help/help.component';
const routes: Routes = [
  { path: '', redirectTo: '/storefront', pathMatch: 'full' },
  { path: 'storefront', component: LandingComponent },
  {
    path: 'editor',
    component: StorefrontEditorComponent,
    canDeactivate: [PendingChangesGuard],
    canActivate: [AuthGuard],
  },
  { path: 'contact', component: ContactComponent },
  { path: 'feedback', component: FeedbackComponent, canActivate: [AuthGuard] },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'products', component: ProductCatalogueViewComponent },
  { path: 'login', component: LoginComponent },
  { path: 'help', component: HelpComponent, canActivate: [AuthGuard] },
  {
    path: 'order-detail/:orderId',
    component: OrderDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'order-overview',
    component: OrderOverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'product-list',
    component: AdminProductListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-product/:id',
    component: AdminProductDetailComponent,
    canActivate: [AuthGuard],
  },
  { path: 'product-detail/:id', component: ProductDetailComponent },
  { path: 'cart', component: CartEditComponent },
  { path: 'payment-complete', component: PaymentStatusComponent },
  {
    path: 'shop-settings',
    component: ShopSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'welcome',
    component: WelcomeViewComponent,
    canActivate: [AuthGuard],
  },
  { path: 'shop-locked', component: ShopLockedComponent },
  { path: 'shop-closed', component: ShopClosedComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'forgot-password-confirm', component: ConfirmPasswordComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
