<div
  *ngIf="product.productId == 'catalogue'"
  [routerLink]="['/products']"
  [style.cursor]="product.productId != null ? 'pointer' : 'auto'"
  class="clean-related-item bg-white"
>
  <div class="image">
    <a>
      <img
        class="img-fluid d-block mx-auto"
        [src]="product.productImage"
        style="max-height: 160px"
    /></a>
  </div>
  <div class="related-name">
    <h4 class="text-dark">{{ product.productTitle }}</h4>
    <small [innerHtml]="product.productSubtitle"></small>
  </div>
</div>

<div
  *ngIf="
    product.productId &&
    product.productId !== 'null' &&
    product.productId !== 'catalogue'
  "
  [routerLink]="['/product-detail', product.productId]"
  [style.cursor]="product.productId != null ? 'pointer' : 'auto'"
  class="clean-related-item bg-white"
>
  <div class="image">
    <a>
      <img
        class="img-fluid d-block mx-auto"
        [src]="product.productImage"
        style="max-height: 160px"
    /></a>
  </div>
  <div class="related-name">
    <h4 class="text-dark">{{ product.productTitle }}</h4>
    <small [innerHtml]="product.productSubtitle"></small>
  </div>
</div>

<div
  *ngIf="!product.productId || product.productId === 'null'"
  class="clean-related-item bg-white"
>
  <div class="image">
    <a>
      <img
        class="img-fluid d-block mx-auto"
        [src]="product.productImage"
        style="max-height: 160px"
    /></a>
  </div>
  <div class="related-name">
    <h4 class="text-dark">{{ product.productTitle }}</h4>
    <small [innerHtml]="product.productSubtitle"></small>
  </div>
</div>
