import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Cart, LineItem } from '../models/cart';
import { Order } from '../models/order';
import { SessionService } from '../session/session.service';
import { ShopService } from '../shop/shop.service';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  order: Order = new Order();
  cart: Cart = new Cart();
  checkoutStep = 0;

  constructor(
    private readonly http: HttpClient,
    private readonly session: SessionService,
    private readonly shopService: ShopService
  ) {
    this.restoreCartSession();
  }

  submitOrder(): Promise<Order> {
    delete this.order.id;
    this.order.shopId = this.shopService?.shopDetails?.id;
    this.order.testMode =
      !!this.shopService?.shopDetails?.paymentSandboxModeEnabled;
    // Map between cart line items and order persisted line items.
    this.order.lineItems = this.cart.lineItems.map((item: LineItem) => {
      return {
        productId: item.product.id,
        productPrice: this.getPriceForProductConfig(item),
        productName: item.product.title,
        purchaseType: item.purchaseType,
        contractLengthInMonths: item.contractLengthInMonths,
        installationPrice: item.product.installationPrice,
        quantity: item.quantity,
      };
    });

    const orderTotals = this.calculateOrderTotals();
    this.order.totalDueNow = orderTotals.totalOrderDueNow;
    this.order.totalDueMonthly = orderTotals.totalOrderDueMonthly;
    this.order.deliveryRate = this.getDeliveryRate();
    return lastValueFrom(
      this.http.post<Order>(environment.API_URL + '/order/create', this.order)
    );
  }

  getDeliveryRate(): number {
    return this.shopService.getDeliveryFee();
  }

  getPriceForProductConfig(lineItem: LineItem): number | undefined {
    if (this.shopService.getShopOwner() === 'TRAXSIT') {
      if (lineItem.purchaseType == 'BUY-NOW') {
        return lineItem.product.buyNowPrice[
          lineItem.contractLengthInMonths || 0
        ];
      } else if (lineItem.purchaseType == 'SUBSCRIPTION') {
        return lineItem.product.subscriptionPrice[
          lineItem.contractLengthInMonths || 0
        ];
      } else {
        return undefined;
      }
    }

    //standard shop behavior
    return lineItem.product.price;
  }

  addLineItem(lineItem: LineItem): void {
    this.cart.addLineItem(lineItem);
    this.session.storeItemsOnCart(this.cart.lineItems);
  }

  removeLineItem(lineItem: LineItem) {
    this.cart.removeLineItem(lineItem);
    this.session.storeItemsOnCart(this.cart.lineItems);
  }

  restoreCartSession(): void {
    this.cart.lineItems = this.session.getItemsOnCart();
  }

  calculateInstallationPrice() {
    let total = 0;
    this.cart.lineItems.forEach((element) => {
      if (element?.product?.installationPrice) {
        total += element?.product?.installationPrice * element?.quantity;
      }
    });
    return total;
  }

  calculateOrderTotals(): {
    totalOrderDueNow: number;
    totalOrderDueMonthly: number;
    buyNowTotal: number;
    deliveryFee: number;
    installationPrice: number;
  } {
    return {
      totalOrderDueNow: +this.getTotalOrderDueNow(),
      totalOrderDueMonthly: +(this.cart.getCartTotals().totalDueMonthly || 0),
      buyNowTotal: +this.cart.getCartTotals().totalDueNow,
      deliveryFee: +(this.shopService.getDeliveryFee() || 0),
      installationPrice: +(this.calculateInstallationPrice() || 0),
    };
  }

  getTotalOrderDueNow(): number {
    return (
      +(this.cart.getCartTotals().totalDueNow || 0) +
      +(this.cart.getCartTotals().totalDueMonthly || 0) +
      +(this.calculateInstallationPrice() || 0) +
      (this.shopService.getDeliveryFee() || 0)
    );
  }
}
