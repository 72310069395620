import { AuthService } from './services/auth/auth.service';
import { CheckoutService } from './services/checkout/checkout.service';
import { Component } from '@angular/core';
import { ContentService } from './services/content/content.service';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { ShopService } from './services/shop/shop.service';
import { TrackingService } from './services/tracking/tracking.service';
import { filter, lastValueFrom } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { ShopTaskService } from './services/shop-task/shop-task.service';
import { ProductService } from './services/product/product.service';
import { ProductSearchFilter } from './services/models/product-search-filter';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    public checkoutService: CheckoutService,
    public contentService: ContentService,
    public shopService: ShopService,
    public authService: AuthService,
    public readonly shopTaskService: ShopTaskService,
    private meta: Meta,
    private router: Router,
    private readonly trackingService: TrackingService,
    private readonly productService: ProductService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.trackingService.trackPageView(location.pathname + location.search);
      });
  }

  title = 'SpeedySell';
  loading = true;
  errorMessage: string | null = null;
  previewAllowed: boolean = false;
  previewError: string | null = null;
  mobileNavExpanded = false;
  shopHasProducts: boolean | null = null;

  ngOnInit() {
    this.loadShopByPathParamShopId();
  }

  private async loadShopByPathParamShopId() {
    this.router.events.subscribe((val) => {
      if (val instanceof RoutesRecognized) {
        if (val.url.indexOf('editor') != -1) {
          this.contentService.editingMode = true;
        } else {
          this.contentService.editingMode = false;
        }
        const path = val.url;
        const hostname = this.shopService.getHostName();
        this.routeToShop(
          hostname,
          val?.state?.root?.firstChild?.queryParams['shopId'],
          path
        );
      }
    });
  }

  async routeToShop(hostname: string, shopParam: string | null, path: string) {
    // Check for param override of shop
    if (shopParam && shopParam != null && shopParam) {
      localStorage.setItem('shopId', shopParam);
      try {
        await this.initializeShopDetails(shopParam, path);
        this.loading = false;
        return;
      } catch (err) {
        console.error(err);
      }
    }

    // Check for locally stored assigned shop
    const shopIdFromStorage = localStorage.getItem('shopId');
    if (shopIdFromStorage !== null) {
      try {
        await this.initializeShopDetails(shopIdFromStorage, path);
        this.loading = false;
        return;
      } catch (err) {
        console.error(err);
      }
    }

    // Fetch shop based off of domain
    try {
      const shop = await this.shopService.getShopFromDomain(hostname);
      await this.initializeShopDetails(shop.id, path);
      localStorage.setItem('shopId', shop.id);
      this.loading = false;
    } catch (err) {
      this.errorMessage = 'Failed to load shop';
      this.loading = false;
      console.error('Failed to find shop for domain', hostname, err);
    }
  }

  private setFavicon() {
    const favIcon: HTMLLinkElement | null = document.querySelector('#appIcon');
    if (favIcon) {
      favIcon.href = this.contentService.content?.favicon
        ? this.contentService.content?.favicon
        : this.contentService.content?.logo || '';
    }
  }

  private hideSplash() {
    const splash: HTMLLinkElement | null = document.querySelector('.splash');
    if (splash) {
      splash.style.display = 'none';
    }
  }

  private async initializeShopDetails(shopId: string, path?: string) {
    await this.contentService.initializeShopTemplateContent(shopId);
    await this.shopService.initializeShopDetails(shopId);
    if (
      !this.urlContains([
        'login',
        'forgot-password',
        'forgot-password-confirm',
      ]) &&
      this.authService.isLoggedOut() &&
      !this.shopService.previewAllowed
    ) {
      this.checkShopLockedOrClosed();
    }
    this.loading = false;
    this.setFavicon();
    this.hideSplash();
    this.setDeliveryFeeOnCartService();
    this.setMetaTags();
    this.checkShopHasProducts();
  }

  setMetaTags() {
    this.meta.updateTag({
      name: 'keywords',
      content: this.shopService.shopDetails?.seoKeywords?.join(',') || '',
    });
    this.meta.updateTag({
      name: 'description',
      content: this.shopService.shopDetails?.seoDescription || '',
    });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });
  }

  setDeliveryFeeOnCartService() {
    this.checkoutService.cart.deliveryFlatRate = +this.getDeliveryFee();
  }

  urlContains(paths: string[] | undefined) {
    if (!paths || paths.length === 0) {
      return true;
    }

    let contains = false;
    for (let path of paths) {
      if (window.location.href.indexOf(path) !== -1) {
        contains = true;
      }
    }

    return contains;
  }

  clickCart() {
    this.mobileNavExpanded = !this.mobileNavExpanded;
    this.checkoutService.checkoutStep = 0;
  }

  getDeliveryFee(): number {
    return this.shopService.shopDetails
      ? this.shopService.shopDetails.deliveryFlatRate
      : 0;
  }

  logOut() {
    this.mobileNavExpanded = !this.mobileNavExpanded;
    this.authService.logout();
    this.router.navigateByUrl('/storefront');
  }

  getShopStatus():
    | 'OPEN'
    | 'CLOSED'
    | 'LOCKED'
    | 'PERMANENTLY_CLOSED'
    | undefined {
    return this.shopService?.shopDetails?.status;
  }

  getShopCheckoutEnabled(): boolean {
    return this.shopService?.shopDetails?.checkoutEnabled || false;
  }

  routeToEditor() {
    this.mobileNavExpanded = !this.mobileNavExpanded;
    this.contentService.editingMode = true;
    this.router.navigateByUrl('editor');
  }

  checkShopLockedOrClosed() {
    if (this.shopService.shopDetails?.status === 'CLOSED') {
      this.router.navigateByUrl('shop-closed');
    } else if (this.shopService.shopDetails?.status === 'LOCKED') {
      this.router.navigateByUrl('shop-locked');
    }
  }

  async checkShopHasProducts() {
    if (this.shopHasProducts == null) {
      const filter = new ProductSearchFilter();
      filter.shopId = this.shopService.shopDetails?.id || '';
      const response = await lastValueFrom(
        this.productService.getProducts(filter)
      );
      response.count > 0
        ? (this.shopHasProducts = true)
        : (this.shopHasProducts = false);
    }
  }

  notFeedbackPageOrEditorOrStorefront(): any {
    return (
      window.location.href.indexOf('feedback') == -1 &&
      window.location.href.indexOf('editor') == -1 &&
      window.location.href.indexOf('storefront') == -1
    );
  }

  isMobileScreen(): any {
    return window.outerWidth <= 500;
  }

  scroll(className: string) {
    setTimeout(() => {
      const classElement = document.getElementsByClassName(className);
      if (classElement.length > 0) {
        classElement[0].scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);
  }
}
