<main class="page contact-us-page">
  <section class="clean-block clean-form dark" style="min-height: 700px">
    <div class="container">
      <div class="block-heading">
        <h2 class="text-dark">Shop is closed for maintenance.</h2>
        <i
          [ngStyle]="{
            color: contentService.content?.colourPalette?.icon
          }"
          class="far fa-clock closed-icon"
        ></i>
        <p>
          We will be back shortly.<br />Contact us for more information:
          <strong>{{
            shopService.shopDetails?.shopContact?.adminEmail
          }}</strong>
        </p>
      </div>
    </div>
  </section>
</main>
