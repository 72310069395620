import { NumberValueAccessor } from '@angular/forms';

export class Product {
  constructor() {}
  id!: string;
  title!: string;
  productCode!: string;
  type?: 'BUY-NOW' | 'SUBSCRIPTION';
  stockLevel: 'NO_STOCK' | 'LOW_STOCK' | 'IN_STOCK' = 'IN_STOCK';
  price?: number | undefined;
  buyNowPrice?: any;
  subscriptionPrice?: any;
  description!: string;
  descriptionPdf?: string;
  additionalInfoHeader?: string;
  additionaInfoPdf?: string;
  minOrderQuantity!: number;
  maxOrderQuantity!: number;
  installationPrice?: number;
  additionalInformation?: string;
  additionalInfoImages?: BaseImage[] = [];
  images?: BaseImage[] = [];
  descriptionImages?: BaseImage[] = [];
  category?: Category | null;
}

export class Category {
  id!: string;
  name!: string;
  shopId?: string;
  productsAssigned?: number;
  createdAt!: Date;
  updatedAt!: Date;
}

export class BaseImage {
  id?: string;
  link!: string;
  createdAt?: Date;
  updatedAt?: Date;
}
