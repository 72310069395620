<!-- carousel -->
<div class="carousel" [ngStyle]="{ 'height.px': height }">
  <ng-container *ngFor="let slide of slides; let i = index">
    <div class="image-container">
      <img
        [@slideAnimation]="animationType"
        *ngIf="i === currentSlide"
        [src]="slide.src"
        class="slide"
        [ngStyle]="{ 'object-fit': slide.fit }"
      />
    </div>
    <h2
      *ngIf="i === currentSlide && imageHeadlineColours[i]"
      class="slide-headline"
      [ngStyle]="{
        color: imageHeadlineColours[i]
      }"
    >
      {{ slide.headline }}
    </h2>
  </ng-container>

  <!-- controls -->
  <button
    *ngIf="slides.length > 1"
    class="control prev arrow"
    (click)="onPreviousClick()"
  >
    <i
      [ngStyle]="{
        color: controlColour
      }"
      class="icon far fa-arrow-alt-circle-left"
    ></i>
  </button>
  <button
    *ngIf="slides.length > 1"
    class="control next arrow"
    (click)="onNextClick()"
  >
    <i
      [ngStyle]="{
        color: controlColour
      }"
      class="icon far fa-arrow-alt-circle-right"
    ></i>
  </button>
</div>
