import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckoutService } from '../../../services/checkout/checkout.service';
import { ShopService } from '../../../services/shop/shop.service';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss'],
})
export class CartSummaryComponent implements OnInit {
  @Input()
  installationPrice: number | null = null;

  @Input()
  showCheckoutButton = true;

  @Output('proceedToCheckout')
  checkoutEventEmitter: EventEmitter<string> = new EventEmitter<string>();
  constructor(
    public checkoutService: CheckoutService,
    public shopService: ShopService
  ) {}

  ngOnInit(): void {}

  getContractLength() {
    return this.checkoutService.cart.lineItems.find(
      (item) => !!item.contractLengthInMonths
    )?.contractLengthInMonths;
  }

  proceedToCheckout() {
    this.checkoutEventEmitter.emit();
  }
}
