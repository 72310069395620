<main *ngIf="shopDetails" class="page contact-us-page d-flex flex-column">
  <section
    class="clean-block clean-form dark pt-5"
    style="padding-bottom: 100px; position: relative"
  >
    <div class="container">
      <h3 class="card-title">
        <strong>Settings</strong>
      </h3>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="shop-tab"
            data-bs-toggle="tab"
            data-bs-target="#shop"
            type="button"
            role="tab"
            aria-controls="shop"
            aria-selected="true"
            (click)="selectedTab = 'shop'"
            #shopButton
          >
            Shop settings
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="checkout-tab"
            data-bs-toggle="tab"
            data-bs-target="#checkout"
            type="button"
            role="tab"
            aria-controls="checkout"
            aria-selected="false"
            (click)="selectedTab = 'checkout'"
            #checkoutButton
          >
            Checkout settings
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="account-tab"
            data-bs-toggle="tab"
            data-bs-target="#account"
            type="button"
            role="tab"
            aria-controls="account"
            aria-selected="false"
            (click)="selectedTab = 'account'"
            #accountButton
          >
            Account & billing
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="shop"
          role="tabpanel"
          aria-labelledby="shop-tab"
        >
          <div class="card mb-5">
            <div class="card-header">
              <h5 class="mb-0">Shop settings</h5>
            </div>
            <div class="card-body p-4">
              <div class="d-flex flex-grow-1 justify-content-between two-field">
                <div class="d-xl-flex flex-column content-input-group">
                  <label class="form-label">Shop name</label>
                  <input
                    class="input-group-text-input"
                    type="text"
                    name="name"
                    [(ngModel)]="shopDetails.name"
                    placeholder="e.g. Homemade Goods"
                  />
                </div>
                <div class="d-xl-flex flex-column content-input-group mx-md-0">
                  <span class="form-label"
                    >Order Reference Prefix (Optional)</span
                  ><input
                    class="input-group-text-input"
                    type="text"
                    name="orderRefPrefix"
                    [(ngModel)]="shopDetails.orderRefPrefix"
                    placeholder="e.g.Order1001 - Default is 'OD'"
                  />
                </div>
              </div>
              <div class="d-flex flex-grow-1 justify-content-between two-field">
                <div class="d-xl-flex flex-column content-input-group">
                  <span class="form-label">Shop Status</span
                  ><select
                    name="status"
                    [(ngModel)]="shopDetails.status"
                    class="form-select input-group-select"
                  >
                    <option value="OPEN">
                      Open for
                      {{ shopDetails.checkoutEnabled ? "selling" : "viewing" }}
                    </option>
                    <option value="CLOSED">Closed for maintenance</option>
                    <option value="LOCKED">Locked for construction</option>
                  </select>
                </div>
                <div class="d-xl-flex flex-column content-input-group mx-md-0">
                  <span class="form-label">Locked Shop Previewer Password*</span
                  ><input
                    class="input-group-text-input"
                    type="text"
                    name="storefrontPassword"
                    [disabled]="shopDetails.status !== 'LOCKED'"
                    [(ngModel)]="shopDetails.storefrontPassword"
                    placeholder="e.g. 4Frt%$wmeWEQ"
                  />
                </div>
              </div>
              <div class="d-flex flex-grow-1 justify-content-between two-field">
                <div class="d-xl-flex flex-column content-input-group">
                  <span class="form-label">Admin Email</span
                  ><input
                    class="input-group-text-input"
                    type="text"
                    name="adminEmail"
                    [(ngModel)]="shopDetails.shopContact.adminEmail"
                    placeholder="e.g. admin@test.com"
                  />
                </div>
                <div class="d-xl-flex flex-column content-input-group mx-md-0">
                  <span class="form-label">Shop Phone Contact</span
                  ><input
                    class="input-group-text-input"
                    type="text"
                    name="shopPhone"
                    [(ngModel)]="shopDetails.shopContact.landingContactPhone"
                    placeholder="e.g.011 232 2323"
                  />
                </div>
              </div>
              <div class="d-flex flex-column flex-grow-1">
                <div class="info-banner">
                  <div>
                    <div class="info-banner-content">
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style="min-width: 21px"
                      >
                        <path
                          d="M9.5 15H11.5V9H9.5V15ZM10.5 7C10.7833 7 11.021 6.904 11.213 6.712C11.405 6.52 11.5007 6.28267 11.5 6C11.5 5.71667 11.404 5.479 11.212 5.287C11.02 5.095 10.7827 4.99933 10.5 5C10.2167 5 9.979 5.096 9.787 5.288C9.595 5.48 9.49933 5.71733 9.5 6C9.5 6.28333 9.596 6.521 9.788 6.713C9.98 6.905 10.2173 7.00067 10.5 7ZM10.5 20C9.11667 20 7.81667 19.7373 6.6 19.212C5.38333 18.6867 4.325 17.9743 3.425 17.075C2.525 16.175 1.81267 15.1167 1.288 13.9C0.763333 12.6833 0.500667 11.3833 0.5 10C0.5 8.61667 0.762667 7.31667 1.288 6.1C1.81333 4.88333 2.52567 3.825 3.425 2.925C4.325 2.025 5.38333 1.31267 6.6 0.788C7.81667 0.263333 9.11667 0.000666667 10.5 0C11.8833 0 13.1833 0.262667 14.4 0.788C15.6167 1.31333 16.675 2.02567 17.575 2.925C18.475 3.825 19.1877 4.88333 19.713 6.1C20.2383 7.31667 20.5007 8.61667 20.5 10C20.5 11.3833 20.2373 12.6833 19.712 13.9C19.1867 15.1167 18.4743 16.175 17.575 17.075C16.675 17.975 15.6167 18.6877 14.4 19.213C13.1833 19.7383 11.8833 20.0007 10.5 20Z"
                          fill="white"
                        />
                      </svg>
                      <div class="info-banner-text">
                        Please do not utilize used/known passwords for preview
                        password. This field is not encrypted.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-5">
            <div class="card-header">
              <h5 class="mb-0">Shop address</h5>
            </div>
            <div class="card-body p-4">
              <div class="d-flex flex-grow-1 justify-content-between two-field">
                <div class="d-xl-flex flex-column content-input-group">
                  <span class="form-label">Address Line 1</span
                  ><input
                    class="input-group-text-input"
                    type="text"
                    name="addressLine1"
                    [(ngModel)]="shopDetails.shopContact.addressLine1"
                    placeholder="6 Elm St, Wattson Park"
                  />
                </div>
                <div class="d-xl-flex flex-column content-input-group mx-md-0">
                  <span class="form-label">Address Line 2</span
                  ><input
                    class="input-group-text-input"
                    type="text"
                    name="addressLine2"
                    [(ngModel)]="shopDetails.shopContact.addressLine2"
                    placeholder="e.g. Apartment 23, Complex 7"
                  />
                </div>
              </div>
              <div class="d-flex flex-grow-1 justify-content-between two-field">
                <div class="d-xl-flex flex-column content-input-group">
                  <span class="form-label">Postal Code</span
                  ><input
                    class="input-group-text-input"
                    type="text"
                    name="postalCode"
                    [(ngModel)]="shopDetails.shopContact.postalCode"
                    placeholder="2550"
                  />
                </div>
                <div class="d-xl-flex flex-column content-input-group mx-md-0">
                  <span class="form-label">Province</span
                  ><input
                    class="input-group-text-input"
                    type="text"
                    name="addressLine2"
                    [(ngModel)]="shopDetails.shopContact.province"
                    placeholder="e.g. Johannesburg"
                  />
                </div>
              </div>
            </div>
          </div>
          <app-seo-settings
            [shopDetails]="shopDetails"
            (seoSettingsUpdatedEvent)="updateSeoSettings($event)"
          ></app-seo-settings>
        </div>
        <div
          class="tab-pane fade"
          id="checkout"
          role="tabpanel"
          aria-labelledby="checkout-tab"
        >
          <div class="card mb-5">
            <div class="card-header">
              <h5 class="mb-0">Checkout settings</h5>
            </div>
            <div class="card-body p-4">
              <div class="d-flex justify-content-between product-header">
                <div class="input-group">
                  <label class="switch">
                    <input
                      type="checkbox"
                      id="checkout-enabled-checkbox"
                      name="checkoutEnabled"
                      [(ngModel)]="shopDetails.checkoutEnabled"
                      class="toggle"
                    />
                    <span class="slider round"></span>
                  </label>
                  <span class="switch-form-label"
                    >Checkout
                    {{
                      shopDetails.checkoutEnabled ? "Enabled" : "Disabled"
                    }}</span
                  >
                </div>
              </div>
              <hr />
              <div class="d-flex justify-content-between product-header mt-4">
                <h4>
                  <strong>Payment Account Details</strong>
                </h4>
              </div>
              <sub *ngIf="!isPaymentDetailsSet()"
                >Checkout enabled is required for payments. You can learn more
                about how to set up your Paystack account by clicking
                below&nbsp; <br />
                <button
                  (click)="goToPaystack()"
                  [disabled]="!shopDetails.checkoutEnabled"
                  class="btn btn-primary mt-2 mb-2"
                >
                  Go To PayStack SignUp
                </button></sub
              >
              <div
                *ngIf="!isPaymentDetailsSet()"
                class="d-flex flex-grow-1 justify-content-between two-field"
              >
                <div class="d-xl-flex flex-column content-input-group">
                  <span class="form-label">Paystack Secret Key</span
                  ><input
                    class="input-group-text-input"
                    type="text"
                    name="paystackSecret"
                    [disabled]="!shopDetails.checkoutEnabled"
                    [(ngModel)]="paymentAppId"
                    placeholder="e.g. sk_XXXXX"
                  />
                </div>
                <div class="d-xl-flex flex-column content-input-group mx-md-0">
                  <span class="form-label">Paystack Public Key</span
                  ><input
                    class="input-group-text-input"
                    type="text"
                    name="paystackPublicKey"
                    [disabled]="!shopDetails.checkoutEnabled"
                    [(ngModel)]="paymentAppKey"
                    placeholder="e.g. pk_XXXXXXXXX"
                  />
                </div>
              </div>
              <button
                *ngIf="!isPaymentDetailsSet()"
                class="btn btn-primary connect"
                (click)="savePaymentDetails()"
                [disabled]="!shopDetails.checkoutEnabled"
              >
                <span *ngIf="!loading">Connect</span>
                <app-spinner *ngIf="loading"></app-spinner>
              </button>

              <div
                *ngIf="isPaymentDetailsSet()"
                class="account-status d-flex flex-column"
              >
                <div class="d-flex flex-row">
                  <img class="img-responsive" src="assets/img/success.webp" />
                  <span>Paystack Account Connnected</span>
                </div>
                <div>
                  <sub
                    >*Disconnecting your payment account will automatically
                    disable online checkout</sub
                  >
                </div>
                <div>
                  <sub
                    >*Payments made before the disconnection will not be
                    viewable in the order details page.</sub
                  >
                </div>
                <button class="btn btn-primary" (click)="disconnectAccount()">
                  <span *ngIf="!loading">Disconnect</span>
                  <app-spinner *ngIf="loading"></app-spinner>
                </button>
              </div>
              <div *ngIf="isPaymentDetailsSet()" class="info-banner">
                <div>
                  <div class="info-banner-content">
                    <svg
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style="min-width: 21px"
                    >
                      <path
                        d="M9.5 15H11.5V9H9.5V15ZM10.5 7C10.7833 7 11.021 6.904 11.213 6.712C11.405 6.52 11.5007 6.28267 11.5 6C11.5 5.71667 11.404 5.479 11.212 5.287C11.02 5.095 10.7827 4.99933 10.5 5C10.2167 5 9.979 5.096 9.787 5.288C9.595 5.48 9.49933 5.71733 9.5 6C9.5 6.28333 9.596 6.521 9.788 6.713C9.98 6.905 10.2173 7.00067 10.5 7ZM10.5 20C9.11667 20 7.81667 19.7373 6.6 19.212C5.38333 18.6867 4.325 17.9743 3.425 17.075C2.525 16.175 1.81267 15.1167 1.288 13.9C0.763333 12.6833 0.500667 11.3833 0.5 10C0.5 8.61667 0.762667 7.31667 1.288 6.1C1.81333 4.88333 2.52567 3.825 3.425 2.925C4.325 2.025 5.38333 1.31267 6.6 0.788C7.81667 0.263333 9.11667 0.000666667 10.5 0C11.8833 0 13.1833 0.262667 14.4 0.788C15.6167 1.31333 16.675 2.02567 17.575 2.925C18.475 3.825 19.1877 4.88333 19.713 6.1C20.2383 7.31667 20.5007 8.61667 20.5 10C20.5 11.3833 20.2373 12.6833 19.712 13.9C19.1867 15.1167 18.4743 16.175 17.575 17.075C16.675 17.975 15.6167 18.6877 14.4 19.213C13.1833 19.7383 11.8833 20.0007 10.5 20Z"
                        fill="white"
                      />
                    </svg>
                    <div class="info-banner-text">
                      <strong
                        >Please ensure that you mark orders as test orders if
                        you are using TEST Paystack API credentails</strong
                      >
                      <br />
                      e.g. sk_test_3fasdfasdfb7123241202ec17b012312323c
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="isPaymentDetailsSet()"
                class="d-flex flex-grow-1 justify-content-between half-field"
              >
                <div class="input-group pt-2">
                  <label class="switch">
                    <input
                      type="checkbox"
                      id="payment-sandbox-checkbox"
                      name="paymentSandboxModeEnabled"
                      [disabled]="!shopDetails.checkoutEnabled"
                      [(ngModel)]="shopDetails.paymentSandboxModeEnabled"
                      class="toggle"
                    />
                    <span class="slider round"></span>
                  </label>
                  <span class="switch-form-label"
                    >Mark Orders as Test Orders</span
                  >
                </div>
              </div>
              <hr />
              <div class="d-flex justify-content-between product-header mt-4">
                <h4>
                  <strong>Delivery Settings</strong>
                </h4>
              </div>
              <sub
                >Delivery costs are per order. Set to 0 if you sell a virtual
                product or service that doesn't require delivery. If set to 0
                delivery fees will be hidden.&nbsp;</sub
              >
              <div class="d-flex justify-content-between product-header">
                <div class="d-xl-flex flex-column content-input-group mx-md-0">
                  <span class="form-label"
                    >Delivery Flat Rate (Per Order in ZAR)</span
                  >
                  <input
                    name="price"
                    [(ngModel)]="shopDetails.deliveryFlatRate"
                    class="input-group-text-input"
                    [disabled]="!shopDetails.checkoutEnabled"
                    (change)="validatePrice()"
                    type="number"
                    placeholder="e.g. 100.00"
                  />
                </div>
              </div>
              <hr />
              <div
                class="d-flex flex-column justify-content-between product-header mt-4"
              >
                <h4>
                  <strong>Custom Field On Order</strong>
                </h4>
              </div>
              <sub
                >This field will add an extra step to the checkout process. It
                will allow you to capture custom information e.g. personalised
                message for homemade goods&nbsp;</sub
              >
              <div
                *ngIf="shopDetails.customField"
                class="d-flex flex-grow-1 justify-content-between two-field"
              >
                <div class="input-group">
                  <label class="switch">
                    <input
                      type="checkbox"
                      id="custom-enabled-checkbox"
                      name="customFieldEnabled"
                      [disabled]="!shopDetails.checkoutEnabled"
                      [(ngModel)]="shopDetails.customField.enabled"
                      class="toggle"
                    />
                    <span class="slider round"></span>
                  </label>
                  <span class="switch-form-label">Custom Field Enabled</span>
                </div>
                <div class="input-group">
                  <label class="switch">
                    <input
                      type="checkbox"
                      id="custom-enabled-checkbox"
                      name="customFieldEnabled"
                      [disabled]="
                        !shopDetails.checkoutEnabled ||
                        !shopDetails.customField.enabled
                      "
                      [(ngModel)]="shopDetails.customField.optional"
                      class="toggle"
                    />
                    <span class="slider round"></span>
                  </label>
                  <span class="switch-form-label"
                    >Custom field required to complete checkout</span
                  >
                </div>
              </div>
              <div
                class="d-flex flex-grow-1 justify-content-between two-field"
                *ngIf="shopDetails.customField"
              >
                <div class="d-xl-flex flex-column content-input-group">
                  <span class="form-label">Custom Field Label</span
                  ><input
                    class="input-group-text-input"
                    type="text"
                    name="CustomFieldLabel"
                    [disabled]="
                      !shopDetails.checkoutEnabled ||
                      !shopDetails.customField.enabled
                    "
                    [(ngModel)]="shopDetails.customField.label"
                    placeholder="e.g. Personalized Message"
                  />
                </div>
                <div class="d-xl-flex flex-column content-input-group mx-md-0">
                  <span class="form-label">Custom Field Example</span>
                  <input
                    class="input-group-text-input"
                    type="text"
                    name="customFieldExample"
                    [disabled]="
                      !shopDetails.checkoutEnabled ||
                      !shopDetails.customField.enabled
                    "
                    [(ngModel)]="shopDetails.customField.value"
                    placeholder="e.g. To Mary from John"
                  />
                </div>
              </div>
              <div
                class="d-flex flex-grow-1 justify-content-between two-field"
                *ngIf="shopDetails.customField"
              >
                <div class="d-xl-flex flex-column content-input-group mx-md-0">
                  <span class="form-label">Custom Field Description</span>
                  <input
                    class="input-group-text-input"
                    type="text"
                    name="customFieldExample"
                    [disabled]="
                      !shopDetails.checkoutEnabled ||
                      !shopDetails.customField.enabled
                    "
                    [(ngModel)]="shopDetails.customField.description"
                    placeholder="e.g. Please add a comma separated list of messages for the products in the cart."
                  />
                </div>
              </div>
              <div>
                <p>
                  Here is an example of what the extra checkout step would look
                  like with the above examples:
                </p>
                <img src="assets/img/exampleCustomField.png" width="100%" />
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="account"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          <div class="card mb-5">
            <div class="card-header">
              <h5 class="mb-0">Account & billing</h5>
            </div>
            <div class="card-body p-4">
              <h4>SpeedySell Package: <strong>Standard</strong></h4>
              <!-- <p> -->
              <!-- With this current package you will be billed -->
              <!-- <strong>R250.00</strong> per month. For any queries surrounding -->
              <!-- billing or account settings please contact support on -->
              <!-- {{ supportEmail }} -->
              <!-- </p> -->
              <p>
                Paystack is used as a payment provider. They take a small
                commission on every online payment that happens on your shop.
              </p>
              <p>
                For more information about their fee structure, you can find it
                here:
                <a
                  href="https://paystack.com/za/pricing?localeUpdate=true"
                  target="_blank"
                  >Paystack Fee Strucutre</a
                >
              </p>
            </div>
          </div>
          <div style="height: 40px"></div>
        </div>
      </div>
    </div>
  </section>
  <div class="bottom-action-bar" *ngIf="selectedTab !== 'account'">
    <div class="bottom-action-bar-content">
      <button
        class="btn btn-primary product-save-button btn-action"
        *ngIf="!loading"
        type="button"
        (click)="saveShopDetails()"
      >
        Save
      </button>
      <div *ngIf="loading" class="spinner-container">
        <app-spinner></app-spinner>
      </div>
    </div>
  </div>
</main>
