// Details for logged in users with sensitive fields
export class ShopContact {
  id!: number;
  adminEmail!: string;
  landingContactPhone!: string;
  addressLine1?: string;
  addressLine2?: string;
  postalCode?: string;
  province?: string;
  capturePhone!: boolean;
}

export class CustomField {
  enabled: boolean = false;
  optional: boolean = false;
  label?: string | null = null;
  description?: string | null = null;
  value?: string | null = null;
}

export class ShopDetails {
  id!: string;
  tenantId!: string;
  themeSelection!: string;
  name?: string;
  orderRefPrefix!: string;
  paymentAppId?: string;
  paymentAppKey?: string;
  paymentSandboxModeEnabled!: boolean;
  currencyCode!: string;
  checkoutEnabled?: boolean;
  deliveryFlatRate: number = 0;
  customField: CustomField = new CustomField();
  status?: 'OPEN' | 'CLOSED' | 'LOCKED' | 'PERMANENTLY_CLOSED';
  storefrontPassword?: string;
  shopContact!: ShopContact;
  seoKeywords?: string[];
  seoDescription?: string;
}
