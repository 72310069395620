<div class="card seo-settings">
  <div class="card-header">
    <h5 class="mb-0">Search Engine Optimization(SEO) Settings</h5>
  </div>
  <div class="card-body p-4">
    <div class="d-flex flex-grow-1 justify-content-between">
      <div class="d-xl-flex flex-column content-input-group">
        <span class="form-label">Shop SEO Description</span>
        <p class="description">
          The SEO description tag is a brief snippet of text that appears in
          search engine results below the page title. It provides a concise
          summary of the content on a web page and helps users determine if the
          page is relevant to their search query. An effective and compelling
          SEO description tag can attract more clicks to your website and
          improve your search engine rankings.
        </p>
        <div class="content-input-group">
          <input
            class="input-group-text-input"
            type="text"
            maxlength="120"
            name="seoKeywords"
            placeholder="e.g. Buy the latest fashion and cheap prices. New shoes, new styles ..."
            [(ngModel)]="description"
            (ngModelChange)="updateSeoDescription($event)"
          />
        </div>
      </div>
    </div>
    <div class="d-flex flex-grow-1 justify-content-between two-field">
      <div class="d-xl-flex flex-column content-input-group">
        <span class="form-label"
          >Shop SEO Keywords (Please press enter to add)</span
        >
        <p class="description">
          Including keyword tags may help search engines understand the page
          better. It is used used to provide information about the main keywords
          or phrases that the page targets.
        </p>

        <div class="content-input-group">
          <input
            class="input-group-text-input"
            type="text"
            maxlength="150"
            name="seoKeywords"
            placeholder="e.g. clothing, fashion, shirts, shoes"
            (keydown.enter)="updateSeoKeywords($event)"
          />
        </div>
        <div class="input-group-append">
          <div class="d-flex flex-wrap">
            <button
              class="btn btn-outline-secondary word-badge"
              type="button"
              *ngFor="
                let keyword of keywords;
                let i = index;
                trackBy: trackByIndex
              "
            >
              {{ keyword }}
              <span
                class="badge badge-pill badge-danger ml-2"
                (click)="removeKeyword(i)"
                >x</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
