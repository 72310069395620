<section class="clean-block clean-form dark">
  <div class="container mb-5">
    <div class="block-heading">
      <h2
        [ngStyle]="{
          color: contentService.content?.colourPalette?.sectionTitle
        }"
      >
        {{ contentService.content?.contactFormSettings?.contactFormTitle }}
      </h2>
      <p
        [ngStyle]="{
          color: contentService.content?.colourPalette?.sectionText
        }"
      >
        {{ contentService.content?.contactFormSettings?.contactFormSubtitle }}
      </p>
    </div>
    <form
      class="text-dark"
      [ngStyle]="{
        'border-color': contentService.content?.colourPalette?.buttonBackground
      }"
    >
      <div class="mb-3">
        <label class="form-label" for="name">Name</label
        ><input
          [(ngModel)]="name"
          class="form-control"
          type="text"
          id="name"
          name="name"
        />
      </div>
      <div *ngIf="capturePhone" class="mb-3">
        <label class="form-label" for="phone">Phone Number</label
        ><input
          [(ngModel)]="phone"
          class="form-control"
          type="text"
          id="phone"
          name="phone"
        />
      </div>
      <div class="mb-3">
        <label class="form-label" for="email">Email</label
        ><input
          [(ngModel)]="email"
          class="form-control"
          type="email"
          id="email"
          name="email"
        />
      </div>
      <div class="mb-3">
        <label class="form-label" for="message">Message</label
        ><textarea
          [(ngModel)]="message"
          class="form-control"
          id="message"
          name="message"
        ></textarea>
      </div>
      <div class="mb-3">
        <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
        <button
          [disabled]="loading"
          class="btn w-100"
          type="submit"
          (click)="sendContactRequest()"
          [ngStyle]="{
            'background-color':
              contentService.content?.colourPalette?.buttonBackground,
            color: contentService.content?.colourPalette?.buttonText
          }"
        >
          {{ loading ? "Loading..." : "Send" }}
        </button>
      </div>
    </form>
  </div>
  <app-shop-address
    [shopContact]="shopService.shopDetails?.shopContact"
    [textColour]="contentService.content?.colourPalette?.sectionText || 'black'"
  ></app-shop-address>
</section>
