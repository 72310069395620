export class Order {
  id?: string;
  shopId?: string;
  orderRef?: string;
  clientInformation?: ClientInformation;
  deliveryInformation: DeliveryInformation = new DeliveryInformation();
  lineItems?: LineItem[];
  testMode: boolean = false;
  totalDueMonthly?: number;
  totalDueNow?: number;
  deliveryRate?: number;
  orderStatus?: string;
  createdAt?: string;
  customFieldValue?: string;
}

export class ClientInformation {
  // email?: string | null = 'peterboxitall@gmail.com';
  // name?: string | null = 'Peter';
  // surname?: string | null = 'Boxall';
  // phone?: string | null = '0825219985';
  email?: string | null = null;
  name?: string | null = null;
  surname?: string | null = null;
  phone?: string | null = null;
}

export class DeliveryInformation {
  // addressLine1?: string | null = '1 elm street';
  // addressLine2?: string | null = 'causeway way apartment';
  // suburb?: string | null = 'test';
  // city?: string | null = 'johannesburg';
  // postcode?: string | null = '24105';
  // country?: string | null = 'South Africa';

  addressLine1?: string | null = null;
  addressLine2?: string | null = null;
  suburb?: string | null = null;
  city?: string | null = null;
  postcode?: string | null = null;
  country?: string | null = 'South Africa';
  trackingLink: string | null = null;
}

export class LineItem {
  productId: string | undefined;
  productName: string | undefined;
  purchaseType: 'BUY-NOW' | 'SUBSCRIPTION' | undefined;
  contractLengthInMonths: number | undefined;
  productPrice: number | undefined;
  installationPrice: number | undefined;
  quantity: number | undefined;
}
