import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import * as moment from 'moment';
import { lastValueFrom, Observable, shareReplay, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ShopService } from '../shop/shop.service';
import { ConfirmPasswordRequest } from './model/confirm-password-request';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private shopService: ShopService) {}
  url: string = environment.API_URL;
  login(email: string | null, password: string | null): Observable<User> {
    return this.http
      .post<User>(this.url + '/auth/sign-in', {
        email: email,
        password,
        shopId: this.shopService?.shopDetails?.id,
      })
      .pipe(
        tap((res) => this.setSession(res)),
        shareReplay()
      );
  }

  forgotPassword(email: string) {
    return lastValueFrom(
      this.http.post<{ email: string }>(this.url + '/auth/forgot-password', {
        email: email,
      })
    );
  }

  confirmPassword(request: ConfirmPasswordRequest) {
    return lastValueFrom(
      this.http.post<ConfirmPasswordRequest>(
        this.url + '/auth/confirm-password',
        request
      )
    );
  }

  private setSession(authResult: any) {
    const expiresAt = moment().add(3600, 'second');

    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
  }

  public isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    if (expiration) {
      const expiresAt = JSON.parse(expiration);
      return moment(expiresAt);
    }
    return null;
  }

  getToken(): string | null {
    return localStorage.getItem('access_token');
  }
}
