import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ShopDetails } from '../../services/models/shop-details';

@Component({
  selector: 'app-seo-settings',
  templateUrl: './seo-settings.component.html',
  styleUrls: ['./seo-settings.component.scss'],
})
export class SeoSettingsComponent implements AfterViewInit {
  @Input()
  shopDetails: ShopDetails | null = null;
  @Output()
  seoSettingsUpdatedEvent: EventEmitter<any> = new EventEmitter<any>();

  keywords: string[] = [];
  description?: string | null;
  constructor() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.keywords = this.shopDetails?.seoKeywords || [];
      this.description = this.shopDetails?.seoDescription || '';
    });
  }

  updateSeoSettings() {}

  updateSeoKeywords(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const keyword = inputElement.value.trim();

    if (keyword.indexOf(',') > -1) {
      let keywords = keyword.split(',');
      keywords = keywords.filter((k) => k !== '');
      keywords = keywords.map((k) => k.trim());
      this.keywords = this.keywords.concat(keywords);
    } else {
      this.keywords.push(keyword);
    }

    inputElement.value = '';
    this.seoSettingsUpdatedEvent.emit({
      keywords: this.keywords,
      description: this.description,
    });
  }

  updateSeoDescription(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const description =
      inputElement && inputElement.value ? inputElement.value.trim() : null;
    this.description = description;
    this.seoSettingsUpdatedEvent.emit({
      keywords: this.keywords,
      description: description,
    });
  }

  removeKeyword(index: number) {
    this.keywords.splice(index, 1);
  }

  trackByIndex(index: number) {
    return index;
  }
}
