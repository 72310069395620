import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ShopDetails } from '../models/shop-details';
import { lastValueFrom, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UpdateShopDetails } from '../models/update-shop-details';

@Injectable({
  providedIn: 'root',
})
export class ShopService {
  constructor(private readonly http: HttpClient) {}
  apiUrl = environment.API_URL;
  shopDetails: ShopDetails | null = null;
  previewAllowed: boolean = false;
  async initializeShopDetails(shopId: string): Promise<void> {
    if (!shopId) {
      console.error('Cannot get shop details for shop, no shop id present');
      throw new Error('Cannot get shop details for shop');
    }

    this.shopDetails = await lastValueFrom(
      this.http.get<ShopDetails>(this.apiUrl + `/shop/${shopId}`)
    );
  }

  getShopOwner(): string | null {
    if (this.shopDetails?.id == '2b7f55b8-b7fd-4163-90bc-dfa359eb8fce') {
      return 'TRAXSIT';
    }

    return null;
  }

  updateShopDetails(shopDetails: UpdateShopDetails): Promise<ShopDetails> {
    return lastValueFrom(
      this.http.patch<ShopDetails>(this.apiUrl + `/shop`, shopDetails)
    );
  }

  getShopFromDomain(domain: string): Promise<ShopDetails> {
    return lastValueFrom(
      this.http.post<ShopDetails>(this.apiUrl + `/shop/domain-search`, {
        domain,
      })
    );
  }

  getDeliveryFee() {
    return this.shopDetails ? +this.shopDetails.deliveryFlatRate : 0;
  }

  getHostName() {
    const url = this.getUrl();
    let { hostname } = new URL(url);
    if (hostname.indexOf('localhost') !== -1) {
      hostname = hostname + ':4200';
    }
    return hostname.replace('www.', '');
  }

  verifyPayment(orderRef: string | null) {
    return lastValueFrom(
      this.http.post<any>(this.apiUrl + `/payment/capture`, {
        orderRef,
        shopId: this.shopDetails?.id,
      })
    );
  }

  connectPaymentAccount(
    paymentAppId: string | null,
    paymentAppKey: string | null
  ): any {
    return lastValueFrom(
      this.http.post<boolean>(this.apiUrl + `/payment/account-connect`, {
        paymentAppId,
        paymentAppKey,
      })
    );
  }

  disconnectPaymentAccount() {
    return lastValueFrom(
      this.http.post<boolean>(this.apiUrl + `/payment/account-disconnect`, {})
    );
  }

  private getUrl() {
    // Allows support for domain pointing if used
    return window.location.ancestorOrigins?.length > 0
      ? window.location.ancestorOrigins[0]
      : window.location.href;
  }
}
