import { Component, Input, OnInit } from '@angular/core';
import { ContentService } from '../../services/content/content.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  @Input()
  layoutType!:
    | 'image-right'
    | 'image-left'
    | 'image-bottom'
    | 'image-top'
    | 'no-image';
  @Input()
  image!: string;
  @Input()
  imageHeight!: number;
  @Input()
  content!: string;
  @Input()
  title!: string;
  @Input()
  textColour!: string;
  @Input()
  sectionTitleColour!: string;

  constructor(public contentService: ContentService) {}

  ngOnInit(): void {}

  showContactButton(): any {
    return (
      !!this.contentService.content?.aboutUsSection?.contactCallToActionTitle ||
      this.contentService.content?.aboutUsSection?.contactCallToActionTitle !==
        ''
    );
  }
}
