import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { CheckoutService } from '../../services/checkout/checkout.service';
import { LineItem } from '../../services/models/cart';
import { Product } from '../../services/models/product';
import { ProductService } from '../../services/product/product.service';
import { ShopService } from '../../services/shop/shop.service';
import { ToasterService } from '../../services/toaster/toaster.service';

@Component({
  selector: 'app-product-detail-traxsit',
  templateUrl: './product-detail-traxsit.component.html',
  styleUrls: ['./product-detail-traxsit.component.scss'],
})
export class ProductDetailTraxsitComponent implements OnInit {
  constructor(
    private activateRoute: ActivatedRoute,
    private productService: ProductService,
    public checkoutService: CheckoutService,
    private toasterService: ToasterService,
    private shopService: ShopService
  ) {}

  product?: Product;
  purchaseType: 'BUY-NOW' | 'SUBSCRIPTION' = 'SUBSCRIPTION';
  contractLengthInMonths: number | null = null;
  quantity: number = 1;
  errorMessage: string | null = null;
  loading: boolean = false;
  ngOnInit(): void {
    this.activateRoute.paramMap.subscribe((paramMap) => {
      const id = paramMap.get('id');
      if (id) {
        this.loading = true;
        this.productService
          .getProductById(id)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              this.product = res;
              this.product.buyNowPrice =
                this.productService.getProductByIdStatic(id)?.buyNowPrice;
              this.product.subscriptionPrice =
                this.productService.getProductByIdStatic(id)?.subscriptionPrice;
              this.product.installationPrice =
                this.productService.getProductByIdStatic(id)?.installationPrice;
              this.loading = false;
            },
            error: (error) => {
              this.loading = false;
              console.error(error);
            },
          });
      }
    });
  }

  clickAddProductToCart(): void {
    this.errorMessage = this.validateAddProduct();
    if (!this.errorMessage) {
      this.addProductToCart();
    }
  }

  addProductToCart(): void {
    this.errorMessage = null;
    if (!this.product) {
      return;
    }
    if (this.contractLengthInMonths === null) {
      this.errorMessage = 'Please select a contract term';
    } else {
      this.checkoutService.addLineItem({
        product: this.product,
        purchaseType: this.purchaseType,
        contractLengthInMonths: +this.contractLengthInMonths,
        installationPrice: this.product.installationPrice,
        quantity: this.quantity,
      });
      this.toasterService.success(
        'Product added',
        'Product added to cart successfully',
        4000
      );
    }
  }

  validateAddProduct(): string | null {
    const numberInCart = this.moreThanMaxForCart();

    if (!this.quantity) {
      return 'Please enter a quantity between 1 and 10';
    } else if (numberInCart) {
      return (
        'You cannot have more than 10 of one product in the cart, you already have: ' +
        numberInCart
      );
    } else if (this.conflictingContractLengths()) {
      return 'You cannot purchase contracts of different lengths at the same.';
    }

    return null;
  }

  conflictingContractLengths() {
    return this.checkoutService.cart.lineItems.find((item) => {
      if (!item.contractLengthInMonths) {
        return false;
      }
      return (
        +item.contractLengthInMonths !==
          (this.contractLengthInMonths ? +this.contractLengthInMonths : 0) &&
        item.purchaseType == 'SUBSCRIPTION' &&
        this.purchaseType == 'SUBSCRIPTION'
      );
    });
  }

  moreThanMaxForCart() {
    const lineItem: LineItem | null = this.getLineItemFromProduct();
    return lineItem
      ? this.checkoutService.cart.maxForCartReached(lineItem)
      : null;
  }

  getLineItemFromProduct(): LineItem | null {
    if (!this.product || !this.contractLengthInMonths) {
      return null;
    }
    return {
      product: this.product,
      purchaseType: this.purchaseType,
      contractLengthInMonths: +this.contractLengthInMonths,
      quantity: this.quantity,
    };
  }

  getShopCheckoutEnabled(): boolean {
    return this.shopService?.shopDetails?.checkoutEnabled || false;
  }
}
