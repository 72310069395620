import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(private toastr: ToastrService) {}

  success(title: string, mesesage?: string, timeout?: number) {
    this.toastr.success(mesesage, title, { timeOut: timeout });
  }

  error(title: string, message?: string, timeout?: number) {
    this.toastr.error(message, title, { timeOut: timeout });
  }
}
