<section class="clean-block clean-cart dark">
  <div class="container">
    <div class="block-heading">
      <h2 class="text-dark">Shopping Cart</h2>
    </div>
    <div class="content">
      <div class="row g-0">
        <div class="col-md-12 col-lg-8 text-center mt-4">
          <h3 *ngIf="checkoutService.cart.lineItems.length == 0">
            The Cart is currently empty
          </h3>
          <ng-container *ngFor="let lineItem of checkoutService.cart.lineItems">
            <div class="items">
              <div class="product">
                <div class="row justify-content-center align-items-center">
                  <div class="col-md-3">
                    <div *ngIf="lineItem.product.images" class="product-image">
                      <img
                        *ngIf="
                          lineItem.product.images &&
                          lineItem.product.images.length > 0
                        "
                        class="img-fluid d-block mx-auto image"
                        [src]="lineItem.product.images[0].link"
                      />
                    </div>
                  </div>
                  <div class="col-md-4 product-info">
                    <a
                      class="link-dark product-name"
                      [routerLink]="['/product-detail', lineItem.product.id]"
                      >{{ lineItem.product.title }}</a
                    >
                    <div class="product-specs">
                      <div>
                        <span>Type:&nbsp;</span
                        ><span class="value">{{ lineItem.purchaseType }}</span>
                      </div>
                      <div>
                        <span>Unit Price:&nbsp;</span>
                        <span class="value">{{
                          lineItem.product.price | currency : "R"
                        }}</span>
                      </div>
                      <div *ngIf="lineItem.product.maxOrderQuantity == 1">
                        <sub
                          >Maximum Order Quantity:
                          {{ lineItem.product.maxOrderQuantity }}</sub
                        >
                      </div>
                      <div
                        *ngIf="
                          lineItem.contractLengthInMonths &&
                          lineItem.contractLengthInMonths > 0
                        "
                      >
                        <span>Contract Length:&nbsp;</span
                        ><span class="value"
                          >{{ lineItem.contractLengthInMonths }} months</span
                        >
                      </div>
                      <div
                        *ngIf="
                          lineItem.product.installationPrice &&
                          lineItem.product.installationPrice > 0
                        "
                      >
                        <span>Installation:&nbsp;</span
                        ><span class="value">{{
                          lineItem.product.installationPrice | currency : "R"
                        }}</span>
                      </div>
                      <div *ngIf="lineItem.product.minOrderQuantity > 1">
                        <span>Min Order Quantity:&nbsp;</span>
                        <span class="value">{{
                          lineItem.product.minOrderQuantity
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 col-md-2 quantity">
                    <label class="form-label d-none d-md-block" for="quantity"
                      >Quantity</label
                    >
                    <div
                      class="d-xl-flex align-items-xl-center quantity-counter"
                    >
                      <input
                        class="d-inline form-control quantity-input"
                        type="number"
                        [min]="lineItem.product.minOrderQuantity"
                        [max]="lineItem.product.maxOrderQuantity"
                        [disabled]="lineItem.product.maxOrderQuantity == 1"
                        (change)="changeLineItemQuantity($event, lineItem)"
                        [value]="lineItem.quantity"
                      />
                    </div>
                  </div>
                  <div
                    *ngIf="lineItem.purchaseType == 'SUBSCRIPTION'"
                    class="col-4 col-md-2"
                  >
                    <span
                      >{{
                        (getSubscriptionPrice(lineItem) || 0) *
                          lineItem.quantity | currency : "R"
                      }}
                      pm</span
                    >
                    <br />
                  </div>
                  <div
                    *ngIf="lineItem.purchaseType == 'BUY-NOW'"
                    class="col-4 col-md-2"
                  >
                    <span>{{
                      (getBuyNowPrice(lineItem) || 0) * lineItem.quantity
                        | currency : "R"
                    }}</span>
                    <br />
                  </div>
                  <div class="col-2 col-md-1 price">
                    <i
                      (click)="removeLineItem(lineItem)"
                      class="fas fa-times remove-lineitem-icon"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-md-12 col-lg-4">
          <app-cart-summary
            [installationPrice]="checkoutService.calculateInstallationPrice()"
            (proceedToCheckout)="proceedToCheckout()"
          ></app-cart-summary>
        </div>
      </div>
    </div>
  </div>
</section>
