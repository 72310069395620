import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { ConfirmPasswordRequest } from '../../services/auth/model/confirm-password-request';
import { ContentService } from '../../services/content/content.service';
import { ToasterService } from '../../services/toaster/toaster.service';
import { ValidationService } from '../../services/validation/validation.service';

@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss'],
})
export class ConfirmPasswordComponent implements OnInit {
  loading = false;
  email: string | null = null;
  password: string | null = null;
  confirmPassword: string | null = null;
  token: string | null = null;

  errorMessage: string | null = null;

  constructor(
    private readonly auth: AuthService,
    private readonly toasterService: ToasterService,
    private readonly validationService: ValidationService,
    public readonly contentService: ContentService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.loading = true;

    this.activatedRoute.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.token = params['token'];
      this.loading = false;
    });
  }

  confirmPasswordRequest() {
    const passwordError = this.validationService.validatePassword(
      this.confirmPassword
    );
    if (passwordError) {
      this.toasterService.error(passwordError);
      return;
    }

    if (!this.email || !this.token) {
      this.toasterService.error(
        'Request is malformed please try again or contact support.'
      );
      return;
    }

    if (
      !this.password ||
      !this.confirmPassword ||
      this.password !== this.confirmPassword
    ) {
      this.toasterService.error('Passwords are empty or do not match.');
      return;
    }

    const error = this.validationService.validateEmail(this.email);
    if (error) {
      this.toasterService.error(error);
      return;
    }
    this.loading = true;
    const request: ConfirmPasswordRequest = {
      email: this.email || '',
      token: this.token || '',
      newPassword: this.password || '',
    };
    this.auth
      .confirmPassword(request)
      .then((res) => {
        this.loading = false;
        this.toasterService.success(
          'Password has been reset, please log in again'
        );
        this.auth.logout();
        this.router.navigateByUrl('login');
      })
      .catch((err) => {
        this.loading = false;
        this.toasterService.error('An error has occurred');
        console.error('Failed to send password reset email', err);
      });
  }
}
