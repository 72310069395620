import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-shop-settings',
  templateUrl: './admin-shop-settings.component.html',
  styleUrls: ['./admin-shop-settings.component.scss']
})
export class AdminShopSettingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
