<main class="page contact-us-page">
  <section class="clean-block clean-form dark">
    <div class="storefront-container">
      <main class="page landing-page">
        <app-landing
          *ngIf="activePage === 'landing'"
          [editMode]="true"
          [content]="contentService.content"
          [activeSlide]="activeSlide"
          (editItem)="editItemClicked($event)"
        ></app-landing>
        <app-contact *ngIf="activePage === 'contact' && !hideContact">
        </app-contact>
        <app-footer
          *ngIf="activePage === 'landing'"
          [editMode]="true"
          (editItem)="editItemClicked($event)"
        ></app-footer>
      </main>
    </div>
    <div class="text-start content-editor">
      <div class="card h-100">
        <div class="card-body content-editor-card overflow-auto">
          <div
            class="d-flex flex-column justify-content-between align-items-center pb-1 px-5"
          >
            <!--div class="d-flex view-type-container">
              <div class="selected mx-2">
                <div>
                  <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.3335 0H2.3335C1.2335 0 0.333496 0.9 0.333496 2V14C0.333496 15.1 1.2335 16 2.3335 16H9.3335V18H7.3335V20H15.3335V18H13.3335V16H20.3335C21.4335 16 22.3335 15.1 22.3335 14V2C22.3335 0.9 21.4335 0 20.3335 0ZM20.3335 14H2.3335V2H20.3335V14Z" fill="#222739"/>
                  </svg>
                  <span class="view-type-text"><b>Desktop</b></span>
                </div>
              </div>
              <div class="mx-2">
                <div>
                  <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.0635 0.0649414H2.60353C1.97615 0.102627 1.38898 0.386333 0.969568 0.854429C0.550153 1.32253 0.332372 1.93721 0.363533 2.56494V17.4349C0.332372 18.0627 0.550153 18.6774 0.969568 19.1455C1.38898 19.6136 1.97615 19.8973 2.60353 19.9349H12.0635C12.6909 19.8973 13.2781 19.6136 13.6975 19.1455C14.1169 18.6774 14.3347 18.0627 14.3035 17.4349V2.56494C14.3347 1.93721 14.1169 1.32253 13.6975 0.854429C13.2781 0.386333 12.6909 0.102627 12.0635 0.0649414ZM13.3035 17.4349C13.3341 17.7975 13.2213 18.1575 12.9895 18.4379C12.7577 18.7183 12.4253 18.8967 12.0635 18.9349H2.60353C2.24174 18.8967 1.90932 18.7183 1.67753 18.4379C1.44573 18.1575 1.33301 17.7975 1.36353 17.4349V2.56494C1.33301 2.20242 1.44573 1.84237 1.67753 1.56198C1.90932 1.28158 2.24174 1.10315 2.60353 1.06494H4.13353V1.57494C4.13353 1.84016 4.23889 2.09451 4.42643 2.28205C4.61396 2.46958 4.86832 2.57494 5.13353 2.57494H9.53353C9.79875 2.57494 10.0531 2.46958 10.2406 2.28205C10.4282 2.09451 10.5335 1.84016 10.5335 1.57494V1.06494H12.0635C12.4253 1.10315 12.7577 1.28158 12.9895 1.56198C13.2213 1.84237 13.3341 2.20242 13.3035 2.56494V17.4349Z" fill="#222739"/>
                  </svg>
                  <span class="view-type-text"><b>Mobile</b></span>
                </div>
              </div>
            </div-->
            <div
              class="d-flex align-items-center input-group-text-input search-box search-box-select"
            >
              <select (change)="changeEditorView($event)">
                <option value="landing">Home page</option>
                <option value="contact">Contact us</option>
              </select>
            </div>
            <label class="mb-3 choose" style="text-align: center"
              >Select the web page you want to edit</label
            >
          </div>
          <div class="accordion" role="tablist" id="content-acordion">
            <div
              id="colourPaletteAccordionItem"
              class="accordion-item"
              *ngIf="activePage === 'landing'"
            >
              <h2 class="accordion-header" role="tab">
                <button
                  class="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#content-acordion .item-1"
                  aria-expanded="false"
                  aria-controls="content-acordion .item-1"
                  #item1
                >
                  Colour Palette
                </button>
              </h2>
              <div
                *ngIf="activePage === 'landing'"
                class="accordion-collapse collapse item-1"
                role="tabpanel"
                data-bs-parent="#content-acordion"
              >
                <div class="accordion-body">
                  <div class="content-input-group align-items-center">
                    <label class="color-picker-form-label">Header Colour</label>
                    <div class="color-picker-input-container">
                      <input
                        (click)="dirtyContext()"
                        [(colorPicker)]="
                          contentService.content.colourPalette.sectionTitle
                        "
                        [style.background]="
                          contentService.content.colourPalette.sectionTitle
                        "
                        [style.color]="
                          contentService.content.colourPalette.sectionTitle
                        "
                        [cpPosition]="'left'"
                      />
                      <div>
                        {{ contentService.content.colourPalette.sectionTitle }}
                      </div>
                    </div>
                  </div>
                  <div class="content-input-group align-items-center">
                    <label class="color-picker-form-label">Text Colour</label>
                    <div class="color-picker-input-container">
                      <input
                        (click)="dirtyContext()"
                        [(colorPicker)]="
                          contentService.content.colourPalette.sectionText
                        "
                        [style.background]="
                          contentService.content.colourPalette.sectionText
                        "
                        [style.color]="
                          contentService.content.colourPalette.sectionText
                        "
                        [cpPosition]="'left'"
                      />
                      <div>
                        {{ contentService.content.colourPalette.sectionText }}
                      </div>
                    </div>
                  </div>
                  <div class="content-input-group align-items-center">
                    <label class="color-picker-form-label">Icon Colour</label>
                    <div class="color-picker-input-container">
                      <input
                        (click)="dirtyContext()"
                        [(colorPicker)]="
                          contentService.content.colourPalette.icon
                        "
                        [style.background]="
                          contentService.content.colourPalette.icon
                        "
                        [style.color]="
                          contentService.content.colourPalette.icon
                        "
                        [cpPosition]="'left'"
                      />
                      <div>{{ contentService.content.colourPalette.icon }}</div>
                    </div>
                  </div>
                  <div class="content-input-group align-items-center">
                    <label class="color-picker-form-label"
                      >Button Background Colour</label
                    >
                    <div class="color-picker-input-container">
                      <input
                        (click)="dirtyContext()"
                        [(colorPicker)]="
                          contentService.content.colourPalette.buttonBackground
                        "
                        [style.background]="
                          contentService.content.colourPalette.buttonBackground
                        "
                        [style.color]="
                          contentService.content.colourPalette.buttonBackground
                        "
                        [cpPosition]="'left'"
                      />
                      <div>
                        {{
                          contentService.content.colourPalette.buttonBackground
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="content-input-group align-items-center">
                    <label class="color-picker-form-label"
                      >Button Text Colour</label
                    >
                    <div class="color-picker-input-container">
                      <input
                        (click)="dirtyContext()"
                        [(colorPicker)]="
                          contentService.content.colourPalette.buttonText
                        "
                        [style.background]="
                          contentService.content.colourPalette.buttonText
                        "
                        [style.color]="
                          contentService.content.colourPalette.buttonText
                        "
                        [cpPosition]="'left'"
                      />
                      <div>
                        {{ contentService.content.colourPalette.buttonText }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="navigationAccordionItem"
              class="accordion-item"
              *ngIf="activePage === 'landing'"
            >
              <h2 class="accordion-header" role="tab">
                <button
                  class="accordion-button"
                  data-bs-toggle="collapse"
                  data-bs-target="#content-acordion .item-2"
                  aria-expanded="false"
                  aria-controls="content-acordion .item-2"
                  #item2
                >
                  Navigation
                </button>
              </h2>
              <div
                *ngIf="activePage === 'landing'"
                class="accordion-collapse collapse show item-2"
                role="tabpanel"
                data-bs-parent="#content-acordion"
              >
                <div class="accordion-body">
                  <div
                    *ngIf="!filesLoading"
                    class="d-flex flex-column content-input-group mt-0"
                  >
                    <label class="form-label"><b>Site Logo</b></label>
                    <label class="form-label-sub-text"
                      >This will show your logo next to your shop name in the
                      navigation bar.</label
                    >
                    <img
                      class="mb-3"
                      width="75px"
                      [src]="contentService.content.logo"
                    />
                    <div class="mb-2">
                      <app-image-selector
                        [filesLoading]="filesLoading"
                        [onlineAllowed]="false"
                        (chosenImage)="logoUpload($event)"
                      >
                      </app-image-selector>
                    </div>

                    <div class="input-group">
                      <label class="switch">
                        <input
                          (click)="toggleShowShopName()"
                          type="checkbox"
                          [checked]="contentService.content.showShopName"
                        />
                        <span class="slider round"></span>
                      </label>
                      <span class="switch-form-label"
                        >Show shop name in navbar</span
                      >
                    </div>
                  </div>
                  <hr />
                  <div
                    *ngIf="!filesLoading"
                    class="d-flex flex-column content-input-group"
                  >
                    <label class="form-label"><b>Favicon</b></label>
                    <label class="form-label-sub-text mb-0"
                      >This is the small icon in the browser tab which will
                      default to the shop logo after the page is published and
                      refreshed.</label
                    >

                    <img
                      *ngIf="contentService.content.favicon"
                      class="mb-3 mt-3"
                      width="75px"
                      [src]="contentService.content.favicon"
                    />

                    <div class="mt-2">
                      <app-image-selector
                        [filesLoading]="filesLoading"
                        [onlineAllowed]="false"
                        [maxWidthAndHeight]="32"
                        (chosenImage)="faviconUpload($event)"
                      ></app-image-selector>
                    </div>
                  </div>
                  <hr />

                  <div class="d-flex flex-column content-input-group">
                    <label class="form-label"><b>Navigation colours</b></label>
                    <label class="form-label-sub-text mb-0"
                      >These colours are for your top bar where people navigate
                      your website.</label
                    >
                  </div>

                  <div class="content-input-group align-items-center">
                    <label class="color-picker-form-label"
                      >Top Navigation Background Colour</label
                    >
                    <div class="color-picker-input-container">
                      <input
                        (click)="dirtyContext()"
                        [(colorPicker)]="
                          contentService.content.colourPalette.navBackground
                        "
                        [style.background]="
                          contentService.content.colourPalette.navBackground
                        "
                        [style.color]="
                          contentService.content.colourPalette.navBackground
                        "
                        [cpPosition]="'left'"
                      />
                      <div>
                        {{ contentService.content.colourPalette.navBackground }}
                      </div>
                    </div>
                  </div>
                  <div class="content-input-group align-items-center">
                    <label class="color-picker-form-label"
                      >Top Navigation Text Colour</label
                    >
                    <div class="color-picker-input-container">
                      <input
                        (click)="dirtyContext()"
                        [(colorPicker)]="
                          contentService.content.colourPalette.navText
                        "
                        [style.background]="
                          contentService.content.colourPalette.navText
                        "
                        [style.color]="
                          contentService.content.colourPalette.navText
                        "
                        [cpPosition]="'left'"
                      />
                      <div>
                        {{ contentService.content.colourPalette.navText }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="heroAccordionItem"
              class="accordion-item"
              *ngIf="activePage === 'landing'"
            >
              <h2 class="accordion-header" role="tab">
                <button
                  class="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#content-acordion .item-3"
                  aria-expanded="false"
                  aria-controls="content-acordion .item-3"
                  #item3
                >
                  Background Image
                  <i
                    *ngIf="!contentService.content.heroSection.enabled"
                    class="hidden-icon fa fa-eye-slash"
                  ></i>
                </button>
              </h2>
              <div
                *ngIf="activePage === 'landing'"
                class="accordion-collapse collapse item-3"
                role="tabpanel"
                data-bs-parent="#content-acordion"
              >
                <div class="accordion-body">
                  <div
                    *ngIf="
                      contentService.content.heroSection?.imageUrl &&
                      !filesLoading
                    "
                    class="content-input-group flex-column"
                  >
                    <label class="form-label"><b>Background Image</b></label>
                    <label class="form-label-sub-text mb-0"
                      >Your background image is the first content that people
                      see when they view your site. It will catch the attention
                      of the user.</label
                    >

                    <div class="input-group">
                      <label class="switch">
                        <input
                          (click)="toggleHeroSection()"
                          type="checkbox"
                          [checked]="contentService.content.heroSection.enabled"
                        />
                        <span class="slider round"></span>
                      </label>
                      <span class="switch-form-label"
                        >Show/Hide on Website</span
                      >
                    </div>

                    <img
                      class="mb-3 mt-2"
                      width="75px"
                      [src]="contentService.content.heroSection.imageUrl"
                    />
                    <div>
                      <app-image-selector
                        [filesLoading]="filesLoading"
                        (chosenImage)="heroUpload($event)"
                      ></app-image-selector>
                    </div>
                    <hr />

                    <div
                      class="d-xl-flex flex-column content-input-group mb-0 mt-0"
                    >
                      <label class="form-label">Background Image Position</label
                      ><select
                        class="form-select input-group-select"
                        (change)="dirtyContext()"
                        [(ngModel)]="
                          contentService.content.heroSection.backgroundPosition
                        "
                      >
                        <optgroup>
                          <option [value]="null">None</option>
                          <option [value]="'top'">Top</option>
                          <option [value]="'center'">Center</option>
                          <option [value]="'bottom'">Bottom</option>
                        </optgroup>
                      </select>
                    </div>
                  </div>
                  <app-spinner *ngIf="filesLoading"></app-spinner>

                  <div class="d-xl-flex flex-column content-input-group">
                    <label class="form-label">Background Image Title</label
                    ><input
                      type="text"
                      name="heroTitle"
                      class="input-group-text-input"
                      (change)="dirtyContext()"
                      [(ngModel)]="contentService.content.heroSection.title"
                      value="Keep track of your pride and joy"
                    />
                  </div>
                  <div class="d-xl-flex flex-column content-input-group">
                    <label class="form-label">Background Image SubTitle</label
                    ><input
                      type="text"
                      name="heroSubtitle"
                      class="input-group-text-input"
                      (change)="dirtyContext()"
                      [(ngModel)]="contentService.content.heroSection.subtitle"
                      value="A staggering R8. 5 billion worth of vehicles are stolen and hijacked in SA annually. The majority of these, almost 30%, are taken across the border to neighbouring countries where syndicates are making huge profits and South Africans are footing the bill."
                    />
                  </div>
                  <div class="d-xl-flex flex-column content-input-group mb-3">
                    <label class="form-label">Height</label
                    ><input
                      type="number"
                      name="heroHeight"
                      class="input-group-text-input"
                      (keyup)="restrictHeights()"
                      [max]="this.MAX_HEIGHT_LIMITS"
                      (change)="dirtyContext()"
                      [(ngModel)]="contentService.content.heroSection.height"
                    />
                  </div>
                  <div class="content-input-group align-items-center">
                    <label class="color-picker-form-label"
                      >Background Image Text Colour</label
                    >
                    <div class="color-picker-input-container">
                      <input
                        (click)="dirtyContext()"
                        [(colorPicker)]="
                          contentService.content.colourPalette.heroText
                        "
                        [style.background]="
                          contentService.content.colourPalette.heroText
                        "
                        [style.color]="
                          contentService.content.colourPalette.heroText
                        "
                        [cpPosition]="'left'"
                      />
                      <div>
                        {{ contentService.content.colourPalette.heroText }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="servicesAccordionItem"
              class="accordion-item"
              *ngIf="activePage === 'landing'"
            >
              <h2 class="accordion-header" role="tab">
                <button
                  class="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#content-acordion .item-4"
                  aria-expanded="false"
                  aria-controls="content-acordion .item-4"
                  #item4
                >
                  Services
                  <i
                    *ngIf="!contentService.content.serviceSection.enabled"
                    class="hidden-icon fa fa-eye-slash"
                  ></i>
                </button>
              </h2>
              <div
                class="accordion-collapse collapse item-4"
                role="tabpanel"
                data-bs-parent="#content-acordion"
              >
                <div class="accordion-body">
                  <div class="input-group">
                    <label class="switch">
                      <input
                        (click)="toggleServiceSection()"
                        type="checkbox"
                        [checked]="
                          contentService.content.serviceSection.enabled
                        "
                      />
                      <span class="slider round"></span>
                    </label>
                    <span class="switch-form-label">Show/Hide on Website</span>
                  </div>
                  <div class="d-xl-flex flex-column content-input-group mb-3">
                    <label class="form-label">Service Section title</label
                    ><input
                      type="text"
                      name="serviceSectionStitle"
                      class="input-group-text-input"
                      (change)="dirtyContext()"
                      [(ngModel)]="contentService.content.serviceSection.title"
                      value="Keep track of your pride and joy"
                    />
                  </div>
                  <label class="form-label"><b>Services</b></label>
                  <ng-container
                    *ngFor="
                      let s of contentService.content.serviceSection.services;
                      let indexOfelement = index
                    "
                  >
                    <div
                      class="border rounded editor-item-card"
                      @fadeIn
                      @fadeOut
                    >
                      <div
                        class="d-flex align-items-center delete-container"
                        (click)="removeService(s)"
                      >
                        <span class="delete-text">Delete</span>
                        <i class="far fa-trash-alt fs-5 delete-item-icon"></i>
                      </div>
                      <label
                        ><b>{{ "Service " + (indexOfelement + 1) }}</b></label
                      >
                      <div class="d-flex flex-column content-input-group">
                        <label class="form-label">Title</label
                        ><input
                          [name]="'service' + indexOfelement + 'Icon'"
                          (change)="dirtyContext()"
                          [(ngModel)]="s.title"
                          type="text"
                          class="long-input input-group-text-input"
                        />
                      </div>
                      <div class="d-flex flex-column content-input-group">
                        <label class="form-label">Text</label
                        ><textarea
                          [name]="'service' + indexOfelement + 'Icon'"
                          (change)="dirtyContext()"
                          [(ngModel)]="s.description"
                          class="input-group-text-input"
                        ></textarea>
                      </div>
                      <div class="d-flex content-input-group">
                        <div
                          (click)="clickedIconPreview(indexOfelement)"
                          class="service-icon-container"
                        >
                          <i [class]="s.icon + ' icon'"></i>
                        </div>
                        <input
                          #iconPickerInput
                          class="input-group-text-input icon-picker"
                          (keydown)="preventUserInput($event)"
                          (cut)="preventUserInput($event)"
                          (paste)="preventUserInput($event)"
                          style="flex: 1"
                          [name]="'service' + indexOfelement + 'Icon'"
                          [iconPicker]="s.icon"
                          [ipIconPack]="['fa5']"
                          [value]="s.icon"
                          [ipPosition]="'bottom'"
                          (iconPickerSelect)="
                            onIconPickerSelect($event, indexOfelement)
                          "
                        />
                      </div>
                    </div>
                  </ng-container>
                  <button
                    (click)="addService()"
                    class="btn btn-primary btn-action action-button btn-100"
                  >
                    + Add service
                  </button>
                </div>
              </div>
            </div>
            <div
              id="galleryAccordionItem"
              class="accordion-item"
              *ngIf="activePage === 'landing'"
            >
              <h2 class="accordion-header" role="tab">
                <button
                  class="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#content-acordion .item-5"
                  aria-expanded="false"
                  aria-controls="content-acordion .item-5"
                  #item5
                >
                  Image Gallery
                  <i
                    *ngIf="!contentService.content.gallerySection.enabled"
                    class="hidden-icon fa fa-eye-slash"
                  ></i>
                </button>
              </h2>
              <div
                class="accordion-collapse collapse item-5"
                role="tabpanel"
                data-bs-parent="#content-acordion"
              >
                <div class="accordion-body">
                  <!-- Rounded switch -->
                  <div class="input-group">
                    <label class="switch">
                      <input
                        (click)="toggleGallerySection()"
                        type="checkbox"
                        [checked]="
                          contentService.content.gallerySection.enabled
                        "
                      />
                      <span class="slider round"></span>
                    </label>
                    <span class="switch-form-label">Show/Hide on Website</span>
                  </div>
                  <div class="d-xl-flex flex-column content-input-group">
                    <label class="form-label">Section Title</label
                    ><input
                      type="text"
                      name="galleryTitle"
                      class="input-group-text-input"
                      (change)="dirtyContext()"
                      [(ngModel)]="contentService.content.gallerySection.title"
                    />
                  </div>
                  <div class="d-xl-flex flex-column content-input-group">
                    <label class="form-label">Section SubTitle</label
                    ><input
                      type="text"
                      name="gallerySubTitle"
                      class="input-group-text-input"
                      (change)="dirtyContext()"
                      [(ngModel)]="
                        contentService.content.gallerySection.subtitle
                      "
                    />
                  </div>
                  <div class="d-xl-flex flex-column content-input-group mb-3">
                    <label class="form-label">Height</label
                    ><input
                      type="number"
                      name="galleryHeight"
                      class="input-group-text-input"
                      (keyup)="restrictHeights()"
                      (change)="dirtyContext()"
                      [max]="this.MAX_HEIGHT_LIMITS"
                      [(ngModel)]="contentService.content.gallerySection.height"
                    />
                  </div>
                  <hr />
                  <label><b>Slides</b></label>
                  <div class="multi-card-container">
                    <ng-container
                      *ngFor="
                        let slide of contentService.content.gallerySection
                          .slides;
                        let indexOfelement = index
                      "
                    >
                      <div
                        class="border rounded editor-item-card"
                        (click)="editingSlide(indexOfelement)"
                        @fadeIn
                        @fadeOut
                      >
                        <div
                          class="d-flex align-items-center delete-container"
                          (click)="removeGallerySlide(slide)"
                        >
                          <span class="delete-text">Delete</span>
                          <i class="far fa-trash-alt fs-5 delete-item-icon"></i>
                        </div>
                        <div
                          class="d-flex flex-column flex-grow-1 justify-content-start edit-item-row"
                        >
                          <div
                            class="d-flex flex-column edit-image-group edit-group-full mb-4"
                          >
                            <label class="form-label"
                              ><b>Slide {{ indexOfelement + 1 }}</b></label
                            >
                            <div class="image-selector d-flex flex-column">
                              <img
                                class="image-display image-white-border mb-2"
                                [src]="slide.src"
                              />
                              <app-image-selector
                                [filesLoading]="filesLoading"
                                (chosenImage)="
                                  gallerySlideImageUpload(
                                    $event,
                                    indexOfelement
                                  )
                                "
                              ></app-image-selector>
                            </div>
                          </div>
                          <div class="mb-2 d-flex flex-column edit-group-full">
                            <label class="form-label">Slide Fit</label
                            ><select
                              class="form-select input-group-select"
                              (change)="dirtyContext()"
                              [(ngModel)]="
                                contentService.content.gallerySection.slides[
                                  indexOfelement
                                ].fit
                              "
                            >
                              <optgroup>
                                <option [value]="'cover'">Cover</option>
                                <option [value]="'contain'">Contain</option>
                                <option [value]="'fill'">Fill</option>
                              </optgroup>
                            </select>
                          </div>
                          <div class="d-flex flex-column edit-group-full">
                            <label class="form-label">Slide Text</label
                            ><input
                              type="text"
                              name="slideHeading"
                              class="input-group-text-input"
                              (change)="dirtyContext()"
                              [(ngModel)]="
                                contentService.content.gallerySection.slides[
                                  indexOfelement
                                ].headline
                              "
                            />
                          </div>
                          <div class="content-input-group align-items-center">
                            <label class="color-picker-form-label"
                              >Slide Text Colour</label
                            >
                            <div class="color-picker-input-container">
                              <input
                                (click)="dirtyContext()"
                                [(colorPicker)]="
                                  contentService.content.colourPalette
                                    .galleryText[indexOfelement]
                                "
                                [style.background]="
                                  contentService.content.colourPalette
                                    .galleryText[indexOfelement]
                                "
                                [style.color]="
                                  contentService.content.colourPalette
                                    .galleryText[indexOfelement]
                                "
                                [cpPosition]="'left'"
                                [cpFallbackColor]="'#FFFFFF'"
                              />
                              <div>
                                {{
                                  contentService.content.colourPalette
                                    .galleryText[indexOfelement] || "#FFFFFF"
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <button
                    (click)="addGallerySlide()"
                    id="gallerySlideAdd"
                    class="btn btn-primary btn-action action-button btn-100"
                  >
                    + Add slide
                  </button>
                </div>
              </div>
            </div>
            <div
              id="featuredProductsAccordionItem"
              class="accordion-item"
              *ngIf="activePage === 'landing'"
            >
              <h2 class="accordion-header" role="tab">
                <button
                  class="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#content-acordion .item-6"
                  aria-expanded="false"
                  aria-controls="content-acordion .item-6"
                  #item6
                >
                  Featured Products
                  <i
                    *ngIf="
                      !contentService.content.featuredProductsSection.enabled
                    "
                    class="hidden-icon fa fa-eye-slash"
                  ></i>
                </button>
              </h2>
              <div
                class="accordion-collapse collapse item-6"
                role="tabpanel"
                data-bs-parent="#content-acordion"
              >
                <div class="accordion-body">
                  <!-- Rounded switch -->
                  <div class="input-group">
                    <label class="switch">
                      <input
                        (click)="toggleFeaturedSection()"
                        type="checkbox"
                        [checked]="
                          contentService.content.featuredProductsSection.enabled
                        "
                      />
                      <span class="slider round"></span>
                    </label>
                    <span class="switch-form-label">Show/Hide on Website</span>
                  </div>
                  <div class="d-xl-flex flex-column content-input-group">
                    <label class="form-label">Section Title</label
                    ><input
                      class="input-group-text-input"
                      type="text"
                      name="featuredProductsTitle"
                      (change)="dirtyContext()"
                      [(ngModel)]="
                        contentService.content.featuredProductsSection.title
                      "
                    />
                  </div>
                  <div class="d-xl-flex flex-column content-input-group">
                    <label class="form-label">Section SubTitle</label
                    ><input
                      class="input-group-text-input"
                      type="text"
                      name="featuredProductsSubTitle"
                      (change)="dirtyContext()"
                      [(ngModel)]="
                        contentService.content.featuredProductsSection.subtitle
                      "
                    />
                  </div>
                  <hr />
                  <label><b>Product cards</b></label>
                  <div class="multi-card-container">
                    <ng-container
                      *ngFor="
                        let prod of contentService.content
                          .featuredProductsSection.featuredProducts;
                        let indexOfelement = index
                      "
                    >
                      <div
                        class="border rounded editor-item-card"
                        @fadeIn
                        @fadeOut
                      >
                        <div
                          class="d-flex align-items-center delete-container"
                          (click)="removeFeaturedProduct(prod)"
                        >
                          <span class="delete-text">Delete</span>
                          <i class="far fa-trash-alt fs-5 delete-item-icon"></i>
                        </div>
                        <div
                          class="d-flex flex-row flex-grow-1 justify-content-start edit-item-row"
                        >
                          <div
                            class="d-flex flex-column edit-image-group edit-group-half"
                          >
                            <label class="form-label">Featured Image</label>
                            <div class="image-selector">
                              <div class="d-flex flex-column">
                                <img
                                  class="image-display mb-2"
                                  [src]="prod.productImage"
                                />
                                <app-image-selector
                                  [filesLoading]="filesLoading"
                                  [onlineAllowed]="false"
                                  (chosenImage)="
                                    featuredProductImageUpload(
                                      $event,
                                      indexOfelement
                                    )
                                  "
                                ></app-image-selector>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex flex-column edit-group-half">
                            <label class="form-label">Title</label
                            ><input
                              [name]="'prodTitle' + indexOfelement"
                              (change)="dirtyContext()"
                              [(ngModel)]="prod.productTitle"
                              type="text"
                              class="long-input input-group-text-input"
                            />
                          </div>
                        </div>
                        <div
                          class="d-flex flex-row flex-grow-1 justify-content-start edit-item-row"
                        >
                          <div class="d-flex flex-column edit-group-full">
                            <label class="form-label">Product Subtitle</label
                            ><input
                              class="input-group-text-input"
                              [name]="'prodSubTitle' + indexOfelement"
                              (change)="dirtyContext()"
                              [(ngModel)]="prod.productSubtitle"
                              type="text"
                            />
                          </div>
                        </div>
                        <div
                          class="d-flex flex-row flex-grow-1 justify-content-start edit-item-row"
                        >
                          <div class="d-flex flex-column edit-group-full">
                            <label class="form-label">Link to Product</label
                            ><select
                              class="form-select input-group-select"
                              (change)="dirtyContext()"
                              [(ngModel)]="
                                contentService.content.featuredProductsSection
                                  .featuredProducts[indexOfelement].productId
                              "
                            >
                              <optgroup>
                                <option [value]="null">None</option>
                                <option [value]="'catalogue'">
                                  Direct To Catalogue
                                </option>
                                <ng-container
                                  *ngFor="let product of productList"
                                >
                                  <option [value]="product.id">
                                    {{ product.title }}
                                  </option>
                                </ng-container>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <button
                    (click)="addFeaturedProduct()"
                    id="featuredProductAdd"
                    class="btn btn-primary btn-action action-button btn-100"
                  >
                    + Add product card
                  </button>
                </div>
              </div>
            </div>
            <div
              id="aboutUsAccordionItem"
              class="accordion-item"
              *ngIf="activePage === 'landing'"
            >
              <h2 class="accordion-header" role="tab">
                <button
                  class="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#content-acordion .item-8"
                  aria-expanded="false"
                  aria-controls="content-acordion .item-8"
                  #item8
                >
                  About Us Section
                  <i
                    *ngIf="!contentService.content.aboutUsSection.enabled"
                    class="hidden-icon fa fa-eye-slash"
                  ></i>
                </button>
              </h2>
              <div
                class="accordion-collapse collapse item-8"
                role="tabpanel"
                data-bs-parent="#content-acordion"
              >
                <div class="accordion-body">
                  <!-- Rounded switch -->
                  <div class="input-group">
                    <label class="switch">
                      <input
                        (click)="toggleAboutUsSection()"
                        type="checkbox"
                        [checked]="
                          contentService.content.aboutUsSection.enabled
                        "
                      />
                      <span class="slider round"></span>
                    </label>
                    <span class="switch-form-label">Show/Hide on Website</span>
                  </div>
                  <div class="d-xl-flex flex-column content-input-group">
                    <label class="form-label">About Us Title</label
                    ><input
                      type="text"
                      class="input-group-text-input"
                      name="aboutUsTitle"
                      (change)="dirtyContext()"
                      [(ngModel)]="contentService.content.aboutUsSection.title"
                      value="About Us"
                    />
                  </div>
                  <div
                    class="d-flex flex-row flex-grow-1 justify-content-start edit-item-row"
                  >
                    <div class="d-flex flex-column edit-group-full">
                      <label class="form-label">Content</label
                      ><textarea
                        class="input-group-text-input"
                        name="aboutUsSectionContent"
                        (change)="dirtyContext()"
                        [(ngModel)]="
                          contentService.content.aboutUsSection.content
                        "
                      ></textarea>
                    </div>
                  </div>
                  <div class="d-xl-flex flex-column content-input-group mb-3">
                    <label class="form-label">Contact Button Text</label
                    ><input
                      type="text"
                      class="input-group-text-input"
                      name="featuredProductsButton"
                      (change)="dirtyContext()"
                      [(ngModel)]="
                        contentService.content.aboutUsSection
                          .contactCallToActionTitle
                      "
                      value="Keep track of your pride and joy"
                    />
                  </div>
                  <hr />
                  <div
                    *ngIf="
                      contentService.content.aboutUsSection?.image &&
                      !filesLoading
                    "
                    class="content-input-group flex-column"
                  >
                    <label class="form-label"><b>About us image</b></label>
                    <img
                      class="mb-3"
                      width="75px"
                      [src]="contentService.content.aboutUsSection.image"
                    />
                    <div>
                      <app-image-selector
                        [filesLoading]="filesLoading"
                        (chosenImage)="aboutUsUpload($event)"
                      ></app-image-selector>
                    </div>
                  </div>
                  <app-spinner *ngIf="filesLoading"></app-spinner>
                  <div class="d-xl-flex flex-column content-input-group mb-3">
                    <label class="form-label">Image Height</label
                    ><input
                      type="number"
                      class="input-group-text-input"
                      name="heroHeight"
                      (change)="dirtyContext()"
                      (keyup)="restrictHeights()"
                      [max]="this.MAX_HEIGHT_LIMITS"
                      [(ngModel)]="
                        contentService.content.aboutUsSection.imageHeight
                      "
                    />
                  </div>
                  <div class="d-xl-flex flex-column content-input-group mb-3">
                    <label class="form-label">Layout Type</label
                    ><select
                      class="form-select input-group-select"
                      (change)="dirtyContext()"
                      [(ngModel)]="
                        contentService.content.aboutUsSection.layoutType
                      "
                    >
                      <optgroup label="- Select -">
                        <option [value]="'image-left'">Image Left</option>
                        <option [value]="'image-right'">Image Right</option>
                        <option [value]="'image-top'">Image Top</option>
                        <option [value]="'image-bottom'">Image Bottom</option>
                        <option [value]="'no-image'">No Image</option>
                      </optgroup>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="footerAccordionItem"
              class="accordion-item"
              *ngIf="activePage === 'landing'"
            >
              <h2 class="accordion-header" role="tab">
                <button
                  class="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#content-acordion .item-7"
                  aria-expanded="false"
                  aria-controls="content-acordion .item-7"
                  #item7
                >
                  Footer
                </button>
              </h2>
              <div
                class="accordion-collapse collapse item-7"
                role="tabpanel"
                data-bs-parent="#content-acordion"
              >
                <div class="accordion-body">
                  <div
                    class="content-input-group d-flex flex-column switch-container"
                  >
                    <label>Show Shop Address & Tel</label>
                    <label class="switch">
                      <input
                        (click)="toggleShopAddress()"
                        type="checkbox"
                        [checked]="
                          contentService.content.footerSection.showShopAddress
                        "
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div class="content-input-group align-items-center">
                    <label class="color-picker-form-label"
                      >Footer Section Background Colour</label
                    >
                    <div class="color-picker-input-container">
                      <input
                        (click)="dirtyContext()"
                        [(colorPicker)]="
                          contentService.content.colourPalette.footerBackground
                        "
                        [style.background]="
                          contentService.content.colourPalette.footerBackground
                        "
                        [style.color]="
                          contentService.content.colourPalette.footerBackground
                        "
                        [cpPosition]="'left'"
                      />
                      <div>
                        {{
                          contentService.content.colourPalette.footerBackground
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="content-input-group align-items-center">
                    <label class="color-picker-form-label"
                      >Footer Text Colour</label
                    >
                    <div class="color-picker-input-container">
                      <input
                        (click)="dirtyContext()"
                        [(colorPicker)]="
                          contentService.content.colourPalette.footerText
                        "
                        [style.background]="
                          contentService.content.colourPalette.footerText
                        "
                        [style.color]="
                          contentService.content.colourPalette.footerText
                        "
                        [cpPosition]="'left'"
                      />
                      <div>
                        {{ contentService.content.colourPalette.footerText }}
                      </div>
                    </div>
                  </div>
                  <div class="d-xl-flex flex-column content-input-group">
                    <label class="form-label">Instagram Profile link</label
                    ><input
                      type="text"
                      class="input-group-text-input"
                      name="instagramLink"
                      (change)="dirtyContext()"
                      [(ngModel)]="
                        contentService.content.footerSection
                          .instagramProfileLink
                      "
                    />
                  </div>
                  <div class="d-xl-flex flex-column content-input-group">
                    <label class="form-label">Facebook Profile link</label
                    ><input
                      type="text"
                      class="input-group-text-input"
                      name="facebookLink"
                      (change)="dirtyContext()"
                      [(ngModel)]="
                        contentService.content.footerSection.facebookProfileLink
                      "
                    />
                  </div>
                  <div class="d-xl-flex flex-column content-input-group">
                    <label class="form-label">Twitter Profile Link</label
                    ><input
                      type="text"
                      class="input-group-text-input"
                      name="twitterProfileLink"
                      (change)="dirtyContext()"
                      [(ngModel)]="
                        contentService.content.footerSection.twitterProfileLink
                      "
                    />
                  </div>
                  <div class="d-xl-flex flex-column content-input-group">
                    <label class="form-label">Terms of Service link</label
                    ><input
                      type="text"
                      class="input-group-text-input"
                      name="termsAndConditionsLink"
                      (change)="dirtyContext()"
                      [(ngModel)]="
                        contentService.content.footerSection
                          .termsAndConditionsLink
                      "
                    />
                  </div>
                  <div class="d-xl-flex flex-column content-input-group">
                    <label class="form-label">Privacy Policy link</label
                    ><input
                      type="text"
                      class="input-group-text-input"
                      name="privacyPolicyLink"
                      (change)="dirtyContext()"
                      [(ngModel)]="
                        contentService.content.footerSection.privacyPolicyLink
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              id="contactFormAccordionItem"
              class="accordion-item"
              *ngIf="activePage === 'contact'"
            >
              <h2 class="accordion-header" role="tab">
                <button
                  class="accordion-button collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#content-acordion .item-9"
                  aria-expanded="false"
                  aria-controls="content-acordion .item-9"
                  #item9
                >
                  Contact Form Settings
                </button>
              </h2>
              <div
                class="accordion-collapse collapse item-9"
                role="tabpanel"
                data-bs-parent="#content-acordion"
              >
                <div class="accordion-body">
                  <div class="d-xl-flex flex-column content-input-group">
                    <label class="form-label">Contact Form Title</label
                    ><input
                      type="text"
                      class="input-group-text-input"
                      name="formTitle"
                      (change)="dirtyContext()"
                      [(ngModel)]="
                        contentService.content.contactFormSettings
                          .contactFormTitle
                      "
                    />
                  </div>
                  <div class="d-xl-flex flex-column content-input-group">
                    <label class="form-label">Contact Form Subtitle</label
                    ><input
                      type="text"
                      class="input-group-text-input"
                      name="formSubtitle"
                      (change)="dirtyContext()"
                      [(ngModel)]="
                        contentService.content.contactFormSettings
                          .contactFormSubtitle
                      "
                    />
                  </div>
                  <div class="input-group" style="margin-top: 20px">
                    <label class="switch">
                      <input
                        (change)="changeCapturePhone()"
                        type="checkbox"
                        class="toggle"
                        [(ngModel)]="
                          contentService.content.contactFormSettings
                            .capturePhone
                        "
                      />
                      <span class="slider round"></span>
                    </label>
                    <span class="switch-form-label">Capture Phone Number</span>
                  </div>
                  <!--div class="d-xl-flex flex-column content-input-group">
                    <label class="form-label">Capture Phone Number</label
                    ><select
                      class="form-select input-group-select"
                      (change)="dirtyContext()"
                      [(ngModel)]="content.contactFormSettings.capturePhone"
                    >
                      <optgroup label="- Select -">
                        <option [value]="false">No</option>
                        <option [value]="true">Yes</option>
                      </optgroup>
                    </select>
                  </div-->
                </div>
              </div>
            </div>
          </div>
          <div class="action-button-content">
            <div
              *ngIf="!loading && !filesLoading"
              class="d-flex flex-column justify-content-center align-items-center action-button-bar"
            >
              <!--button
              (click)="resetContent()"
              class="btn btn-secondary action-button"
              id="reset-button"
              type="button"
            >
              Reset
            </button-->
              <div class="d-flex justify-content-center btn-100 px-4">
                <button
                  (click)="preview()"
                  class="btn-action action-button action-button-outline"
                  id="preview-button"
                  type="button"
                >
                  <div class="d-flex justify-content-center align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                    >
                      <g fill="#fff">
                        <path
                          d="M10 14.5a4.5 4.5 0 1 1 4.5-4.5 4.5 4.5 0 0 1-4.5 4.5zM10 3C3 3 0 10 0 10s3 7 10 7 10-7 10-7-3-7-10-7z"
                        />
                        <circle cx="10" cy="10" r="2.5" />
                      </g>
                    </svg>
                    <div style="padding-left: 10px">View site</div>
                  </div>
                </button>
                <div style="padding: 11px"></div>
                <button
                  (click)="saveContent()"
                  class="btn btn-primary btn-action action-button"
                  id="save-button"
                  type="button"
                >
                  Publish site
                </button>
              </div>
              <div class="d-flex align-items-center" style="padding-top: 10px">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.00574 0.619233C5.16196 0.775506 5.24973 0.987429 5.24973 1.2084C5.24973 1.42937 5.16196 1.64129 5.00574 1.79757L3.51157 3.29173H10.0416C11.3601 3.29173 12.649 3.68273 13.7454 4.41527C14.8417 5.14781 15.6962 6.189 16.2008 7.40718C16.7054 8.62535 16.8374 9.9658 16.5801 11.259C16.3229 12.5522 15.688 13.7401 14.7556 14.6724C13.8233 15.6048 12.6354 16.2397 11.3422 16.497C10.049 16.7542 8.70852 16.6222 7.49035 16.1176C6.27217 15.613 5.23098 14.7585 4.49844 13.6622C3.7659 12.5659 3.3749 11.2769 3.3749 9.9584C3.3749 9.73739 3.4627 9.52543 3.61898 9.36915C3.77526 9.21286 3.98722 9.12507 4.20824 9.12507C4.42925 9.12507 4.64121 9.21286 4.79749 9.36915C4.95377 9.52543 5.04157 9.73739 5.04157 9.9584C5.04157 10.9473 5.33482 11.914 5.88422 12.7363C6.43363 13.5585 7.21452 14.1994 8.12815 14.5778C9.04178 14.9562 10.0471 15.0553 11.017 14.8623C11.9869 14.6694 12.8778 14.1932 13.5771 13.4939C14.2764 12.7947 14.7526 11.9038 14.9455 10.9339C15.1384 9.96395 15.0394 8.95861 14.661 8.04498C14.2825 7.13135 13.6417 6.35046 12.8194 5.80105C11.9972 5.25165 11.0305 4.9584 10.0416 4.9584H3.51157L5.00574 6.45257C5.15754 6.60974 5.24153 6.82024 5.23963 7.03873C5.23773 7.25723 5.15009 7.46624 4.99559 7.62075C4.84108 7.77526 4.63207 7.8629 4.41357 7.8648C4.19507 7.86669 3.98457 7.7827 3.8274 7.6309L0.910737 4.71423C0.754511 4.55796 0.666748 4.34604 0.666748 4.12507C0.666748 3.9041 0.754511 3.69217 0.910737 3.5359L3.8274 0.619233C3.98368 0.463007 4.1956 0.375244 4.41657 0.375244C4.63754 0.375244 4.84946 0.463007 5.00574 0.619233Z"
                    fill="white"
                  />
                </svg>
                <div (click)="resetContent()" class="reset-to-default-link">
                  Discard unpublished changes
                </div>
              </div>
            </div>
            <div
              *ngIf="loading || filesLoading"
              class="d-flex flex-column justify-content-center align-items-center action-button-bar"
              style="color: #ffffff"
            >
              <app-spinner></app-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
