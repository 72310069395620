<section class="clean-block clean-form dark">
  <div class="container">
    <div class="block-heading">
      <h2 class="text-dark">SpeedySell Feedback</h2>
      <p>
        Your feedback is incredibly valuable to us. <br />Please let us know of
        any issues or improvements that bring you value and we will get to work
        on making it happen!
      </p>
    </div>
    <form class="text-dark border-dark">
      <div class="mb-3">
        <label class="form-label" for="name">Feedback Type</label
        ><select name="type" [(ngModel)]="feedback.type" class="form-select">
          <option [value]="undefined" selected="">-Select-</option>
          <option value="BUG" selected="">Bug Report / System Fault</option>
          <option value="FEATURE">New Feature Request</option>
          <option value="OTHER">General Feedback</option>
        </select>
      </div>
      <p *ngIf="feedback.type === 'BUG'">
        Please explain below the steps that resulted in the unexpected behavior
      </p>
      <p *ngIf="feedback.type === 'FEATURE'">
        Please explain the feature and how it would bring you value.
      </p>
      <div *ngIf="feedback" class="mb-3">
        <label class="form-label" for="phone">Description</label
        ><textarea
          placeholder="e.g. I would like a contact form for customers to get in contact with my shop admin..."
          [(ngModel)]="feedback.description"
          class="form-control"
          type="text"
          id="description"
          name="description"
        ></textarea>
      </div>
      <div class="d-flex flex-column edit-image-group edit-group-full mb-4">
        <label class="form-label">Screenshot</label>
        <div class="image-selector">
          <img
            *ngIf="feedback.image"
            class="image-display mb-5"
            width="100%"
            [src]="feedback.image"
          />
          <input
            class="file-input"
            *ngIf="!filesLoading"
            name="screenshotFileInput"
            (change)="uploadScreenshot($event)"
            type="file"
          />
          <p class="text-muted mt-4">Only PNG & JPG, max 3mb</p>
        </div>
        <app-spinner *ngIf="filesLoading"></app-spinner>
      </div>
      <div class="mb-3">
        <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
        <button
          [disabled]="loading"
          class="btn btn-dark w-100"
          type="submit"
          (click)="submit()"
        >
          {{ loading ? "Loading..." : "Send" }}
        </button>
      </div>
    </form>
  </div>
</section>
