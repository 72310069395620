import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { debounce, Subject, take, timer } from 'rxjs';
import { ContentService } from '../../services/content/content.service';
import { OrderSearchFilter } from '../../services/models/order-search-filter';
import { ToasterService } from '../../services/toaster/toaster.service';

@Component({
  selector: 'app-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss'],
})
export class ImageSelectorComponent {
  closeResult = '';

  @ViewChild('closer')
  closer: ElementRef | null = null;

  @Output('chosenImage')
  chosenImage: EventEmitter<string> = new EventEmitter<string>();

  @Input('filesLoading')
  filesLoading: boolean = false;

  @Input('maxWidthAndHeight')
  maxWidthAndHeight: number | null = null;

  @Input('onlineAllowed')
  onlineAllowed: boolean = true;

  searchTerm: string | null = null;
  searchTermSubject: Subject<string> = new Subject();
  images: any[] = [];
  page = 1;

  constructor(
    private modalService: NgbModal,
    private readonly contentService: ContentService,
    private readonly toasterService: ToasterService
  ) {}

  open(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.chosenImage.emit(result);
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  async imageUpload($event: Event) {
    this.filesLoading = true;
    $event.preventDefault();
    const file = ($event as any)?.target?.files[0];
    if (this.maxWidthAndHeight) {
      const dimensions: number[] = await this.retrieveImageDimensionsFromFile(
        file
      );
      if (
        dimensions[0] != this.maxWidthAndHeight ||
        dimensions[1] != this.maxWidthAndHeight
      ) {
        this.toasterService.error(
          'Cannot Upload',
          `Image must be ${this.maxWidthAndHeight}x${this.maxWidthAndHeight} pixels`
        );
        this.filesLoading = false;
        return;
      }
    }

    this.contentService
      .uploadFile(file)
      .then((result: any) => {
        this.chosenImage.emit(result.filePath);
        this.filesLoading = false;
        this.modalService.dismissAll();
      })
      .catch((error: any) => {
        this.filesLoading = false;
        this.toasterService.error(
          'Failed to upload image.',
          error.error.message
        );
        console.error('Failed to upload file', error);
      });
  }

  getUnsplashImages() {
    this.filesLoading = true;
    if (this.searchTerm) {
      this.contentService
        .unsplashImageSearch(this.searchTerm, this.page)
        .pipe(
          take(1),
          debounce(() => timer(500))
        )
        .subscribe({
          next: (res: any) => {
            this.images = res;
            this.filesLoading = false;
          },
          error: (error: any) => {
            this.filesLoading = false;
            console.error(error);
          },
        });
    }
  }

  changeTerm(term: any) {
    const value = term?.target?.value;
    if (value && value !== '') {
      this.page = 1;
      this.searchTerm = value;
      this.getUnsplashImages();
    }

    if (value == '') {
      this.searchTerm = null;
      this.getUnsplashImages();
    }
  }

  nextPage() {
    this.page += 1;
    this.getUnsplashImages();
  }
  previousPage() {
    if (this.page > 1) {
      this.page -= 1;
      this.getUnsplashImages();
    }
  }

  retrieveImageDimensionsFromFile(file: File): Promise<any[]> {
    var url = URL.createObjectURL(file);
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve([img.height, img.width]);
      img.onerror = reject;
      img.src = url;
    });
  }
}
