import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '../../services/content/content.service';
import { OrderService } from '../../services/order/order.service';
import { ShopService } from '../../services/shop/shop.service';
@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss'],
})
export class PaymentStatusComponent implements OnInit {
  orderId: any;
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly orderService: OrderService,
    public readonly shopService: ShopService,
    public readonly contentService: ContentService
  ) {}

  success: boolean | null = null;
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (!params['success']) {
        console.error('Could not find success status on the url provided.');
        return;
      }
      this.success = params['success'] === 'true';

      if (!this.success) {
        if (!params['order-id']) {
          console.error('Could not find order Id on the url provided.');
          return;
        }

        this.orderId = params['order-id'];
        this.cancelOrder();
      }
    });
  }

  cancelOrder() {
    this.orderService
      .paymentCancelled(this.orderId)
      .then((res) => {
        console.debug('Order cancelled');
      })
      .catch((error) => {
        console.error('Failed to cancel order', error);
      });
  }
}
