import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckoutService } from '../../../services/checkout/checkout.service';
import { ClientInformation } from '../../../services/models/order';
import { ValidationService } from '../../../services/validation/validation.service';

@Component({
  selector: 'app-customer-info-form',
  templateUrl: './customer-info-form.component.html',
  styleUrls: ['./customer-info-form.component.scss'],
})
export class CustomerInfoFormComponent implements OnInit {
  constructor(
    public checkoutService: CheckoutService,
    private validation: ValidationService
  ) {}
  clientInformation: ClientInformation = new ClientInformation();
  errorMessage: string | null = null;
  ngOnInit(): void {}

  proceedToNextStep() {
    this.errorMessage = this.validation.validateCustomerInfo(
      this.clientInformation
    );
    if (!this.errorMessage) {
      this.checkoutService.order.clientInformation = this.clientInformation;
      this.checkoutService.checkoutStep = 2;
    }
  }
}
