import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, take, debounce, timer } from 'rxjs';
import { Category, Product } from '../../services/models/product';
import { ProductSearchFilter } from '../../services/models/product-search-filter';
import { ProductService } from '../../services/product/product.service';
import { ToasterService } from '../../services/toaster/toaster.service';

@Component({
  selector: 'app-admin-product-list',
  templateUrl: './admin-product-list.component.html',
  styleUrls: ['./admin-product-list.component.scss'],
})
export class AdminProductListComponent implements OnInit {
  constructor(
    private readonly productService: ProductService,
    private readonly toasterService: ToasterService,
    private readonly router: Router
  ) {}

  searchProductFilter: ProductSearchFilter = new ProductSearchFilter();
  searchTerm: Subject<string> = new Subject();
  products: Product[] = [];
  count: number = 0;
  catLoading: boolean = false;
  categories: Category[] = [];
  categoryToAdd: string | null = null;
  loading: boolean = false;

  ngOnInit(): void {
    this.getProducts();
    this.getCategories();
  }

  getProducts() {
    this.loading = true;
    this.productService
      .getProducts(this.searchProductFilter)
      .pipe(
        take(1),
        debounce(() => timer(500))
      )
      .subscribe({
        next: (res) => {
          this.products = res.products;
          this.count = res.count;
          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          console.error(error);
        },
      });
  }

  getCategories() {
    this.catLoading = true;
    this.productService
      .getCategories()
      .pipe(
        take(1),
        debounce(() => timer(500))
      )
      .subscribe({
        next: (res) => {
          this.categories = res;
          this.catLoading = false;
        },
        error: (error) => {
          this.catLoading = false;
          console.error(error);
        },
      });
  }

  setPage(event: number) {
    this.searchProductFilter.page = +event;
    this.getProducts();
  }

  changeTerm(term: any) {
    const value = term?.target?.value;
    if (value && value !== '') {
      this.searchProductFilter.title = value;
      this.getProducts();
    }

    if (value == '') {
      this.searchProductFilter.title = null;
      this.getProducts();
    }
  }

  deleteCategory(categoryId: string) {
    this.catLoading = true;
    this.productService
      .deleteCategory(categoryId)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          console.debug('deleted category: ');
          console.debug(res);
          this.toasterService.success('Category deleted', undefined, 4000);
          this.getCategories();
        },
        error: (error) => {
          this.toasterService.error(
            'Failed to delete category',
            'Ensure there are no products asssigned to this category',
            4000
          );
          this.catLoading = false;
          console.error(error);
        },
      });
  }

  addCategory() {
    if (!this.categoryToAdd?.trim()) {
      return;
    }

    if (this.categories.some((el) => el.name == this.categoryToAdd)) {
      this.toasterService.error(
        'Cannot add category',
        'A category with this name already exists.',
        4000
      );
      return;
    }

    this.catLoading = true;

    this.productService
      .createCategory(this.categoryToAdd)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          console.debug('created category: ');
          console.debug(res);
          this.categoryToAdd = null;
          this.toasterService.success('Category created', undefined, 4000);
          this.getCategories();
        },
        error: (error) => {
          this.catLoading = false;
          this.toasterService.error('Failed to add category.', undefined, 4000);
          console.error(error);
        },
      });
  }

  addProduct() {
    this.router.navigateByUrl('edit-product/');
  }
}
