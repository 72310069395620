import { Component } from '@angular/core';
import { ShopTaskService } from '../../services/shop-task/shop-task.service';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent {
  constructor(public shopTaskService: ShopTaskService, public router: Router) {}
  loading: boolean = false;
  async updateTask(id: string, status: string) {
    // update the task in the shopTaskService.shopTasks array with the new task with the updated status of 'DONE'
    this.shopTaskService.shopTasks = this.shopTaskService.shopTasks.map(
      (task) => (task.id === id ? { ...task, status: status } : task)
    );
    const task = this.shopTaskService.shopTasks.find((task) => task.id === id);
    this.loading = true;
    await this.shopTaskService.updateTask(task);
    this.loading = false;
  }

  routeToTask(location: string) {
    const route = location.split(':')[0];
    const activeTab = location.split(':')[1];
    const scrollTo = location.split(':')[2];
    const navigationExtras: NavigationExtras = {
      queryParams: {
        activeTab: activeTab,
        scrollTo: scrollTo,
      },
    };

    this.router.navigate([route], navigationExtras);
  }

  showSite() {
    this.router.navigate(['/storefront']);
  }
}
