import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckoutService } from '../../services/checkout/checkout.service';
import { LineItem } from '../../services/models/cart';
import { Product } from '../../services/models/product';
import { ProductService } from '../../services/product/product.service';
import { ShopService } from '../../services/shop/shop.service';
import { ToasterService } from '../../services/toaster/toaster.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit {
  constructor(public shopService: ShopService) {}

  ngOnInit(): void {}
}
