import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Slide } from './carousel.interface';
import { trigger, transition, useAnimation } from '@angular/animations';

import { AnimationType, scaleIn, scaleOut } from './carousel.animations';

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [
    trigger('slideAnimation', [
      /* scale */
      transition('void => scale', [
        useAnimation(scaleIn, { params: { time: '500ms' } }),
      ]),
      transition('scale => void', [
        useAnimation(scaleOut, { params: { time: '500ms' } }),
      ]),
    ]),
  ],
})
export class CarouselComponent implements OnInit, OnDestroy, OnChanges {
  @Input() slides: Slide[] = [];
  @Input() animationType = AnimationType.Scale;
  @Input() editMode: boolean = false;
  @Input() height: number | undefined = 400;
  @Input() controlColour: string | undefined = 'white';
  @Input() imageHeadlineColours: string[] = [];
  @Input() activeSlide: number = 0;

  currentSlide: number = 0;
  interval: any = -1;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['slides'] &&
      this.currentSlide >= changes['slides'].currentValue.length
    ) {
      this.currentSlide = this.currentSlide == 0 ? 0 : this.currentSlide - 1;
    }

    if (changes['activeSlide']) {
      this.currentSlide = changes['activeSlide'].currentValue;
    }
  }

  ngOnInit() {
    this.preloadImages();
    if (this.interval === -1 && !this.editMode) {
      this.interval = window.setInterval(() => {
        this.onNextClick();
      }, 5000);
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    console.debug(
      'previous clicked, new current slide is: ',
      this.currentSlide
    );
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
    console.debug('next clicked, new current slide is: ', this.currentSlide);
  }

  preloadImages() {
    for (const slide of this.slides) {
      new Image().src = slide.src;
    }
  }
}
