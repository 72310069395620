import { Product } from './product';

export class Cart {
  lineItems: LineItem[] = [];
  deliveryFlatRate: number = 0;
  MAX_PRODUCT_FOR_CART = 10;
  getCartTotals(): CartTotal {
    let totalDueNow = 0;
    let totalDueMonthly = 0;
    this.lineItems?.forEach((lineItem: LineItem) => {
      const subscriptionPrice = this.getSubcriptionPrice(lineItem) || 0;
      const buyNowPrice = this.getBuyNowPrice(lineItem) || 0;
      if (lineItem.purchaseType === 'BUY-NOW') {
        totalDueNow += buyNowPrice * lineItem.quantity;
      } else if (lineItem.purchaseType === 'SUBSCRIPTION') {
        totalDueMonthly += subscriptionPrice * lineItem.quantity;
      }
    });

    return {
      totalDueNow,
      totalDueMonthly,
    };
  }

  getSubcriptionPrice(lineItem: LineItem): number | undefined {
    // traxsit behavior
    if (lineItem.contractLengthInMonths) {
      return lineItem.product.subscriptionPrice
        ? lineItem.product.subscriptionPrice[lineItem.contractLengthInMonths]
        : 0;
    }

    //standard shop behavior
    return lineItem.product.price;
  }

  getBuyNowPrice(lineItem: LineItem): number | undefined {
    if (lineItem.contractLengthInMonths) {
      //traxsit behavior
      return lineItem.product.buyNowPrice
        ? lineItem.product.buyNowPrice[lineItem.contractLengthInMonths]
        : 0;
    } else {
      // standard shop behavior
      if (!lineItem.product.price) {
        return 0;
      } else {
        return +lineItem.product.price;
      }
    }
  }

  addLineItem(lineItem: LineItem) {
    const foundItem = this.lineItems.find((item) =>
      isLineItemEqual(lineItem, item)
    );
    if (foundItem) {
      const index = this.lineItems.indexOf(foundItem);
      this.lineItems[index].quantity += lineItem.quantity;
      return;
    }

    this.lineItems.push(lineItem);
  }

  maxForCartReached(lineItem: LineItem) {
    const foundItem = this.lineItems.find((item) =>
      isLineItemEqual(lineItem, item)
    );
    if (
      foundItem &&
      foundItem.quantity + lineItem.quantity > lineItem.product.maxOrderQuantity
    ) {
      return foundItem.quantity;
    }
    return null;
  }

  lessThanMinForLineItem(lineItem: LineItem) {
    const foundItem = this.lineItems.find((item) =>
      isLineItemEqual(lineItem, item)
    );
    if (
      foundItem &&
      foundItem.quantity + lineItem.quantity > lineItem.product.minOrderQuantity
    ) {
      return null;
    }
    if (lineItem.quantity < lineItem.product.minOrderQuantity) {
      return 1;
    }

    return null;
  }

  removeLineItem(lineItem: LineItem) {
    this.lineItems = this.lineItems.filter(
      (item) => !isLineItemEqual(lineItem, item)
    );
  }
}

export class LineItem {
  product!: Product;
  purchaseType!: 'BUY-NOW' | 'SUBSCRIPTION';
  contractLengthInMonths?: number;
  installationPrice?: number;
  quantity!: number;
}

export class CartTotal {
  totalDueNow!: number;
  totalDueMonthly!: number;
}
function isLineItemEqual(a: LineItem, b: LineItem): unknown {
  return (
    JSON.stringify(a.product) === JSON.stringify(b.product) &&
    a.contractLengthInMonths === b.contractLengthInMonths &&
    a.purchaseType === b.purchaseType
  );
}
