<div class="summary">
  <h3 class="text-dark">Summary</h3>
  <h4>
    <span class="text">Buy Now</span
    ><span class="price">{{
      checkoutService.calculateOrderTotals().buyNowTotal | currency : "R"
    }}</span>
  </h4>
  <h4
    class="mb-2"
    *ngIf="checkoutService.calculateOrderTotals().totalOrderDueMonthly"
  >
    <span class="text">Subscriptions</span
    ><span class="price"
      >{{
        checkoutService.calculateOrderTotals().totalOrderDueMonthly
          | currency : "R"
      }}
      <br />
      {{
        getContractLength()
          ? "x " + getContractLength() + " months"
          : "monthly (starting today)"
      }}
    </span>
  </h4>
  <h4
    *ngIf="
      shopService.shopDetails && shopService.shopDetails.deliveryFlatRate > 0
    "
  >
    <span
      *ngIf="checkoutService.calculateOrderTotals().deliveryFee > 0"
      class="text"
      >Delivery Fee</span
    ><span
      *ngIf="checkoutService.calculateOrderTotals().deliveryFee > 0"
      class="price"
      >{{
        checkoutService.calculateOrderTotals().deliveryFee | currency : "R"
      }}</span
    >
  </h4>
  <h4 *ngIf="installationPrice">
    <span class="text">Installation</span
    ><span class="price">{{
      checkoutService.calculateOrderTotals().installationPrice | currency : "R"
    }}</span>
  </h4>
  <h4
    class="fw-bold text-dark bg-light border rounded-0"
    style="padding-bottom: 12px; padding-top: 7px"
  >
    <span class="text">Total Due Now</span
    ><span class="price">{{
      checkoutService.calculateOrderTotals().totalOrderDueNow | currency : "R"
    }}</span>
  </h4>
  <h4
    *ngIf="checkoutService.calculateOrderTotals().totalOrderDueMonthly"
    class="fw-bold text-dark bg-light border rounded-0"
    style="padding-bottom: 12px; padding-top: 7px; margin-bottom: 15px"
  >
    <span class="text">Total Due Monthly</span
    ><span class="price">{{
      checkoutService.calculateOrderTotals().totalOrderDueMonthly
        | currency : "R"
    }}</span>
  </h4>
  <button
    *ngIf="checkoutService.checkoutStep === 0"
    (click)="proceedToCheckout()"
    [disabled]="
      checkoutService.calculateOrderTotals().totalOrderDueMonthly +
        checkoutService.calculateOrderTotals().buyNowTotal ==
      0
    "
    class="btn btn-dark btn-lg d-block w-100"
    role="button"
  >
    Checkout
  </button>
</div>
