<section class="clean-block clean-cart dark">
  <div class="container">
    <div class="block-heading">
      <h2 class="text-dark">Additional Information</h2>
    </div>
    <div class="content">
      <div class="row g-0">
        <div
          class="col-md-12 col-lg-8"
          style="padding-right: 30px; padding-left: 30px; padding-top: 30px"
        >
          <form class="text-dark border-dark">
            <div
              *ngIf="shopService.shopDetails?.customField?.enabled"
              class="mb-3"
            >
              <label class="form-label" for="country">
                <strong>{{
                  shopService.shopDetails?.customField?.label +
                    (shopService.shopDetails?.customField?.optional
                      ? " (optional)"
                      : "")
                }}</strong></label
              >
              <p *ngIf="shopService.shopDetails?.customField?.description">
                {{ shopService.shopDetails?.customField?.description }}
              </p>
              <input
                class="form-control"
                type="customField"
                id="customField"
                name="customField"
                [(ngModel)]="checkoutService.order.customFieldValue"
                [placeholder]="
                  'e.g. ' + shopService.shopDetails?.customField?.value
                "
              />
            </div>
            <p class="error">{{ errorMessage }}</p>
            <div class="d-lg-flex justify-content-lg-end mb-3">
              <button
                class="btn btn-dark"
                role="button"
                style="width: 177.1719px"
                (click)="proceedToNextStep()"
              >
                Next
              </button>
            </div>
          </form>
        </div>
        <div class="col-md-12 col-lg-4">
          <app-cart-summary
            [installationPrice]="checkoutService.calculateInstallationPrice()"
          ></app-cart-summary>
        </div>
      </div>
    </div>
  </div>
</section>
