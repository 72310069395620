import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ShopService } from '../shop/shop.service';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(
    private gaService: GoogleAnalyticsService,
    private shopService: ShopService
  ) {}

  trackLogin(userName: string) {
    this.gaService.event(
      'login',
      'merchant',
      userName,
      undefined,
      true,
      this.getTrackingOptions()
    );
  }

  trackOrderCreate(orderRef: string) {
    this.gaService.event(
      'order_created',
      'checkout',
      orderRef,
      undefined,
      true,
      this.getTrackingOptions()
    );
  }

  trackPaymentStarted(orderRef: string) {
    this.gaService.event(
      'payment_started',
      'checkout',
      orderRef,
      undefined,
      true,
      this.getTrackingOptions()
    );
  }

  trackPaymentSuccessful(orderRef: string, amount: number) {
    this.gaService.event(
      'purchase',
      'checkout',
      orderRef,
      amount,
      true,
      this.getTrackingOptions()
    );
  }

  trackPaymentFailed(userEmail: string) {
    this.gaService.event(
      'payment_failed',
      'checkout',
      userEmail,
      undefined,
      true,
      this.getTrackingOptions()
    );
  }

  trackProductAddedToCart(stockCode: string) {
    this.gaService.event(
      'add_to_cart',
      'checkout',
      stockCode,
      undefined,
      true,
      this.getTrackingOptions(stockCode)
    );
  }

  trackCheckoutStarted() {
    this.gaService.event(
      'begin_checkout',
      'checkout',
      undefined,
      undefined,
      true,
      this.getTrackingOptions()
    );
  }

  trackPageView(path: string) {
    if (this.shopService.shopDetails?.id) {
      this.gaService.pageView(
        path,
        undefined,
        undefined,
        this.getTrackingOptions()
      );
    }
  }

  trackFeedbackGiven() {
    this.gaService.event(
      'merchant_feedback',
      'merchant',
      undefined,
      undefined,
      true,
      this.getTrackingOptions()
    );
  }

  getTrackingOptions(productCode?: string) {
    if (!this.shopService.shopDetails?.id) {
      throw new Error('No shop details found');
    }

    if (productCode) {
      return { shopId: this.shopService.shopDetails.id, productCode };
    }
    return { shopId: this.shopService.shopDetails.id };
  }
}
