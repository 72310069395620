<div class="centered mt-5">
  <h1>
    {{
      shopTaskService.allTasksComplete()
        ? "All Tasks Complete!"
        : "Welcome to Your Shop!"
    }}
  </h1>
  <p *ngIf="!shopTaskService.allTasksComplete()">
    Follow these steps to set up your shop:
  </p>
</div>
<div class="card-container">
  <div class="card">
    <ul class="list-group list-group-flush">
      <li
        *ngFor="let task of shopTaskService.shopTasks"
        class="list-group-item task"
      >
        <div class="task-name">
          <div class="status">
            <svg
              (click)="updateTask(task.id, 'DONE')"
              *ngIf="task.status !== 'DONE'"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="unchecked"
            >
              <path
                d="M12 23.9c6.6 0 11.9-5.3 11.9-11.9S18.6.1 12 .1 0 5.4 0 12s5.3 11.9 11.9 11.9zm0-22.7c5.5 0 9.9 4.4 9.9 9.9S17.5 21 12 21s-9.9-4.4-9.9-9.9S6.5 1.2 12 1.2z"
              />
            </svg>
          </div>
          <div *ngIf="task.status === 'DONE'" class="status">
            <svg
              (click)="updateTask(task.id, 'NOT_STARTED')"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="checked"
            >
              <path
                d="M11.9 24C9 24 6.2 22.9 4 21c-2.4-2.1-3.8-5.1-4-8.3C-.3 6.1 4.8.4 11.4 0c1.9-.1 3.7.2 5.4 1 .5.2.7.8.5 1.3-.2.5-.8.7-1.3.5-1.5-.6-3-.8-4.5-.8C6 2.3 1.7 7.1 2 12.6c.1 2.6 1.3 5.1 3.3 6.9 2 1.8 4.6 2.7 7.2 2.5 5.6-.3 9.7-4.9 9.5-10.5 0-.6.4-1 1-1 .5 0 1 .4 1 .9.2 3.2-.9 6.3-3.1 8.6-2.1 2.4-5.1 3.7-8.3 3.9-.2.1-.5.1-.7.1zm-.6-8.3c-.2 0-.5-.1-.7-.3l-2.8-2.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.1 2 8.9-8.9c.4-.4 1-.4 1.4 0s.4 1 0 1.4L12 15.5c-.2.1-.4.2-.7.2z"
              ></path>
            </svg>
          </div>
          <a
            *ngIf="task.status !== 'DONE'"
            (click)="routeToTask(task.location)"
            class="task-link"
          >
            {{ task.taskName }}
            <i class="fas fa-arrow-right"></i>
          </a>

          <span *ngIf="task.status === 'DONE'">{{ task.taskName }}</span>
        </div>
        <div *ngIf="task.status === 'DONE'" class="d-flex flex-row undo-group">
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.00574 0.619233C5.16196 0.775506 5.24973 0.987429 5.24973 1.2084C5.24973 1.42937 5.16196 1.64129 5.00574 1.79757L3.51157 3.29173H10.0416C11.3601 3.29173 12.649 3.68273 13.7454 4.41527C14.8417 5.14781 15.6962 6.189 16.2008 7.40718C16.7054 8.62535 16.8374 9.9658 16.5801 11.259C16.3229 12.5522 15.688 13.7401 14.7556 14.6724C13.8233 15.6048 12.6354 16.2397 11.3422 16.497C10.049 16.7542 8.70852 16.6222 7.49035 16.1176C6.27217 15.613 5.23098 14.7585 4.49844 13.6622C3.7659 12.5659 3.3749 11.2769 3.3749 9.9584C3.3749 9.73739 3.4627 9.52543 3.61898 9.36915C3.77526 9.21286 3.98722 9.12507 4.20824 9.12507C4.42925 9.12507 4.64121 9.21286 4.79749 9.36915C4.95377 9.52543 5.04157 9.73739 5.04157 9.9584C5.04157 10.9473 5.33482 11.914 5.88422 12.7363C6.43363 13.5585 7.21452 14.1994 8.12815 14.5778C9.04178 14.9562 10.0471 15.0553 11.017 14.8623C11.9869 14.6694 12.8778 14.1932 13.5771 13.4939C14.2764 12.7947 14.7526 11.9038 14.9455 10.9339C15.1384 9.96395 15.0394 8.95861 14.661 8.04498C14.2825 7.13135 13.6417 6.35046 12.8194 5.80105C11.9972 5.25165 11.0305 4.9584 10.0416 4.9584H3.51157L5.00574 6.45257C5.15754 6.60974 5.24153 6.82024 5.23963 7.03873C5.23773 7.25723 5.15009 7.46624 4.99559 7.62075C4.84108 7.77526 4.63207 7.8629 4.41357 7.8648C4.19507 7.86669 3.98457 7.7827 3.8274 7.6309L0.910737 4.71423C0.754511 4.55796 0.666748 4.34604 0.666748 4.12507C0.666748 3.9041 0.754511 3.69217 0.910737 3.5359L3.8274 0.619233C3.98368 0.463007 4.1956 0.375244 4.41657 0.375244C4.63754 0.375244 4.84946 0.463007 5.00574 0.619233Z"
              fill="#aaa"
            />
          </svg>
          <div (click)="updateTask(task.id, 'NOT_STARTED')" class="undo">
            <span>Unmark</span>
          </div>
        </div>
        <div *ngIf="task.status !== 'DONE'">
          <button class="btn btn-primary" (click)="updateTask(task.id, 'DONE')">
            <div *ngIf="!loading">Mark as done</div>
            <app-spinner *ngIf="loading"></app-spinner>
          </button>
        </div>
      </li>
    </ul>
  </div>
  <div class="d-flex flex-row justify-content-center">
    <button
      *ngIf="shopTaskService.allTasksComplete()"
      (click)="showSite()"
      class="btn btn-primary view-site"
    >
      View Site
    </button>
  </div>
</div>
<div *ngIf="!shopTaskService.allTasksComplete()" class="centered mt-5">
  <p>You can come back at any time by clicking the red button in the top bar</p>
</div>

<div class="centered mt-5">
  <h1>Need Help?</h1>
  <p>Check out our editor tutorial video before you get started.</p>
  <iframe
    class="mb-5"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/hHnzRYkZIOU"
    frameborder="0"
    allowfullscreen
  ></iframe>
</div>
