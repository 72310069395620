<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
    />
    <title>{{ shopService.shopDetails?.name }}</title>
    <link href="/src/assets/bootstrap/css/bootstrap.min.css" />
    <link
      href="https://fonts.googleapis.com/css?family=Montserrat:400,400i,700,700i,600,600i&amp;display=swap"
    />
  </head>
  <div class="container">
    <div class="row text-center">
      <app-spinner *ngIf="!getShopStatus() && loading"></app-spinner>
    </div>
  </div>
  <div class="row text-center">
    <p *ngIf="errorMessage" class="error p-5">{{ errorMessage }}</p>
  </div>
  <body *ngIf="!loading && !errorMessage">
    <nav
      class="navbar navbar-light navbar-expand-lg fixed-top clean-navbar"
      style="padding-top: 10px; padding-bottom: 10px"
      [ngStyle]="{
        'background-color': contentService.content?.colourPalette?.navBackground
      }"
    >
      <div class="container">
        <a
          ><img
            id="logo"
            class="img-fluid d-block mx-auto image"
            [src]="contentService.content?.logo"
            style="max-height: 68px; max-width: 250px"
        /></a>
        <h2
          class="shop-name"
          [ngStyle]="{
            color: contentService.content?.colourPalette?.navText
          }"
          *ngIf="
            (shopService.shopDetails?.name &&
              contentService.content?.showShopName) ||
            isMobileScreen()
          "
        >
          {{ shopService.shopDetails?.name }}
        </h2>
        <button
          data-bs-toggle="collapse"
          class="navbar-toggler"
          data-bs-target="#navcol-1"
          (click)="mobileNavExpanded = !mobileNavExpanded"
        >
          <span class="visually-hidden">Toggle navigation</span
          ><span class="navbar-toggler-icon"></span>
        </button>
        <div
          [ngClass]="{ show: mobileNavExpanded }"
          class="collapse navbar-collapse"
          id="navcol-1"
        >
          <ul class="navbar-nav ms-auto">
            <li>
              <app-welcome-floater
                *ngIf="
                  this.authService.isLoggedIn() &&
                  !this.shopTaskService?.allTasksComplete()
                "
              ></app-welcome-floater>
            </li>
            <li
              *ngIf="
                (authService.isLoggedOut() && getShopStatus() == 'OPEN') ||
                shopService.previewAllowed
              "
              class="nav-item"
              [routerLink]="['storefront']"
              (click)="mobileNavExpanded = !mobileNavExpanded"
            >
              <a
                [ngStyle]="{
                  color: contentService.content?.colourPalette?.navText
                }"
                href="javascript:void(0);"
                class="nav-link active"
                >Home
              </a>
            </li>
            <li
              *ngIf="
                authService.isLoggedOut() &&
                (getShopStatus() == 'OPEN' || shopService.previewAllowed) &&
                shopHasProducts
              "
              class="nav-item"
              [routerLink]="'products'"
            >
              <a
                [ngStyle]="{
                  color: contentService.content?.colourPalette?.navText
                }"
                href="javascript:void(0);"
                class="nav-link"
                >Products</a
              >
            </li>
            <li
              *ngIf="
                authService.isLoggedOut() &&
                (getShopStatus() == 'OPEN' || shopService.previewAllowed)
              "
              class="nav-item"
              (click)="mobileNavExpanded = !mobileNavExpanded"
              [routerLink]="['contact']"
            >
              <a
                [ngStyle]="{
                  color: contentService.content?.colourPalette?.navText
                }"
                href="javascript:void(0);"
                class="nav-link"
                >Contact</a
              >
            </li>

            <li
              class="nav-item"
              *ngIf="authService.isLoggedOut()"
              (click)="mobileNavExpanded = !mobileNavExpanded"
              [routerLink]="['login']"
            >
              <a
                [ngStyle]="{
                  color: contentService.content?.colourPalette?.navText
                }"
                href="javascript:void(0);"
                class="nav-link"
                >Login</a
              >
            </li>
            <li
              class="nav-item"
              *ngIf="authService.isLoggedIn()"
              (click)="routeToEditor()"
            >
              <a
                [ngStyle]="{
                  color: contentService.content?.colourPalette?.navText
                }"
                href="javascript:void(0);"
                class="nav-link"
                >Editor</a
              >
            </li>
            <li
              class="nav-item"
              *ngIf="authService.isLoggedIn()"
              (click)="mobileNavExpanded = !mobileNavExpanded"
              [routerLink]="['order-overview']"
            >
              <a
                [ngStyle]="{
                  color: contentService.content?.colourPalette?.navText
                }"
                href="javascript:void(0);"
                class="nav-link"
                >Orders</a
              >
            </li>
            <li
              class="nav-item"
              *ngIf="authService.isLoggedIn()"
              (click)="mobileNavExpanded = !mobileNavExpanded"
              [routerLink]="['product-list']"
            >
              <a
                [ngStyle]="{
                  color: contentService.content?.colourPalette?.navText
                }"
                href="javascript:void(0);"
                class="nav-link"
                >Products</a
              >
            </li>
            <li
              class="nav-item"
              *ngIf="authService.isLoggedIn()"
              (click)="mobileNavExpanded = !mobileNavExpanded"
              [routerLink]="['shop-settings']"
            >
              <a
                [ngStyle]="{
                  color: contentService.content?.colourPalette?.navText
                }"
                href="javascript:void(0);"
                class="nav-link"
                >Settings</a
              >
            </li>
            <li
              class="nav-item"
              *ngIf="authService.isLoggedIn()"
              (click)="mobileNavExpanded = !mobileNavExpanded"
              [routerLink]="['help']"
            >
              <a
                [ngStyle]="{
                  color: contentService.content?.colourPalette?.navText
                }"
                href="javascript:void(0);"
                class="nav-link"
                >Help</a
              >
            </li>
            <li
              class="nav-item"
              *ngIf="authService.isLoggedIn()"
              (click)="logOut()"
            >
              <a
                [ngStyle]="{
                  color: contentService.content?.colourPalette?.navText
                }"
                href="javascript:void(0);"
                class="nav-link"
                >Logout</a
              >
            </li>
          </ul>
          <a
            *ngIf="
              authService.isLoggedOut() &&
              getShopCheckoutEnabled() &&
              (getShopStatus() == 'OPEN' || 'LOCKED')
            "
            class="btn btn-dark"
            role="button"
            (click)="clickCart()"
            [routerLink]="['cart']"
            ><i class="icon-basket"
              ><span
                *ngIf="checkoutService.cart.lineItems.length > 0"
                class="cart-count"
                >{{ checkoutService.cart.lineItems.length }}</span
              ></i
            ></a
          >
        </div>
      </div>
    </nav>
    <main class="page landing-page">
      <app-feedback-banner
        *ngIf="
          authService.isLoggedIn() && notFeedbackPageOrEditorOrStorefront()
        "
        class="banner"
      ></app-feedback-banner>
      <div
        *ngIf="getShopStatus() && !errorMessage && !loading"
        class="row text-center"
      ></div>
      <router-outlet></router-outlet>
    </main>
    <app-footer *ngIf="!contentService.editingMode"></app-footer>
  </body>
</html>
