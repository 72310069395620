<section class="clean-block clean-cart dark">
  <div class="container">
    <div class="block-heading">
      <h2 class="text-dark">Delivery Information</h2>
    </div>
    <div class="content">
      <div class="row g-0">
        <div
          class="col-md-12 col-lg-8"
          style="padding-right: 30px; padding-left: 30px; padding-top: 30px"
        >
          <form class="text-dark border-dark">
            <div class="mb-3">
              <label class="form-label" for="line1">Line 1</label
              ><input
                [(ngModel)]="deliveryInformation.addressLine1"
                class="form-control"
                placeholder="e.g. 123 Hollywood Boulevard"
                type="text"
                id="line1"
                name="line1"
              />
            </div>
            <div class="mb-3">
              <label class="form-label" for="line2">Line 2</label
              ><input
                [(ngModel)]="deliveryInformation.addressLine2"
                placeholder="e.g. Apartment 4, Suite 37"
                class="form-control"
                type="text"
                id="line2"
                name="line2"
              />
            </div>
            <div class="mb-3">
              <label class="form-label" for="city">City</label
              ><input
                [(ngModel)]="deliveryInformation.city"
                placeholder="e.g. Johannesburg"
                class="form-control"
                type="city"
                id="city"
                name="city"
              />
            </div>
            <div class="mb-3">
              <label class="form-label" for="postcode">Postcode</label
              ><input
                [(ngModel)]="deliveryInformation.postcode"
                placeholder="e.g. 4589"
                class="form-control"
                type="text"
                id="postcode"
                name="postcode"
              />
            </div>
            <div class="mb-3">
              <label class="form-label" for="suburb">Suburb</label
              ><input
                [(ngModel)]="deliveryInformation.suburb"
                class="form-control"
                placeholder="Kyalami"
                type="text"
                id="suburb"
                name="suburb"
              />
            </div>
            <div class="mb-3">
              <label class="form-label" for="country">Country</label
              ><input
                class="form-control"
                disabled
                type="email"
                id="email-2"
                name="email"
                value="South Africa"
              />
            </div>
            <p class="error">{{ errorMessage }}</p>
            <div class="d-lg-flex justify-content-lg-end mb-3">
              <button
                class="btn btn-dark"
                role="button"
                style="width: 177.1719px"
                (click)="proceedToNextStep()"
              >
                Next
              </button>
            </div>
          </form>
        </div>
        <div class="col-md-12 col-lg-4">
          <app-cart-summary
            [installationPrice]="checkoutService.calculateInstallationPrice()"
          ></app-cart-summary>
        </div>
      </div>
    </div>
  </div>
</section>
