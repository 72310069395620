<div class="container">
  <div class="row mt-3">
    <div class="col-xs-0 col-md-3"></div>
    <div class="col-xs-12 col-md-9">
      <input
        class="form-control mr-sm-2 w-100 search-box"
        type="search"
        (change)="changeTerm($event)"
        placeholder="Search by product name"
        aria-label="Search"
      />
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-lg-3 col-md-4">
      <div *ngIf="categories && categories.length > 0" class="category-section">
        <h5>Categories</h5>
        <div
          *ngFor="let category of categories; let i = index"
          class="form-check"
        >
          <input
            class="form-check-input"
            type="checkbox"
            [value]="category.id"
            (change)="changeCategory($event)"
            [id]="'category' + i"
          />
          <label class="form-check-label" [for]="'category' + i">
            {{ category.name }}
          </label>
        </div>
      </div>
      <div
        *ngIf="shopService.shopDetails?.checkoutEnabled"
        class="in-stock-section"
      >
        <h5>Stock</h5>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [value]="searchProductFilter.onlyInStock"
            (change)="changeOnlyInStock($event)"
            id="hasStock"
          />
          <label class="form-check-label" for="hasStock">
            Only Show In Stock
          </label>
        </div>
      </div>
      <div class="product-search-controls mb-4">
        <div class="status-filter">
          <div
            class="d-flex align-items-center input-group-text-input border-50 search-box"
          >
            <svg
              width="18"
              height="12"
              viewBox="0 0 18 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 12C7.71667 12 7.479 11.904 7.287 11.712C7.095 11.52 6.99934 11.2827 7 11C7 10.7167 7.096 10.479 7.288 10.287C7.48 10.095 7.71734 9.99934 8 10H10C10.2833 10 10.521 10.096 10.713 10.288C10.905 10.48 11.0007 10.7173 11 11C11 11.2833 10.904 11.521 10.712 11.713C10.52 11.905 10.2827 12.0007 10 12H8ZM1 2C0.71667 2 0.479003 1.904 0.287003 1.712C0.0950034 1.52 -0.000663206 1.28267 3.46021e-06 1C3.46021e-06 0.71667 0.0960036 0.479004 0.288004 0.287004C0.480004 0.0950036 0.717337 -0.000663206 1 3.4602e-06H17C17.2833 3.4602e-06 17.521 0.0960036 17.713 0.288004C17.905 0.480004 18.0007 0.717337 18 1C18 1.28334 17.904 1.521 17.712 1.713C17.52 1.905 17.2827 2.00067 17 2H1ZM4 7C3.71667 7 3.479 6.904 3.287 6.712C3.095 6.52 2.99934 6.28267 3 6C3 5.71667 3.096 5.479 3.288 5.287C3.48 5.095 3.71734 4.99934 4 5H14C14.2833 5 14.521 5.096 14.713 5.288C14.905 5.48 15.0007 5.71734 15 6C15 6.28334 14.904 6.521 14.712 6.713C14.52 6.905 14.2827 7.00067 14 7H4Z"
                fill="#EF4136"
              />
            </svg>
            <select (change)="changeSort($event)">
              <option [value]="null">No Sort</option>
              <option
                *ngIf="shopService.shopDetails?.checkoutEnabled"
                value="price-asc"
              >
                Price Asc
              </option>
              <option
                *ngIf="shopService.shopDetails?.checkoutEnabled"
                value="price-desc"
              >
                Price Desc
              </option>
              <option value="name-asc">Name Asc</option>
              <option value="name-desc">Name Desc</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-9 col-md-8">
      <div *ngIf="loading" class="row">
        <div class="col">
          <app-spinner></app-spinner>
        </div>
      </div>
      <div *ngIf="products.length == 0 && !loading" class="row">
        <div class="col">
          <h2 class="p-3">No products found</h2>
        </div>
      </div>
      <div *ngIf="!loading" class="row">
        <div *ngFor="let product of products" class="col-lg-4 col-md-6">
          <div
            class="card product-card"
            [routerLink]="['/product-detail', product.id]"
          >
            <img [src]="getImage(product)" class="card-img-top" alt="Product" />
            <div class="card-body">
              <h5 class="card-title">{{ product.title }}</h5>
              <p
                *ngIf="shopService.shopDetails?.checkoutEnabled"
                class="card-text"
              >
                {{
                  product.stockLevel === "NO_STOCK"
                    ? "Sold Out"
                    : "R" + product.price
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container mt-3">
  <div class="row">
    <div class="col d-xl-flex justify-content-xl-center">
      <app-paging
        class="m-auto"
        *ngIf="!loading"
        [currentPage]="searchProductFilter.page"
        [totalRecords]="count"
        [pageSize]="searchProductFilter.pageSize"
        (pageChange)="setPage($event)"
      ></app-paging>
    </div>
  </div>
</div>
