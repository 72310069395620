import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-welcome-floater',
  templateUrl: './welcome-floater.component.html',
  styleUrls: ['./welcome-floater.component.scss'],
})
export class WelcomeFloaterComponent {
  constructor(private route: ActivatedRoute) {}

  notWelcomePage(): boolean {
    return window.location.href.indexOf('welcome') == -1;
  }
}
