<section class="clean-block clean-form dark pt-5">
  <div class="container">
    <div class="row">
      <app-spinner *ngIf="loading" class="text-center"></app-spinner>
    </div>
    <h2 *ngIf="errorMessage && !loading">{{ errorMessage }}</h2>
    <div *ngIf="!loading && order" class="card">
      <div class="card-body detail-card">
        <div class="row">
          <div class="col-md-8">
            <h3 *ngIf="order.testMode">
              <span class="badge bg-secondary">Test Order</span>
            </h3>
            <h1>{{ order.orderRef }}</h1>
            <small *ngIf="!isOrderPlacedToday()" class="muted-warning-text">{{
              order.createdAt | date : "YYY-MM-dd HH:mm"
            }}</small>
            <small *ngIf="isOrderPlacedToday()" class="muted-warning-text"
              >Today {{ order.createdAt | date : "HH:mm" }}</small
            >
          </div>
          <div
            class="col-md-4 d-flex flex-column status-column"
            style="width: 33%"
          >
            <div class="d-flex flex-row">
              <select
                class="status-selector"
                [disabled]="statusLoading"
                [value]="order.orderStatus"
                (change)="changeOrderStatus($event)"
              >
                <option value="WAITING_FOR_PAYMENT" selected="">
                  Waiting for Payment
                </option>
                <option value="PAID" selected="">Paid</option>
                <option value="SHIPPING" selected="">Shipping</option>
                <option value="DELIVERED" selected="">Delivered</option>
                <option value="CANCELLED">Cancelled</option>
              </select>
              <app-spinner
                *ngIf="statusLoading"
                class="status-load"
              ></app-spinner>
            </div>
            <small class="muted-warning-text"
              >Changing status will send an email to the customer</small
            >

            <div
              *ngIf="orderService.orderPaymentNotCompleted(order)"
              class="payment-warning"
            >
              <div class="d-flex flex-direction-row">
                <div class="icon error">
                  <i class="fas fa-exclamation-triangle"></i>
                </div>
                <p>Payment did not complete - request payment from user.</p>
              </div>
              <button
                [ngStyle]="{
                  'background-color':
                    contentService.content?.colourPalette?.buttonBackground,
                  color: contentService.content?.colourPalette?.buttonText
                }"
                class="btn btn-primary"
                [disabled]="sentPayRequest || loadingPayRequest"
                (click)="requestPayment()"
              >
                {{
                  sentPayRequest ? "Request Sent" : "Request Payment Via Email"
                }}
              </button>
            </div>
            <p
              *ngIf="orderService.orderPaymentNotCompleted(order)"
              class="small mt-3"
            >
              *You will receive an email from Paystack when the customer pays.
              You will then have to update the order to PAID manually with the
              selector above.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-md-6 order-detail-group">
                <h3>Shipping</h3>
                <p>
                  <strong>Line 1</strong>:
                  {{ order.deliveryInformation.addressLine1 }}
                </p>
                <p>
                  <strong>Line 2:</strong>
                  {{ order.deliveryInformation.addressLine2 }}
                </p>
                <p>
                  <strong>Suburb</strong>:
                  {{ order.deliveryInformation.suburb }}
                </p>
                <p>
                  <strong>City</strong>: {{ order.deliveryInformation.city }}
                </p>
                <p>
                  <strong>PostCode:</strong>
                  {{ order.deliveryInformation.postcode }}
                </p>
              </div>
              <div class="col-md-6 order-detail-group">
                <div class="d-xl-flex flex-column content-input-group">
                  <span class="form-label">Shipment Tracking Link</span>
                  <sub
                    >Tracking link is sent by email to the customer on order
                    status change to Shipping</sub
                  >
                  <input
                    name="MaxOrderQty"
                    [(ngModel)]="order.deliveryInformation.trackingLink"
                    class="input-group-text-input mt-3"
                    (blur)="saveTrackingLink()"
                    type="text"
                    placeholder="e.g. https://dhl.tracking.com/waybill-tracking/WK3242J23N1J2"
                  />
                </div>
              </div>
              <div class="col-auto col-sm-6 col-md-6 order-detail-group">
                <h3>Customer</h3>
                <p>
                  <strong>Name: </strong>{{ order.clientInformation?.name }}
                  {{ order.clientInformation?.surname }}
                </p>
                <p>
                  <strong>Email: </strong>{{ order.clientInformation?.email }}
                </p>
                <p *ngIf="order.clientInformation?.phone">
                  <strong>Phone: </strong>{{ order.clientInformation?.phone }}
                </p>
              </div>

              <div
                *ngIf="
                  shopService.shopDetails?.customField?.enabled &&
                  order.customFieldValue
                "
                class="col-auto col-sm-12 col-md-6 order-detail-group"
              >
                <h3>Custom Field</h3>
                <p>
                  <strong
                    >{{ shopService.shopDetails?.customField?.label }}:
                  </strong>
                  {{ order.customFieldValue }}
                </p>
              </div>
              <div
                *ngIf="order.orderStatus !== 'WAITING_FOR_PAYMENT'"
                class="col-sm-12 col-md-6 order-detail-group"
              >
                <h3>Payment Info</h3>
                <p></p>
                <a [href]="getPaymentInfoUrl(order.orderRef)" target="_blank"
                  >View On Paystack</a
                >
                <br />
                <br />
                <a
                  href="https://dashboard.paystack.com/#/plans?status=active"
                  target="_blank"
                  >View All Active Subscriptions</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Purchase Type</th>
                  <th *ngIf="containsContractLengths(order)">
                    Contract Length
                  </th>
                  <th>Quantity</th>
                  <th class="amount-row">Line Item Total</th>
                </tr>
              </thead>
              <tbody>
                <tr class="loader-track" *ngIf="loading">
                  <app-spinner></app-spinner>
                </tr>
                <ng-container *ngIf="!loading">
                  <tr class="order-track" *ngFor="let item of order.lineItems">
                    <td class="name-row" class="pr-2">
                      {{ item.productName }}
                    </td>
                    <td class="type-row" class="pr-2">
                      {{ item.purchaseType }}
                    </td>
                    <td
                      *ngIf="containsContractLengths(order)"
                      class="contract-row"
                      class="pr-2"
                    >
                      {{ item.contractLengthInMonths }}
                    </td>
                    <td class="quantity-row" class="pr-2">
                      {{ item.quantity }} ( x
                      {{ item.productPrice | currency : "R" }} unit price)
                    </td>
                    <td class="amount-row">
                      {{
                        (item.productPrice || 0) * (item.quantity || 0)
                          | currency : "R"
                      }}
                    </td>
                  </tr>
                  <tr *ngIf="(order.deliveryRate || 0) > 0">
                    <td class="productName"></td>
                    <td class="purchaseType"></td>
                    <td
                      class="contractLength"
                      *ngIf="containsContractLengths(order)"
                    ></td>
                    <td class="quantity"></td>
                    <td class="amount-row">
                      <strong>Delivery Fee:&nbsp;&nbsp;&nbsp;</strong>
                      {{ order.deliveryRate | currency : "R" }}
                    </td>
                  </tr>
                  <tr *ngIf="(getInstallationCosts(order) || 0) > 0">
                    <td class="productName"></td>
                    <td class="purchaseType"></td>
                    <td
                      class="contractLength"
                      *ngIf="containsContractLengths(order)"
                    ></td>
                    <td class="quantity"></td>
                    <td class="amount-row">
                      <strong>Installation Fee:&nbsp;&nbsp;&nbsp;</strong>
                      {{ getInstallationCosts(order) | currency : "R" }}
                    </td>
                  </tr>
                  <tr>
                    <td class="productName"></td>
                    <td class="purchaseType"></td>
                    <td
                      class="contractLength"
                      *ngIf="containsContractLengths(order)"
                    ></td>
                    <td class="quantity"></td>
                    <td class="amount-row">
                      <strong>Total Due Now:&nbsp;&nbsp;&nbsp;</strong>
                      {{ +(order.totalDueNow || 0) | currency : "R" }}
                    </td>
                  </tr>
                  <tr>
                    <td class="productName"></td>
                    <td class="purchaseType"></td>
                    <td
                      class="contractLength"
                      *ngIf="containsContractLengths(order)"
                    ></td>
                    <td class="quantity"></td>
                    <td class="amount-row">
                      <strong>Total Due Monthly:&nbsp;&nbsp;&nbsp;</strong>
                      {{ order.totalDueMonthly | currency : "R" }}
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
