<div *ngIf="loading" class="w-100 d-flex justify-content-center">
  <app-spinner></app-spinner>
</div>
<section *ngIf="!loading" class="clean-block clean-product dark pt-5">
  <div class="container" id="product-detail">
    <h2 *ngIf="!product">No product found for this ID</h2>
    <div *ngIf="product" class="block-content">
      <div class="product-info">
        <div class="row">
          <div class="col-md-6">
            <div class="text-white gallery">
              <div class="text-white vanilla-zoom" id="product-preview">
                <div class="zoomed-image">
                  <img
                    *ngIf="product.images && product.images.length > 0"
                    class="img-fluid d-block mx-auto"
                    [src]="product.images[0].link"
                    style="max-height: 320px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="info">
              <h3 id="product-heading">{{ product.title }}</h3>
              <div
                class="product-checkout-functionality"
                *ngIf="getShopCheckoutEnabled()"
              >
                <div class="price">
                  <h3
                    *ngIf="
                      contractLengthInMonths && purchaseType == 'SUBSCRIPTION'
                    "
                    style="margin-bottom: 32px"
                  >
                    {{
                      product.subscriptionPrice[contractLengthInMonths]
                        | currency : "R"
                    }}
                    per month
                  </h3>
                  <h3
                    *ngIf="contractLengthInMonths && purchaseType == 'BUY-NOW'"
                    style="margin-bottom: 32px"
                  >
                    {{
                      product.buyNowPrice[contractLengthInMonths]
                        | currency : "R"
                    }}
                  </h3>
                  <div class="product-option-field mb-3">
                    <label class="form-label d-block product-option-label"
                      >Purchase Type (Monthly or upfront)</label
                    ><select
                      class="form-select-sm"
                      name="type"
                      [(ngModel)]="purchaseType"
                    >
                      <option value="SUBSCRIPTION" selected="">
                        Monthly subscription
                      </option>
                      <option value="BUY-NOW">Upfront payment</option>
                    </select>
                  </div>
                  <div class="product-option-field mb-3">
                    <label class="form-label d-block product-option-label"
                      >Contract Length</label
                    ><select
                      class="form-select-sm"
                      [(ngModel)]="contractLengthInMonths"
                    >
                      <option [value]="null">-Select-</option>
                      <option
                        *ngIf="product?.subscriptionPrice[12]"
                        [value]="12"
                      >
                        12 months
                      </option>
                      <option
                        *ngIf="product?.subscriptionPrice[24]"
                        [value]="24"
                      >
                        24 months
                      </option>
                      <option
                        *ngIf="product?.subscriptionPrice[36]"
                        [value]="36"
                      >
                        36 months
                      </option>
                    </select>
                  </div>
                  <div class="product-option-field mb-3">
                    <label class="form-label product-option-label"
                      >Quantity</label
                    >
                    <div class="input-group" style="height: 38px">
                      <input
                        class="form-control form-control-sm"
                        type="number"
                        style="
                          height: 40px;
                          text-align: center;
                          max-width: 75px;
                        "
                        min="1"
                        max="10"
                        name="quantity"
                        [(ngModel)]="quantity"
                      />
                    </div>
                    <sub
                      >*If you require more than 10 units please contact us on
                      our contact form.</sub
                    >
                  </div>
                </div>
                <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
                <button
                  class="btn btn-dark"
                  type="button"
                  style="width: 100%; margin-bottom: 12px"
                  (click)="clickAddProductToCart()"
                >
                  <i class="icon-basket"></i>Add to Cart
                </button>
              </div>
              <div class="summary">
                <p>{{ product.description }}</p>
                <br />
                <div
                  *ngIf="product.descriptionImages"
                  class="product-description mb-5"
                >
                  <img
                    *ngIf="product.descriptionImages[0]"
                    width="100%"
                    [src]="product.descriptionImages[0].link"
                  />
                  <a
                    *ngIf="product.descriptionPdf"
                    [href]="product.descriptionPdf"
                    download
                    >More Information</a
                  >
                </div>

                <strong>Check out screenshots from our tracking app:</strong>
                <div class="container">
                  <div class="row">
                    <div
                      class="d-flex flex-column justify-content-center align-items-center"
                    >
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-md-6 col-sm-12">
                            <img
                              width="100%"
                              src="https://storage.googleapis.com/cypress-online-static/app-preview.png"
                            />
                          </div>
                          <div class="col-md-6 col-sm-12">
                            <img
                              width="100%"
                              src="https://storage.googleapis.com/cypress-online-static/app-preview2.png"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="product.additionalInformation" class="product-info">
        <div>
          <ul class="nav nav-tabs" role="tablist" id="myTab">
            <li class="nav-item" role="presentation">
              <a
                class="nav-link active link-dark"
                role="tab"
                data-bs-toggle="tab"
                id="description-tab"
                href="#description"
                >Product Information</a
              >
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active description"
              role="tabpanel"
              id="description"
            >
              <p>{{ product.additionalInformation }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
