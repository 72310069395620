import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ValidationService } from '../../services/validation/validation.service';
import { FeedbackFormSubmission } from './feedback-submission';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
})
export class FeedbackFormComponent implements OnInit {
  @Output('submitFeedback')
  submitFeedbackEmitter: EventEmitter<FeedbackFormSubmission> =
    new EventEmitter<FeedbackFormSubmission>();

  @Output('screenshotUploaded')
  screenshotUploaded: EventEmitter<Event> = new EventEmitter<Event>();

  @Input('loading')
  loading: boolean = false;

  @Input('filesLoading')
  filesLoading: boolean = false;

  @Input('errorMessage')
  errorMessage: string | null = null;

  @Input('feedback')
  feedback: FeedbackFormSubmission = new FeedbackFormSubmission();

  constructor(private readonly validation: ValidationService) {}

  ngOnInit(): void {}

  submit() {
    this.errorMessage = this.validation.validateFeedback(this.feedback);

    if (!this.errorMessage) {
      this.submitFeedbackEmitter.emit(this.feedback);
    }
  }

  uploadScreenshot($event: any) {
    this.screenshotUploaded.emit($event);
  }
}
