<div *ngIf="prodLoad" class="w-100 d-flex justify-content-center">
  <app-spinner></app-spinner>
</div>

<main
  *ngIf="!prodLoad && product"
  class="page contact-us-page"
  style="position: relative"
>
  <section class="clean-block clean-form dark pt-4" style="padding-bottom: 0px">
    <div class="container">
      <div class="d-flex align-items-center pb-sm-3">
        <svg
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          role="button"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.963657 5.44682C0.618234 5.73468 0.618235 6.26521 0.963657 6.55306L6.31898 11.0158C6.78794 11.4066 7.49991 11.0732 7.49991 10.4627L7.49991 1.53717C7.49991 0.926729 6.78794 0.593257 6.31898 0.984053L0.963657 5.44682Z"
            fill="#08081B"
          />
        </svg>
        <div
          class="small fw-bold ps-sm-1"
          role="button"
          [routerLink]="'/product-list'"
        >
          Back to products
        </div>
      </div>

      <div class="d-flex flex-grow-1 justify-content-between two-field">
        <h3 class="card-title pb-4">
          <strong>Product details</strong>
        </h3>

        <div>
          <div *ngIf="!loading">
            <button
              *ngIf="
                shopService.getShopOwner() !== 'TRAXSIT' && this.givenProductId
              "
              class="btn-outline btn-action"
              (click)="deleteProduct()"
              type="button"
            >
              Delete
            </button>
            &nbsp;&nbsp;
            <button
              class="btn btn-primary product-save-button btn-action"
              (click)="saveProduct()"
              type="button"
            >
              Save
            </button>
          </div>
          <div *ngIf="loading">
            <app-spinner></app-spinner>
          </div>
        </div>
      </div>

      <div class="card mb-5">
        <div class="card-header">
          <h5 class="mb-0">Product image</h5>
        </div>
        <div class="card-body p-4">
          <div class="d-flex flex-grow-1 justify-content-between two-field">
            <div
              class="carousel slide product-carousel"
              data-bs-ride="false"
              style="flex: 1"
            >
              <div class="carousel-inner">
                <ng-container
                  *ngIf="!product.images || product.images.length == 0"
                >
                  <div class="image-container">
                    <div
                      class="d-flex flex-column justify-content-center align-items-center"
                    >
                      <svg
                        class="add-photo-image"
                        width="50"
                        height="49"
                        viewBox="0 0 50 49"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M44.0556 0H5.94444C2.95 0 0.5 2.45 0.5 5.44444V43.5556C0.5 46.55 2.95 49 5.94444 49H44.0556C47.05 49 49.5 46.55 49.5 43.5556V5.44444C49.5 2.45 47.05 0 44.0556 0ZM44.0556 43.5556H5.94444V31.0878L8.63944 33.7828L19.5283 22.8939L30.4172 33.7828L41.3061 22.9211L44.0556 25.6706V43.5556ZM44.0556 17.9394L41.3061 15.19L30.4172 26.1061L19.5283 15.2172L8.63944 26.1061L5.94444 23.3839V5.44444H44.0556V17.9394Z"
                          fill="#EF4136"
                        />
                      </svg>
                      <span class="add-photo-text">Add photo</span>
                    </div>
                    <input
                      (change)="productImageUpload($event)"
                      id="product-image-upload"
                      type="file"
                      class="image-add-button-file"
                    />
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="product.images && product.images.length > 0"
                >
                  <div
                    *ngFor="let element of product.images"
                    class="carousel-item active"
                  >
                    <!--img
                      class="w-100 d-block"
                      [src]="element.link"
                      alt="Slide Image"
                    /-->
                    <div
                      class="image-container"
                      style.backgroundImage="url({{ element.link }})"
                    >
                      <i
                        class="far fa-trash-alt fs-4 delete-item-icon"
                        (click)="removeImage('product')"
                      ></i>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <!--div class="image-add-button" role="button">
              <input
                (change)="productImageUpload($event)"
                id="product-image-upload"
                type="file"
                class="image-add-button-file"
              />
              <div>
                <div class="fs-1 d-flex justify-content-center">+</div>
                <div class="d-flex justify-content-center">Add photo</div>
              </div>
            </div-->
            <!-- <div>
                <a
                  class="carousel-control-prev"
                  role="button"
                  data-bs-slide="prev"
                  ><span class="carousel-control-prev-icon"></span
                  ><span class="visually-hidden">Previous</span></a
                ><a
                  class="carousel-control-next"
                  role="button"
                  data-bs-slide="next"
                  ><span class="carousel-control-next-icon"></span
                  ><span class="visually-hidden">Next</span></a
                >
              </div> -->
            <ol class="carousel-indicators">
              <!-- <li data-bs-target="#" data-bs-slide-to="0" class="active"></li> -->
              <!-- <li data-bs-target="#" data-bs-slide-to="1"></li>
                <li data-bs-target="#" data-bs-slide-to="2"></li> -->
            </ol>
          </div>
          <div class="info-banner pt-2">
            <div>
              <div class="info-banner-content">
                <svg
                  width="13"
                  height="9"
                  viewBox="0 0 13 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.25 4.75L4.75 8.25L11.75 0.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="info-banner-text">
                  Only PNG and JPEG file formats are accepted.
                </div>
              </div>
              <div class="info-banner-content">
                <svg
                  width="13"
                  height="9"
                  viewBox="0 0 13 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.25 4.75L4.75 8.25L11.75 0.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="info-banner-text">
                  The file size is limited to 3MB.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="clean-block clean-form dark" style="padding-bottom: 0px">
    <div class="container">
      <div class="card mb-5">
        <div class="card-header">
          <h5 class="mb-0">Product detail</h5>
        </div>
        <div class="card-body p-4">
          <div
            class="d-flex bottom-flex-grow-1 justify-content-between two-field"
          >
            <div class="d-xl-flex flex-column content-input-group">
              <span class="form-label">*Product Name (required)</span
              ><input
                class="input-group-text-input"
                name="title"
                [(ngModel)]="product.title"
                type="text"
                placeholder="e.g. iPhone 14"
              />
            </div>
            <div class="d-xl-flex flex-column content-input-group mx-md-0">
              <span class="form-label">*Product Code (required)</span
              ><input
                class="input-group-text-input"
                name="productCode"
                [(ngModel)]="product.productCode"
                type="text"
                placeholder="e.g. TX001"
              />
            </div>
          </div>
          <div
            class="d-flex bottom-flex-grow-1 justify-content-between two-field"
          >
            <div
              *ngIf="shopService.getShopOwner() !== 'TRAXSIT'"
              class="d-xl-flex flex-column content-input-group"
            >
              <span class="form-label">*Price In ZAR (required)</span
              ><input
                name="price"
                [(ngModel)]="product.price"
                class="input-group-text-input"
                (change)="validatePrices()"
                type="number"
                placeholder="e.g. 100.00"
              />
            </div>
            <div class="d-xl-flex flex-column content-input-group mx-md-0">
              <span class="form-label">*Type (required)</span
              ><select
                name="type"
                [(ngModel)]="product.type"
                class="form-select input-group-select"
              >
                <option [value]="undefined" selected="">-Select-</option>
                <option value="SUBSCRIPTION" selected="">Subscription</option>
                <option value="BUY-NOW">Buy Now</option>
              </select>
            </div>
          </div>
          <hr />
          <div
            class="d-flex bottom-flex-grow-1 justify-content-between one-field"
          >
            <div class="d-xl-flex flex-column content-input-group mx-md-0">
              <span class="form-label">Description</span
              ><textarea
                name="description"
                [(ngModel)]="product.description"
                maxlength="1000"
                class="text-area input-group-text-input"
                placeholder='e.g. 6.1" Super Retina XDR display with Always On and ProMotion Dynamic Island, a magical new way to interact with iPhone 48 MP main camera for up to 4x higher resolution'
              ></textarea>
            </div>
          </div>
          <div
            class="d-flex bottom-flex-grow-1 justify-content-between two-field"
          >
            <div class="d-xl-flex flex-column content-input-group">
              <span class="form-label">PDF Link</span
              ><input
                class="input-group-text-input"
                type="text"
                name="descriptionPdf"
                [(ngModel)]="product.descriptionPdf"
                placeholder="e.g. https://..."
              />
            </div>
            <div
              *ngIf="!catLoading && categories.length > 0"
              class="d-xl-flex flex-column content-input-group mx-md-0"
            >
              <span class="form-label">Category</span
              ><select
                name="productCode"
                class="input-group-text-input category-select"
                [ngModel]="chosenCategoryId"
                (change)="changeCategory($event)"
              >
                <option [value]="undefined">No Category</option>
                <option
                  *ngFor="let category of categories"
                  [value]="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
          </div>

          <div
            class="d-flex bottom-flex-grow-1 justify-content-between two-field"
          >
            <div
              *ngIf="shopService.getShopOwner() !== 'TRAXSIT'"
              class="d-xl-flex flex-column content-input-group"
            >
              <span class="form-label">Installation Price (R)</span
              ><input
                name="installationPrice"
                [(ngModel)]="product.installationPrice"
                class="input-group-text-input"
                (change)="validatePrices()"
                type="number"
                placeholder="e.g. 100.00"
              />
            </div>
            <div class="d-xl-flex flex-column content-input-group">
              <span class="form-label"
                >Stock Level
                <i
                  *ngIf="product.stockLevel === 'NO_STOCK'"
                  class="fas fa-exclamation-triangle error"
                  style="font-size: 14px"
                ></i>
                <i
                  *ngIf="product.stockLevel === 'LOW_STOCK'"
                  class="fas fa-exclamation-triangle"
                  style="color: orange; font-size: 14px"
                ></i>
              </span>
              <select
                name="type"
                [(ngModel)]="product.stockLevel"
                class="form-select input-group-select"
                [ngClass]="{
                  'out-of-stock': product.stockLevel === 'NO_STOCK'
                }"
              >
                <option [value]="undefined">-Select-</option>
                <option value="NO_STOCK">No Stock</option>
                <option value="LOW_STOCK">Low Stock</option>
                <option value="IN_STOCK">Stock Available</option>
              </select>
            </div>
          </div>
          <div
            class="d-flex bottom-flex-grow-1 justify-content-between two-field"
          >
            <div class="d-xl-flex flex-column content-input-group">
              <span class="form-label">Min Order Quantity</span
              ><input
                name="minOrderQty"
                [(ngModel)]="product.minOrderQuantity"
                class="input-group-text-input"
                (change)="validateOrderQuantities()"
                type="number"
                placeholder="e.g. 1"
              />
            </div>
            <div class="d-xl-flex flex-column content-input-group">
              <span class="form-label">Max Order Quantity</span
              ><input
                name="MaxOrderQty"
                [(ngModel)]="product.maxOrderQuantity"
                class="input-group-text-input"
                (change)="validateOrderQuantities()"
                type="number"
                placeholder="e.g. 100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="clean-block clean-form dark" style="padding-bottom: 0px">
    <div class="container">
      <div class="card mb-5">
        <div class="card-header">
          <h5 class="mb-0">Description image</h5>
        </div>
        <div class="card-body p-4">
          <div class="d-flex flex-grow-1 justify-content-between two-field">
            <div
              class="carousel slide product-carousel"
              data-bs-ride="false"
              style="flex: 1"
            >
              <div class="carousel-inner">
                <ng-container
                  *ngIf="
                    !product.descriptionImages ||
                    product.descriptionImages.length == 0
                  "
                >
                  <div class="image-container">
                    <div
                      class="d-flex flex-column justify-content-center align-items-center"
                    >
                      <svg
                        class="add-photo-image"
                        width="50"
                        height="49"
                        viewBox="0 0 50 49"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M44.0556 0H5.94444C2.95 0 0.5 2.45 0.5 5.44444V43.5556C0.5 46.55 2.95 49 5.94444 49H44.0556C47.05 49 49.5 46.55 49.5 43.5556V5.44444C49.5 2.45 47.05 0 44.0556 0ZM44.0556 43.5556H5.94444V31.0878L8.63944 33.7828L19.5283 22.8939L30.4172 33.7828L41.3061 22.9211L44.0556 25.6706V43.5556ZM44.0556 17.9394L41.3061 15.19L30.4172 26.1061L19.5283 15.2172L8.63944 26.1061L5.94444 23.3839V5.44444H44.0556V17.9394Z"
                          fill="#EF4136"
                        />
                      </svg>
                      <span class="add-photo-text">Add photo</span>
                    </div>
                    <input
                      (change)="descriptionImageUpload($event)"
                      id="description-image-upload"
                      type="file"
                      class="image-add-button-file"
                    />
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="
                    product.descriptionImages &&
                    product.descriptionImages.length > 0
                  "
                >
                  <div
                    *ngFor="let element of product.descriptionImages"
                    class="carousel-item active"
                  >
                    <!--img
                      class="w-100 d-block"
                      [src]="element.link"
                      alt="Slide Image"
                    /-->
                    <div
                      class="image-container"
                      style.backgroundImage="url({{ element.link }})"
                    >
                      <i
                        class="far fa-trash-alt fs-4 delete-item-icon"
                        (click)="removeImage('description')"
                      ></i>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <!--div class="image-add-button" role="button">
              <input
                (change)="descriptionImageUpload($event)"
                id="description-image-upload"
                type="file"
                class="image-add-button-file"
              />
              <div>
                <div class="fs-1 d-flex justify-content-center">+</div>
                <div class="d-flex justify-content-center">Add photo</div>
              </div>
            </div-->
          </div>
          <!-- <div>
              <a
                class="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
                ><span class="carousel-control-prev-icon"></span
                ><span class="visually-hidden">Previous</span></a
              ><a
                class="carousel-control-next"
                role="button"
                data-bs-slide="next"
                ><span class="carousel-control-next-icon"></span
                ><span class="visually-hidden">Next</span></a
              >
            </div> -->
          <ol class="carousel-indicators">
            <!-- <li data-bs-target="#" data-bs-slide-to="0" class="active"></li> -->
            <!-- <li data-bs-target="#" data-bs-slide-to="1"></li>
              <li data-bs-target="#" data-bs-slide-to="2"></li> -->
          </ol>
          <div class="info-banner pt-2">
            <div>
              <div class="info-banner-content">
                <svg
                  width="13"
                  height="9"
                  viewBox="0 0 13 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.25 4.75L4.75 8.25L11.75 0.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="info-banner-text">
                  Only PNG and JPEG file formats are accepted.
                </div>
              </div>
              <div class="info-banner-content">
                <svg
                  width="13"
                  height="9"
                  viewBox="0 0 13 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.25 4.75L4.75 8.25L11.75 0.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="info-banner-text">
                  The file size is limited to 3MB.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="clean-block clean-form dark" style="padding-bottom: 0px">
    <div class="container">
      <div class="card mb-5">
        <div class="card-header">
          <h5 class="mb-0">Additional information</h5>
        </div>
        <div class="card-body p-4">
          <div
            class="d-flex bottom-flex-grow-1 justify-content-between two-field"
          >
            <div class="d-xl-flex flex-column content-input-group">
              <span class="form-label">Heading</span
              ><input
                class="input-group-text-input"
                type="text"
                maxlength="1000"
                [(ngModel)]="product.additionalInfoHeader"
                name="additionalInfoHeader"
                placeholder="e.g. Specifications"
              />
            </div>
            <div class="d-xl-flex flex-column content-input-group">
              <span class="form-label">PDf Link</span
              ><input
                class="input-group-text-input"
                type="text"
                [(ngModel)]="product.additionaInfoPdf"
                name="additionaInfoPdf"
                placeholder="e.g. https://..."
              />
            </div>
          </div>
          <div
            class="d-flex bottom-flex-grow-1 justify-content-between one-field"
          >
            <div class="d-xl-flex flex-column content-input-group mx-md-0">
              <span class="form-label">Text</span
              ><textarea
                class="text-area input-group-text-input"
                placeholder="e.g. 14GB RAM, 48MP Camera"
                [(ngModel)]="product.additionalInformation"
                name="additionalInformation"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="clean-block clean-form dark pb-5" style="padding-bottom: 0px">
    <div class="container">
      <div class="card mb-5">
        <div class="card-header">
          <h5 class="mb-0">Additional info image</h5>
        </div>
        <div class="card-body p-4">
          <div class="d-flex flex-grow-1 justify-content-between two-field">
            <div
              class="carousel slide product-carousel"
              data-bs-ride="false"
              style="flex: 1"
            >
              <div class="carousel-inner">
                <ng-container
                  *ngIf="
                    !product.additionalInfoImages ||
                    product.additionalInfoImages.length == 0
                  "
                >
                  <div class="image-container">
                    <div
                      class="d-flex flex-column justify-content-center align-items-center"
                    >
                      <svg
                        class="add-photo-image"
                        width="50"
                        height="49"
                        viewBox="0 0 50 49"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M44.0556 0H5.94444C2.95 0 0.5 2.45 0.5 5.44444V43.5556C0.5 46.55 2.95 49 5.94444 49H44.0556C47.05 49 49.5 46.55 49.5 43.5556V5.44444C49.5 2.45 47.05 0 44.0556 0ZM44.0556 43.5556H5.94444V31.0878L8.63944 33.7828L19.5283 22.8939L30.4172 33.7828L41.3061 22.9211L44.0556 25.6706V43.5556ZM44.0556 17.9394L41.3061 15.19L30.4172 26.1061L19.5283 15.2172L8.63944 26.1061L5.94444 23.3839V5.44444H44.0556V17.9394Z"
                          fill="#EF4136"
                        />
                      </svg>
                      <span class="add-photo-text">Add photo</span>
                    </div>
                    <input
                      (change)="additionalInformationImageUpload($event)"
                      id="information-image-upload"
                      type="file"
                      class="image-add-button-file"
                    />
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="
                    product.additionalInfoImages &&
                    product.additionalInfoImages.length > 0
                  "
                >
                  <div
                    *ngFor="let element of product.additionalInfoImages"
                    class="carousel-item active"
                  >
                    <!--img
                      class="w-100 d-block"
                      [src]="element.link"
                      alt="Slide Image"
                    /-->
                    <div
                      class="image-container"
                      style.backgroundImage="url({{ element.link }})"
                    >
                      <i
                        class="far fa-trash-alt fs-4 delete-item-icon"
                        (click)="removeImage('additional')"
                      ></i>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <!--div class="image-add-button" role="button">
              <input
                (change)="additionalInformationImageUpload($event)"
                id="information-image-upload"
                type="file"
                class="image-add-button-file"
              />
              <div>
                <div class="fs-1 d-flex justify-content-center">+</div>
                <div class="d-flex justify-content-center">Add photo</div>
              </div>
            </div-->
          </div>
          <!-- <div>
              <a
                class="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
                ><span class="carousel-control-prev-icon"></span
                ><span class="visually-hidden">Previous</span></a
              ><a
                class="carousel-control-next"
                role="button"
                data-bs-slide="next"
                ><span class="carousel-control-next-icon"></span
                ><span class="visually-hidden">Next</span></a
              >
            </div> -->
          <ol class="carousel-indicators">
            <!-- <li data-bs-target="#" data-bs-slide-to="0" class="active"></li> -->
            <!-- <li data-bs-target="#" data-bs-slide-to="1"></li>
              <li data-bs-target="#" data-bs-slide-to="2"></li> -->
          </ol>
          <div class="info-banner pt-2">
            <div>
              <div class="info-banner-content">
                <svg
                  width="13"
                  height="9"
                  viewBox="0 0 13 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.25 4.75L4.75 8.25L11.75 0.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="info-banner-text">
                  Only PNG and JPEG file formats are accepted.
                </div>
              </div>
              <div class="info-banner-content">
                <svg
                  width="13"
                  height="9"
                  viewBox="0 0 13 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.25 4.75L4.75 8.25L11.75 0.75"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="info-banner-text">
                  The file size is limited to 3MB.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="bottom-action-bar">
    <div class="bottom-action-bar-content">
      <div *ngIf="!loading">
        <button
          *ngIf="
            shopService.getShopOwner() !== 'TRAXSIT' && this.givenProductId
          "
          class="btn-outline btn-action"
          (click)="deleteProduct()"
          type="button"
        >
          Delete
        </button>
        &nbsp;&nbsp;
        <button
          class="btn btn-primary product-save-button btn-action"
          (click)="saveProduct()"
          type="button"
        >
          Save
        </button>
      </div>
      <div *ngIf="loading">
        <app-spinner></app-spinner>
      </div>
    </div>
  </div>
</main>
