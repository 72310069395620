<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ onlineAllowed ? "Search Image By description" : "Add Image" }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div *ngIf="onlineAllowed" class="col-md-6">
          <input
            type="search"
            placeholder="Search image online..."
            class="search"
            [disabled]="filesLoading"
            (change)="changeTerm($event)"
          />
        </div>
        <div class="col-md-6 d-flex align-content-center">
          <div class="d-flex flex-column">
            <label class="btn btn-primary upload-button">
              <input
                name="imageUpload"
                (change)="imageUpload($event)"
                type="file"
              />
              Choose From Computer
            </label>
            <sub class="text-muted mt-1">Only PNG & JPG, max 3mb</sub>
          </div>
        </div>
      </div>
    </div>
    <div class="container image-container">
      <div class="row">
        <app-spinner *ngIf="filesLoading"></app-spinner>
        <p *ngIf="images.length == 0 && !filesLoading && onlineAllowed">
          No Results To Show
        </p>
        <p *ngIf="!onlineAllowed">
          Please choose from computer for this image.
        </p>
        <ng-container *ngFor="let image of images">
          <div *ngIf="!filesLoading" class="col">
            <img
              [src]="image.urls.small"
              class="unsplash-image"
              (click)="modal.close(image.urls.regular)"
            />
            <small class="d-block text-muted">By {{ image.user.name }}</small>
          </div>
        </ng-container>
      </div>
    </div>
    <div
      class="row d-flex float-start flex-grow-1 flex-fill justify-content-between"
      style="width: 100%"
    >
      <div class="col">
        <div
          class="d-flex flex-row flex-grow-1 justify-content-between image-pagers"
        >
          <i
            [style.visibility]="this.page == 1 ? 'hidden' : 'visible'"
            (click)="previousPage()"
            class="fas fa-arrow-circle-left pager-icon"
          ></i
          ><i
            *ngIf="images.length > 0"
            (click)="nextPage()"
            class="fas fa-arrow-circle-right pager-icon"
          ></i>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <sub>*All search images are provided by Unsplash and are royalty free*</sub>
  </div>
</ng-template>

<button class="btn btn-primary" (click)="open(content)">
  <span *ngIf="!filesLoading">Choose File</span>
  <app-spinner *ngIf="filesLoading"></app-spinner>
</button>
