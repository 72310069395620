import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentService } from '../../services/content/content.service';
import { Product } from '../../services/models/product';
import { ShopTemplateContent } from '../../services/models/shop-template-content';
import { ProductService } from '../../services/product/product.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { ValidationService } from '../../services/validation/validation.service';
import { LandingPositioner } from './landing-positioner';

const enterTransition = transition(':enter', [
  style({
    opacity: 0,
  }),
  animate('0.5s ease', style({ opacity: 1 })),
]);
const exitTransition = transition(':leave', [
  style({
    opacity: 1,
  }),
  animate('0.5s ease', style({ opacity: 0 })),
]);
const fadeIn = trigger('fadeIn', [enterTransition]);
const fadeOut = trigger('fadeOut', [exitTransition]);

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  animations: [fadeIn, fadeOut],
})
export class LandingComponent implements OnInit {
  constructor(
    private productService: ProductService,
    private contentService: ContentService,
    private readonly validationService: ValidationService
  ) {}

  @Input('content')
  public content: ShopTemplateContent = new ShopTemplateContent();
  @Input('editMode')
  public editMode: boolean = false;
  @Input('activeSlide')
  public activeSlide: number = 0;

  @Output('editItem')
  public editItem: EventEmitter<any> = new EventEmitter<any>();

  message: any | null = null;
  products: Product[] = [];
  positioner: LandingPositioner = new LandingPositioner();

  ngOnInit(): void {
    this.products = this.productService.getAllProductsStatic();
    this.content = this.contentService.content
      ? this.contentService.content
      : new ShopTemplateContent();
    this.positioner.loadPositions(this.content);
  }

  emitEditItem(item: string) {
    if (this.editMode) {
      this.editItem.emit(item);
    }
  }

  objectHasAllValues(object: any) {
    return this.validationService.objectHasAllValues(object);
  }

  moveSectionUp(section: string) {
    this.positioner.moveSection(section, 'up');
    this.saveSectionPositions();
  }

  moveSectionDown(section: string) {
    this.positioner.moveSection(section, 'down');
    this.saveSectionPositions();
  }

  saveSectionPositions() {
    this.content.heroSection.position =
      this.positioner.positions.get('hero') || 1;
    this.content.serviceSection.position =
      this.positioner.positions.get('services') || 2;
    this.content.gallerySection.position =
      this.positioner.positions.get('gallery') || 3;
    this.content.featuredProductsSection.position =
      this.positioner.positions.get('featuredProduct') || 4;
    this.content.aboutUsSection.position =
      this.positioner.positions.get('about') || 5;
  }
}
