<div *ngIf="loading" class="w-100 d-flex justify-content-center">
  <app-spinner></app-spinner>
</div>
<section *ngIf="!loading" class="clean-block clean-product dark pt-5">
  <div class="container" id="product-detail">
    <h2 *ngIf="!product">No product found for this ID</h2>
    <div *ngIf="product" class="block-content">
      <div class="product-info">
        <div class="row">
          <div class="col-md-6">
            <div class="text-white gallery">
              <div class="text-white vanilla-zoom" id="product-preview">
                <div class="zoomed-image">
                  <img
                    *ngIf="product.images && product.images[0]"
                    class="img-fluid d-block mx-auto"
                    [src]="product.images[0].link"
                    style="max-height: 320px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="info">
              <h3 id="product-heading">{{ product.title }}</h3>
              <div
                class="product-checkout-functionality"
                *ngIf="getShopCheckoutEnabled()"
              >
                <p *ngIf="isOutOfStock()">Product is currently out of stock.</p>
                <div *ngIf="!isOutOfStock()" class="price">
                  <h3 style="margin-bottom: 32px">
                    {{ product.price | currency : "R" }}
                    {{ product.type === "SUBSCRIPTION" ? "per month" : null }}
                  </h3>
                  <p *ngIf="+getDeliveryFee() > 0">
                    Delivery Fee:
                    {{ +getDeliveryFee() | currency : "R" }}
                  </p>
                  <p
                    *ngIf="
                      product.installationPrice && product.installationPrice > 0
                    "
                  >
                    Installation Cost: R{{ product.installationPrice }}
                  </p>
                  <div class="product-option-field mb-3">
                    <label class="form-label product-option-label"
                      >Quantity</label
                    >
                    <div class="input-group" style="height: 38px">
                      <input
                        class="form-control form-control-sm"
                        type="number"
                        style="
                          height: 40px;
                          text-align: center;
                          max-width: 75px;
                        "
                        [min]="product.minOrderQuantity"
                        [max]="product.maxOrderQuantity"
                        [disabled]="product.maxOrderQuantity == 1"
                        name="quantity"
                        [(ngModel)]="quantity"
                      />
                    </div>
                  </div>
                  <div
                    *ngIf="product.minOrderQuantity > 1"
                    class="minOrderQuantity"
                  >
                    <sub
                      >Minimum Order Quantity:
                      {{ product.minOrderQuantity }}</sub
                    >
                  </div>
                  <div
                    *ngIf="product.maxOrderQuantity == 1"
                    class="maxOrderQuantity"
                  >
                    <sub
                      >Maximum Order Quantity:
                      {{ product.maxOrderQuantity }}</sub
                    >
                  </div>
                </div>
                <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
                <button
                  class="btn btn-dark"
                  type="button"
                  style="width: 100%; margin-bottom: 12px"
                  (click)="clickAddProductToCartStandard()"
                  *ngIf="!isOutOfStock()"
                >
                  <i class="icon-basket"></i>Add to Cart
                </button>
                <button
                  class="btn btn-dark"
                  type="button"
                  style="width: 100%; margin-bottom: 12px"
                  [routerLink]="'/contact'"
                  *ngIf="isOutOfStock()"
                >
                  Contact Us
                </button>
              </div>
              <div class="summary">
                <p>{{ product.description }}</p>
                <br />
                <div
                  *ngIf="product.descriptionImages"
                  class="product-description mb-5"
                >
                  <img
                    *ngIf="product.descriptionImages[0]"
                    width="100%"
                    [src]="product.descriptionImages[0].link"
                  />
                  <a
                    *ngIf="product.descriptionPdf"
                    [href]="product.descriptionPdf"
                    download
                    >More Information</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          product.additionalInformation ||
          (product.additionalInfoImages &&
            product.additionalInfoImages.length > 0)
        "
        class="product-info"
      >
        <div>
          <ul class="nav nav-tabs" role="tablist" id="myTab">
            <li class="nav-item" role="presentation">
              <a
                class="nav-link active link-dark"
                role="tab"
                data-bs-toggle="tab"
                id="description-tab"
                href="#description"
                >Product Information</a
              >
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active description"
              role="tabpanel"
              id="description"
            >
              <h3 *ngIf="product.additionalInfoHeader">
                {{ product.additionalInfoHeader }}
              </h3>
              <img
                *ngIf="
                  product.additionalInfoImages &&
                  product.additionalInfoImages[0]
                "
                width="100%"
                [src]="product.additionalInfoImages[0].link"
              />
              <p>{{ product.additionalInformation }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
