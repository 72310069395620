import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { CheckoutService } from '../../../services/checkout/checkout.service';
import { Order } from '../../../services/models/order';
import { SessionService } from '../../../services/session/session.service';
import { ShopService } from '../../../services/shop/shop.service';
import { TrackingService } from '../../../services/tracking/tracking.service';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent implements OnInit {
  constructor(
    public checkoutService: CheckoutService,
    private sessionService: SessionService,
    public shopService: ShopService,
    private readonly router: Router,
    private readonly trackingService: TrackingService
  ) {}
  @ViewChild('payForm') payForm: any;
  @ViewChild('paystack') paystack: ElementRef | null = null;

  errorMessage: string | null = null;
  today: string = new Date().toISOString().slice(0, 10);
  shopId: string = this.shopService.shopDetails?.id || '';
  orderRef: string | null = '0';
  uiUrl = environment.UI_URL;
  apiUrl = environment.API_URL;
  loading = false;
  orderCreated: boolean = false;
  ngOnInit(): void {}

  getPhone() {
    return this.checkoutService.order.clientInformation?.phone
      ? this.checkoutService.order.clientInformation?.phone
      : 'N/A';
  }

  getAddress() {
    return `
    ${this.checkoutService.order.deliveryInformation?.addressLine1}
    ${this.checkoutService.order.deliveryInformation?.addressLine2}
    ${this.checkoutService.order.deliveryInformation?.city}
    ${this.checkoutService.order.deliveryInformation?.country}
    ${this.checkoutService.order.deliveryInformation?.postcode}
    `;
  }

  async createOrder() {
    try {
      this.loading = true;
      const response: Order = await this.checkoutService.submitOrder();
      if (response.id) {
        this.checkoutService.order.id = response.id;
        this.orderRef = response.orderRef || 'order';
        // reset the cart session after an order is created
        this.trackingService.trackOrderCreate(this.orderRef);
        this.sessionService.storeItemsOnCart([]);
        this.loading = false;
        this.orderCreated = true;
      }
    } catch (error) {
      this.loading = false;
      this.errorMessage =
        'Failed to submit order. Please contact support on #number';
      console.error('Failed to submit order', error);
    }
  }

  paymentDone($event: any) {
    this.shopService.verifyPayment(this.orderRef);
    this.trackingService.trackPaymentSuccessful(
      this.orderRef || 'none',
      this.checkoutService.calculateOrderTotals().totalOrderDueNow
    );
    this.checkoutService.cart.lineItems = [];
    this.router.navigateByUrl('/payment-complete?success=true');
  }

  paymentCancel() {
    this.trackingService.trackPaymentFailed(this.orderRef || 'none');
    this.router.navigateByUrl(
      '/payment-complete?success=false&order-id=' +
        this.checkoutService.order.id
    );
  }
}
