import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckoutService } from '../../services/checkout/checkout.service';
import { LineItem } from '../../services/models/cart';
import { ShopService } from '../../services/shop/shop.service';
import { TrackingService } from '../../services/tracking/tracking.service';

@Component({
  selector: 'app-cart-edit',
  templateUrl: './cart-edit.component.html',
  styleUrls: ['./cart-edit.component.scss'],
})
export class CartEditComponent implements OnInit {
  constructor(
    public checkoutService: CheckoutService,
    private shopService: ShopService,
    private router: Router,
    private readonly trackingService: TrackingService
  ) {}

  installationPrice = null;
  ngOnInit(): void {
    this.checkoutService.checkoutStep = 0;
  }

  getTotalDueMonthly() {
    return this.checkoutService.calculateOrderTotals().totalOrderDueMonthly;
  }

  getTotalDueNow() {
    return this.checkoutService.calculateOrderTotals().totalOrderDueNow;
  }

  getDeliveryFee() {
    return this.shopService.getDeliveryFee();
  }

  changeLineItemQuantity(event: any, lineItem: LineItem) {
    if (+event.target.value > lineItem.product.maxOrderQuantity) {
      event.target.value = lineItem.product.maxOrderQuantity;
    }
    if (+event.target.value < lineItem.product.minOrderQuantity) {
      event.target.value = lineItem.product.minOrderQuantity;
    }
    if (+event.target.value > 0) {
      lineItem.quantity = +event.target.value;
    } else {
      this.removeLineItem(lineItem);
    }
  }

  removeLineItem(lineItem: LineItem) {
    this.checkoutService.removeLineItem(lineItem);
  }

  proceedToCheckout() {
    this.checkoutService.checkoutStep = 1;
    this.trackingService.trackCheckoutStarted();
    this.router.navigateByUrl('/checkout');
  }

  getBuyNowPrice(lineItem: LineItem): undefined | number {
    if (lineItem.contractLengthInMonths) {
      return lineItem.product.buyNowPrice[lineItem.contractLengthInMonths];
    } else {
      return lineItem.product.price;
    }
  }

  getSubscriptionPrice(lineItem: LineItem): number | undefined {
    if (lineItem.contractLengthInMonths) {
      return lineItem.product.subscriptionPrice[
        lineItem.contractLengthInMonths
      ];
    } else {
      return lineItem.product.price;
    }
  }
}
