import { Injectable } from '@angular/core';
import { Cart, LineItem } from '../models/cart';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  cartStateKey: string = 'user-cart-items';

  storeItemsOnCart(lineItems: LineItem[]) {
    localStorage.setItem(this.cartStateKey, JSON.stringify(lineItems));
  }

  getItemsOnCart(): LineItem[] {
    let data = localStorage.getItem(this.cartStateKey);
    return data ? JSON.parse(data) : [];
  }
}
