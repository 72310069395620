import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { ContentService } from '../../services/content/content.service';
import { Order } from '../../services/models/order';
import { OrderService } from '../../services/order/order.service';
import { ShopService } from '../../services/shop/shop.service';
import { ToasterService } from '../../services/toaster/toaster.service';
import { ValidationService } from '../../services/validation/validation.service';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
})
export class OrderDetailComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    public orderService: OrderService,
    public shopService: ShopService,
    private toaster: ToasterService,
    public contentService: ContentService,
    private validationService: ValidationService
  ) {}
  loading: boolean = true;
  loadingPayRequest: boolean = false;
  statusLoading: boolean = false;
  errorMessage: string | null = null;
  order: Order | null = null;
  sentPayRequest: boolean = false;
  linkOnLoad: string | null = null;
  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((map: any) => {
      if (!map.params['orderId']) {
        console.error('Cannot find order id on the url provided.');
        this.errorMessage = 'Order not found';
        this.loading = false;
        return;
      }

      const orderId = map.params['orderId'];
      this.orderService
        .getOrderById(orderId)
        .pipe(take(1))
        .subscribe({
          next: (order) => {
            if (order) {
              this.order = order;
              this.linkOnLoad = order.deliveryInformation?.trackingLink;
            } else {
              this.errorMessage = 'Order not found';
            }
            this.loading = false;
          },
          error: (err) => {
            console.error(`Cannot find order for id: ${orderId}`, err);
            this.errorMessage = 'Order not found';
            this.loading = false;
          },
        });
    });
  }

  changeOrderStatus(statusEvent: any | null) {
    this.statusLoading = true;
    const status = statusEvent?.target.value;
    if (status && this.order?.id) {
      this.orderService
        .updateOrderStatus(this.order?.id, status)
        .pipe(take(1))
        .subscribe({
          next: (res: any) => {
            if (this.order) {
              this.order.orderStatus = res.orderStatus;
            }
            this.statusLoading = false;
            this.toaster.success(
              'Order updated',
              `Updated order to ${status}`,
              4000
            );
          },
          error: (err) => {
            this.statusLoading = false;

            console.error('Failed to update order status', err);
            this.toaster.error(
              'Failed to update order',
              `Contact system admin for help`
            );
          },
        });
    }
  }

  isOrderPlacedToday() {
    return this.orderService.isOrderPlacedToday(this.order);
  }

  getPaymentInfoUrl(orderRef: string | undefined) {
    return !orderRef
      ? ''
      : 'https://dashboard.paystack.com/#/search?model=transactions&query=' +
          orderRef;
  }

  containsContractLengths(order: Order): any {
    return order.lineItems?.some((item) => !!item.contractLengthInMonths);
  }

  getInstallationCosts(order: Order) {
    // For each line item, if there is a installation and qty then return the
    // sum of (all lineItem installtion costs * quantity)
    return +(
      order.lineItems?.reduce(
        (sum, item) =>
          sum +
          (item.installationPrice && item.quantity
            ? item.installationPrice * item.quantity
            : 0),
        0
      ) || 0
    );
  }

  requestPayment() {
    if (!this.order?.id) {
      return;
    }
    this.loadingPayRequest = true;
    this.sentPayRequest = true;
    this.orderService
      .requestPayment(this.order.id)
      .then((res) => {
        this.toaster.success(
          'Request Sent',
          'Payment request sent to customer'
        );
        this.loadingPayRequest = false;
      })
      .catch((err) => {
        console.error('Failed to send payment request', err);
        this.toaster.error(
          'Request Failed',
          'Failed to send request to customer'
        );
        this.loadingPayRequest = false;
      });
  }

  isValidTrackingLink() {
    const error = this.validationService.validateTrackingLink(
      this.order?.deliveryInformation?.trackingLink
    );
    if (error) {
      this.toaster.error('Tracking link invalid', error);
      if (this.order?.deliveryInformation.trackingLink) {
        this.order.deliveryInformation.trackingLink = this.linkOnLoad;
      }
      return false;
    }
    return true;
  }

  saveTrackingLink() {
    if (this.isValidTrackingLink() && this.order?.id) {
      this.loading = true;
      this.sentPayRequest = false;
      this.orderService
        .saveTrackingLink(
          this.order.id,
          this.order?.deliveryInformation?.trackingLink || null
        )
        .then((res) => {
          this.toaster.success(
            'Tracking link saved',
            'Tracking link saved to customer'
          );
          if (this.order?.deliveryInformation.trackingLink) {
            this.linkOnLoad = this.order.deliveryInformation.trackingLink;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.error('Failed to save tracking link', err);
          this.toaster.error(
            'Failed to save tracking link',
            'Failed to save tracking link to customer'
          );
          this.loading = false;
        });
    }
  }
}
