<main class="page contact-us-page">
  <section class="clean-block clean-form dark pt-5 mb-3">
    <div class="container">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Products</h4>
          <div
            class="product-table-controls d-flex flex-row flex-grow-1 align-items-center table-head mb-4 mt-4"
          >
            <div
              class="d-flex align-items-center input-group-text-input border-50 search-box"
            >
              <input
                class="no-border me-2"
                style="flex: 1"
                (change)="changeTerm($event)"
                type="text"
                placeholder="Search by title (press enter to search)"
              />
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.9 17.3L10.3 11.7C9.8 12.1 9.225 12.4167 8.575 12.65C7.925 12.8833 7.23333 13 6.5 13C4.68333 13 3.146 12.3707 1.888 11.112C0.63 9.85333 0.000666667 8.316 0 6.5C0 4.68333 0.629333 3.146 1.888 1.888C3.14667 0.63 4.684 0.000666667 6.5 0C8.31667 0 9.854 0.629333 11.112 1.888C12.37 3.14667 12.9993 4.684 13 6.5C13 7.23333 12.8833 7.925 12.65 8.575C12.4167 9.225 12.1 9.8 11.7 10.3L17.325 15.925C17.5083 16.1083 17.6 16.3333 17.6 16.6C17.6 16.8667 17.5 17.1 17.3 17.3C17.1167 17.4833 16.8833 17.575 16.6 17.575C16.3167 17.575 16.0833 17.4833 15.9 17.3ZM6.5 11C7.75 11 8.81267 10.5623 9.688 9.687C10.5633 8.81167 11.0007 7.74933 11 6.5C11 5.25 10.5623 4.18733 9.687 3.312C8.81167 2.43667 7.74933 1.99933 6.5 2C5.25 2 4.18733 2.43767 3.312 3.313C2.43667 4.18833 1.99933 5.25067 2 6.5C2 7.75 2.43767 8.81267 3.313 9.688C4.18833 10.5633 5.25067 11.0007 6.5 11Z"
                  fill="#EF4136"
                />
              </svg>
            </div>
            <a
              class="btn btn-primary add-button"
              role="button"
              [ngClass]="{ disabled: loading }"
              (click)="addProduct()"
              >Add a Product</a
            >
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="product-headers">
                <tr>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Product Code</th>
                  <th>Category</th>
                  <th>Type</th>
                  <th>Stock Level</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr class="loader-track" *ngIf="loading">
                  <app-spinner></app-spinner>
                </tr>
                <ng-container *ngIf="!loading">
                  <tr
                    class="text-center product-row"
                    *ngFor="let product of products"
                    [routerLink]="['/edit-product', product.id]"
                  >
                    <td class="id-row">
                      <img
                        *ngIf="product.images && product.images.length > 0"
                        [src]="product.images[0].link"
                      />
                    </td>
                    <td class="title-row table-cell">{{ product.title }}</td>
                    <td class="product-code-row title-row table-cell">
                      {{ product.productCode }}
                    </td>
                    <td class="category-column table-cell">
                      {{ product.category?.name || "-" }}
                    </td>
                    <td class="table-cell">{{ product.type | titlecase }}</td>
                    <td class="table-cell">
                      <i
                        *ngIf="product.stockLevel === 'NO_STOCK'"
                        class="fas fa-exclamation-triangle error"
                        style="font-size: 14px"
                      ></i>
                      <i
                        *ngIf="product.stockLevel === 'LOW_STOCK'"
                        class="fas fa-exclamation-triangle"
                        style="color: orange; font-size: 14px"
                      ></i>
                      <span class="stock-level">{{
                        product.stockLevel.replace("_", " ") | titlecase
                      }}</span>
                    </td>
                    <td class="table-cell">
                      {{ product.price | currency : "R" }}
                    </td>
                  </tr>
                </ng-container>
                <h2 class="p-3" *ngIf="!loading && products.length == 0">
                  No products to display
                </h2>
              </tbody>
            </table>
          </div>
        </div>
        <div class="container mt-3">
          <div class="row">
            <div class="col d-xl-flex justify-content-xl-center">
              <app-paging
                class="m-auto"
                *ngIf="!loading"
                [currentPage]="searchProductFilter.page"
                [totalRecords]="count"
                [pageSize]="searchProductFilter.pageSize"
                (pageChange)="setPage($event)"
              ></app-paging>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="clean-block clean-form dark">
    <div class="container">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Categories (Max 20)</h4>
          <div
            class="product-table-controls d-flex flex-row flex-grow-1 align-items-center table-head mb-4 mt-4"
          >
            <div
              class="d-flex align-items-center input-group-text-input border-50 search-box"
            >
              <input
                class="no-border me-2 category-add"
                [(ngModel)]="categoryToAdd"
                type="text"
                placeholder="Enter category name"
              />
            </div>
            <button
              (click)="addCategory()"
              [ngClass]="{ disabled: catLoading }"
              class="btn btn-primary add-button"
              type="button"
            >
              Create
            </button>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead class="product-headers">
                <tr>
                  <th>Name</th>
                  <th>Products Assigned</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr class="loader-track" *ngIf="catLoading">
                  <app-spinner></app-spinner>
                </tr>
                <ng-container *ngIf="!catLoading">
                  <tr
                    class="text-center category-row"
                    *ngFor="let category of categories"
                  >
                    <td class="category-name-row table-cell" style="width: 50%">
                      {{ category.name }}
                    </td>
                    <td class="products-assigned table-cell">
                      {{ category.productsAssigned }}
                    </td>
                    <td class="table-cell">
                      <button
                        (click)="deleteCategory(category.id)"
                        class="btn btn-danger"
                        type="button"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </ng-container>
                <h2 class="p-3" *ngIf="!catLoading && categories.length == 0">
                  No categories to display
                </h2>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
