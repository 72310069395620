<div class="d-flex flex-column mt-5 text-center">
  <h1>Tutorials & FAQ</h1>
  <p>Check out our tutorials below to help you get started with your shop.</p>
  <iframe
    class="mb-5 mx-auto d-block"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/hHnzRYkZIOU"
    frameborder="0"
    allowfullscreen
  ></iframe>
  <div class="info">
    <p>More tutorials coming soon.</p>
  </div>
</div>
