import { Component } from '@angular/core';

@Component({
  selector: 'app-product-catalogue-view',
  templateUrl: './product-catalogue-view.component.html',
  styleUrls: ['./product-catalogue-view.component.scss']
})
export class ProductCatalogueViewComponent {

}
