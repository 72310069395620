<ng-container *ngIf="layoutType === 'image-right'">
  <div class="container">
    <div class="row">
      <div class="d-flex flex-column col-xs-12 col-md-6 justify-content-center">
        <h2 class="text-center mb-3" [ngStyle]="{ color: sectionTitleColour }">
          {{ title }}
        </h2>
        <p class="text-center" [ngStyle]="{ color: textColour }">
          {{ content }}
        </p>
        <a
          *ngIf="showContactButton()"
          class="btn contactButton"
          role="button"
          [routerLink]="['/contact']"
          [ngStyle]="{
            'background-color':
              contentService.content?.colourPalette?.buttonBackground,
            color: contentService.content?.colourPalette?.buttonText
          }"
          >{{
            contentService.content?.aboutUsSection?.contactCallToActionTitle
          }}</a
        >
      </div>
      <div class="col-xs-12 col-md-6 text-center">
        <img
          class="about-us-image"
          [src]="image"
          [ngStyle]="{ 'height.px': imageHeight }"
        />
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="layoutType === 'image-left'">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-6 text-center">
        <img
          class="about-us-image"
          [src]="image"
          [ngStyle]="{ 'height.px': imageHeight }"
        />
      </div>
      <div class="d-flex flex-column col-xs-12 col-md-6 justify-content-center">
        <h2 class="text-center mb-3" [ngStyle]="{ color: sectionTitleColour }">
          {{ title }}
        </h2>
        <p class="text-center" [ngStyle]="{ color: textColour }">
          {{ content }}
        </p>
        <a
          *ngIf="showContactButton()"
          class="btn contactButton"
          role="button"
          [routerLink]="['/contact']"
          [ngStyle]="{
            'background-color':
              contentService.content?.colourPalette?.buttonBackground,
            color: contentService.content?.colourPalette?.buttonText
          }"
          >{{
            contentService.content?.aboutUsSection?.contactCallToActionTitle
          }}</a
        >
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="layoutType === 'image-top'">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-12 text-center">
        <img
          class="about-us-image"
          [src]="image"
          [ngStyle]="{ 'height.px': imageHeight }"
        />
      </div>
      <div class="d-flex flex-column col-xs-12 col-md-12 mt-5">
        <h2 class="text-center mb-3" [ngStyle]="{ color: sectionTitleColour }">
          {{ title }}
        </h2>
        <p class="top-paragraph" [ngStyle]="{ color: textColour }">
          {{ content }}
        </p>
      </div>
    </div>
    <div class="row">
      <a
        *ngIf="showContactButton()"
        class="btn contactButton"
        role="button"
        [routerLink]="['/contact']"
        [ngStyle]="{
          'background-color':
            contentService.content?.colourPalette?.buttonBackground,
          color: contentService.content?.colourPalette?.buttonText
        }"
        >{{
          contentService.content?.aboutUsSection?.contactCallToActionTitle
        }}</a
      >
    </div>
  </div>
</ng-container>

<ng-container *ngIf="layoutType === 'image-bottom'">
  <div class="container">
    <div class="row">
      <div class="d-flex flex-column col-xs-12 col-md-12 mb-5">
        <h2 class="text-center mb-3" [ngStyle]="{ color: sectionTitleColour }">
          {{ title }}
        </h2>
        <p class="top-paragraph" [ngStyle]="{ color: textColour }">
          {{ content }}
        </p>
        <a
          *ngIf="showContactButton()"
          class="btn contactButton"
          role="button"
          [routerLink]="['/contact']"
          [ngStyle]="{
            'background-color':
              contentService.content?.colourPalette?.buttonBackground,
            color: contentService.content?.colourPalette?.buttonText
          }"
          >{{
            contentService.content?.aboutUsSection?.contactCallToActionTitle
          }}</a
        >
      </div>

      <div class="col-xs-12 col-md-12 text-center">
        <img
          class="about-us-image"
          [src]="image"
          [ngStyle]="{ 'height.px': imageHeight }"
        />
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="layoutType === 'no-image'">
  <div class="container">
    <div class="row">
      <div class="d-flex flex-column col-xs-12 col-md-12">
        <h2 class="text-center mb-3" [ngStyle]="{ color: sectionTitleColour }">
          {{ title }}
        </h2>
        <p class="top-paragraph" [ngStyle]="{ color: textColour }">
          {{ content }}
        </p>
        <a
          *ngIf="showContactButton()"
          class="btn contactButton"
          role="button"
          [routerLink]="['/contact']"
          [ngStyle]="{
            'background-color':
              contentService.content?.colourPalette?.buttonBackground,
            color: contentService.content?.colourPalette?.buttonText
          }"
          >{{
            contentService.content?.aboutUsSection?.contactCallToActionTitle
          }}</a
        >
      </div>
    </div>
  </div>
</ng-container>
