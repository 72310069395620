import { Component, OnInit } from '@angular/core';
import { FeedbackFormSubmission } from '../../components/feedback-form/feedback-submission';
import { ContentService } from '../../services/content/content.service';
import { FeedbackService } from '../../services/feedback/feedback.service';
import { ToasterService } from '../../services/toaster/toaster.service';
import { TrackingService } from '../../services/tracking/tracking.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  constructor(
    private readonly feedbackService: FeedbackService,
    private readonly toasterService: ToasterService,
    private readonly contentService: ContentService,
    private readonly trackingService: TrackingService
  ) {}

  loading: boolean = false;
  filesLoading: boolean = false;
  errorMessage: string | null = null;
  feedback: FeedbackFormSubmission = new FeedbackFormSubmission();
  ngOnInit(): void {}

  submitFeedback(feedback: FeedbackFormSubmission) {
    this.loading = true;
    this.feedbackService
      .sendUserFeedback(feedback)
      .then((response) => {
        console.log('successfully sent user feedback', response);
        this.loading = false;
        this.clearInputs();
        this.trackingService.trackFeedbackGiven();
        this.toasterService.success(
          'Feedback Sent',
          'Thank you for your feedback!',
          4000
        );
      })
      .catch((error) => {
        this.loading = false;
        this.errorMessage =
          'Oops, something went wrong. Please try again later.';
        console.error('Failed to send feedback: ', error);
      });
  }

  uploadScreenShot($event: Event) {
    this.filesLoading = true;
    this.contentService
      .uploadFile(($event as any)?.target?.files[0])
      .then((result: any) => {
        this.feedback.image = result.filePath;
        this.toasterService.success(
          'Screenshot added',
          'Screenshot added successfully',
          4000
        );
        this.filesLoading = false;
      })
      .catch((error: any) => {
        this.toasterService.error('Failed to add screenshot', error);
        this.filesLoading = false;
        console.error('Failed to add screenshot', error);
      });
  }

  clearInputs() {
    this.feedback = new FeedbackFormSubmission();
  }
}
