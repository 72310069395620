<main class="page contact-us-page">
  <section class="clean-block clean-form dark" style="min-height: 700px">
    <div class="container">
      <div class="d-flex flex-column align-items-xxl-center block-heading">
        <h2 class="text-dark">Shop Is currently under construction</h2>
        <i
          [ngStyle]="{
            color: contentService.content?.colourPalette?.icon
          }"
          class="fas fa-hammer closed-icon"
        ></i>
        <div class="flex-wrap" style="width: 100%">
          <p style="text-align: center">
            This shop is currently under construction. If you are a selected
            previewer, please enter the password below and preview the site
            under construction.
          </p>
        </div>
      </div>
      <div
        class="d-flex flex-row flex-grow-1 justify-content-center"
        style="width: 100%"
      >
        <div class="input-group storefront-lock">
          <span class="input-group-text">password</span
          ><input
            name="password"
            [(ngModel)]="password"
            class="form-control"
            type="password"
          /><button
            [ngStyle]="{
              'background-color':
                contentService.content?.colourPalette?.buttonBackground,
              color: contentService.content?.colourPalette?.buttonText
            }"
            class="btn"
            type="button"
            (click)="submit()"
          >
            Preview
          </button>
        </div>
      </div>
    </div>
  </section>
</main>
