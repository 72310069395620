import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { ContentService } from '../../services/content/content.service';
import { Category, Product } from '../../services/models/product';
import { ShopTemplateContent } from '../../services/models/shop-template-content';
import { ProductService } from '../../services/product/product.service';
import { ShopService } from '../../services/shop/shop.service';
import { ToasterService } from '../../services/toaster/toaster.service';
import { ValidationService } from '../../services/validation/validation.service';

@Component({
  selector: 'app-admin-product-detail',
  templateUrl: './admin-product-detail.component.html',
  styleUrls: ['./admin-product-detail.component.scss'],
})
export class AdminProductDetailComponent implements OnInit {
  constructor(
    private readonly productService: ProductService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly toastrService: ToasterService,
    private readonly contentService: ContentService,
    private readonly validationService: ValidationService,
    public readonly shopService: ShopService,
    private readonly router: Router
  ) {}

  //TODO: add multiple images
  product: Product = new Product();
  loading: boolean = false;
  prodLoad: boolean = false;
  givenProductId: string | null = null;
  categories: Category[] = [];
  chosenCategoryId: string | undefined;
  catLoading: boolean = false;

  ngOnInit(): void {
    this.prodLoad = true;
    this.getCategories();
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.givenProductId = paramMap.get('id');
      if (this.givenProductId) {
        this.productService
          .getProductById(this.givenProductId)
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              this.product = res as Product;
              this.chosenCategoryId = this.product.category?.id;
              this.prodLoad = false;
            },
            error: (error) => {
              this.prodLoad = false;
              console.error(error);
            },
          });
      } else {
        this.prodLoad = false;
      }
    });
  }

  changeCategory($event: any): void {
    const categoryId = $event.target.value;
    this.product.category =
      this.categories.find((el) => el.id === categoryId) || null;
    this.chosenCategoryId = categoryId == 'undefined' ? undefined : categoryId;
  }

  deleteProduct() {
    this.loading = true;
    this.productService
      .deleteProduct(this.product.id)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.router.navigateByUrl('product-list');
          this.toastrService.success('Product deleted', undefined, 4000);
          this.loading = false;
        },
        error: (error: any) => {
          this.loading = false;
          this.toastrService.error('Failed to delete product', undefined, 4000);
          console.error(error);
        },
      });
  }

  getCategories() {
    this.catLoading = true;
    this.productService
      .getCategories()
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.categories = res;
          this.catLoading = false;
        },
        error: (error) => {
          this.catLoading = false;
          this.toastrService.error('Failed to load categories for shop');
          console.error(error);
        },
      });
  }

  saveProduct() {
    if (this.shopService.getShopOwner() === 'TRAXSIT') {
      this.product.price = 100;
    }

    const errorMessage = this.isValidProduct();
    if (errorMessage) {
      this.toastrService.error(errorMessage);
      return;
    }
    this.loading = true;
    let query;
    if (this.product.id) {
      query = this.productService.updateProduct(this.product);
    } else {
      query = this.productService.createProduct(this.product);
    }

    query.pipe(take(1)).subscribe({
      next: (res: Product) => {
        this.product = res;
        this.loading = false;
        this.contentService
          .refreshShopContent()
          .then((content: ShopTemplateContent) => {
            this.contentService.content = content;
          });
        this.toastrService.success('Product Saved', undefined, 4000);
        this.router.navigateByUrl('product-list');
      },
      error: (error: any) => {
        this.loading = false;
        console.error(error);
        this.toastrService.error('Failed to save product', error.error.message);
      },
    });
  }

  isValidProduct(): string | null {
    return this.validationService.validProduct(this.product);
  }

  productImageUpload($event: Event) {
    this.loading = true;
    $event.preventDefault();
    this.contentService
      .uploadFile(($event as any)?.target?.files[0])
      .then((result: any) => {
        this.product.images = [];
        this.product.images?.push({ link: result.filePath });
        this.toastrService.success(
          'Image uploaded',
          'Image uploaded successfully',
          4000
        );
        this.loading = false;
      })
      .catch((error: any) => {
        this.loading = false;
        console.error('Failed to upload file for product image', error);
        this.toastrService.error('Failed to upload image', error.message);
      });
  }

  descriptionImageUpload($event: Event) {
    $event.preventDefault();
    this.contentService
      .uploadFile(($event as any)?.target?.files[0])
      .then((result: any) => {
        this.product.descriptionImages = [];
        this.product.descriptionImages?.push({ link: result.filePath });
        this.toastrService.success(
          'Image uploaded',
          'Image uploaded successfully',
          4000
        );
      })
      .catch((error: any) => {
        console.error('Failed to upload file for description image', error);
        this.toastrService.error('Failed to upload image', error);
      });
  }

  additionalInformationImageUpload($event: Event) {
    $event.preventDefault();
    this.contentService
      .uploadFile(($event as any)?.target?.files[0])
      .then((result: any) => {
        this.product.additionalInfoImages = [];
        this.product.additionalInfoImages?.push({ link: result.filePath });
        this.toastrService.success(
          'Image uploaded',
          'Image uploaded successfully',
          4000
        );
      })
      .catch((error: any) => {
        console.error(
          'Failed to upload file for additional information image',
          error
        );
        this.toastrService.error('Failed to upload image', error.error.message);
      });
  }

  validatePrices() {
    if (this.product.price) {
      if (this.product.price > 1000000) {
        this.product.price = 1000000;
      }
      this.product.price = Number(this.product.price.toFixed(2));
    }
    if (this.product.installationPrice) {
      this.product.installationPrice = Number(
        this.product.installationPrice.toFixed(2)
      );
    }
  }

  validateOrderQuantities() {
    if (this.product.minOrderQuantity && this.product.minOrderQuantity <= 0) {
      this.product.minOrderQuantity = 1;
    }

    if (this.product.maxOrderQuantity && this.product.maxOrderQuantity >= 100) {
      this.product.maxOrderQuantity = 100;
    }
  }

  removeImage(type: 'product' | 'description' | 'additional') {
    switch (type) {
      case 'product':
        this.product.images = [];
        break;
      case 'description':
        this.product.descriptionImages = [];
        break;
      case 'additional':
        this.product.additionalInfoImages = [];
        break;

      default:
        break;
    }
  }
}
