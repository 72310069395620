import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '../../services/content/content.service';
import { ShopService } from '../../services/shop/shop.service';
import { ToasterService } from '../../services/toaster/toaster.service';

@Component({
  selector: 'app-shop-locked',
  templateUrl: './shop-locked.component.html',
  styleUrls: ['./shop-locked.component.scss'],
})
export class ShopLockedComponent implements OnInit {
  previewError: string | null = null;

  password: string | null = null;
  constructor(
    private readonly shopService: ShopService,
    private readonly router: Router,
    public readonly contentService: ContentService,
    private readonly toasterService: ToasterService
  ) {}

  ngOnInit(): void {}
  submit() {
    if (
      !this.password ||
      this.password.length < 2 ||
      this.password == '' ||
      this.password == ' '
    ) {
      this.toasterService.error('Please enter a password');
      return;
    }
    if (this.password === this.shopService.shopDetails?.storefrontPassword) {
      this.shopService.previewAllowed = true;
      this.router.navigateByUrl('storefront');
    } else {
      this.toasterService.error('Password incorrect');
    }
  }
}
