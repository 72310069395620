import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShopService } from '../shop/shop.service';
import { environment } from '../../../environments/environment';
import { lastValueFrom } from 'rxjs';
import { ShopTask } from '../models/shop-task';

@Injectable({
  providedIn: 'root',
})
export class ShopTaskService {
  constructor(
    private readonly http: HttpClient,
    private readonly shopService: ShopService
  ) {}
  apiUrl = environment.API_URL;
  shopTasks: ShopTask[] = [];
  async getShopTasks(): Promise<any> {
    if (!this.shopService?.shopDetails?.id) {
      console.error('Cannot get tasks for shop. No shop ID present.');
      throw new Error('Cannt get shop tasks');
    }

    const response = await lastValueFrom(
      this.http.post(this.apiUrl + `/shop/tasks`, {})
    );
    this.shopTasks = response as ShopTask[];
  }

  async updateTask(task: ShopTask | undefined) {
    const taskUpdateRequest = {
      id: task?.id,
      status: task?.status,
    };

    return lastValueFrom(
      this.http.post(this.apiUrl + `/shop/update-task`, taskUpdateRequest)
    );
  }

  allTasksComplete() {
    return this.shopTasks.every((task) => task.status === 'DONE');
  }
}
