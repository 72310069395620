import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentService } from '../../services/content/content.service';
import { LoginEvent } from '../../services/models/events/login-event';

@Component({
  selector: 'app-login-card',
  templateUrl: './login-card.component.html',
  styleUrls: ['./login-card.component.scss'],
})
export class LoginCardComponent implements OnInit {
  @Input()
  loading: boolean = false;

  @Input()
  errorMessage: string | null = null;

  @Output('login')
  loginEventEmitter: EventEmitter<LoginEvent> = new EventEmitter();

  constructor(public contentService: ContentService) {}

  email: string | null = null;
  password: string | null = null;
  ngOnInit(): void {}

  login() {
    this.loginEventEmitter.emit({ email: this.email, password: this.password });
  }

  loginTest() {
    this.loginEventEmitter.emit({ email: 'test', password: 'Test123!' });
  }
}
