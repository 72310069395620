<section class="clean-block clean-form dark">
  <div class="container">
    <div class="block-heading">
      <h2 class="text-dark">Log In</h2>
    </div>
    <form class="border-dark">
      <div class="mb-3">
        <label class="form-label" for="email">Email</label
        ><input
          [(ngModel)]="email"
          name="email"
          class="form-control item"
          type="email"
          id="email"
        />
      </div>
      <button
        class="btn btn-dark w-100"
        role="button"
        [disabled]="loading"
        (click)="forgotPassword()"
        [ngStyle]="{
          'background-color':
            contentService.content?.colourPalette?.buttonBackground,
          color: contentService.content?.colourPalette?.buttonText
        }"
      >
        {{ loading ? "Loading..." : "Send Instructions" }}
      </button>
    </form>
  </div>
</section>
