import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { take } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CustomField, ShopDetails } from '../../services/models/shop-details';
import { ShopService } from '../../services/shop/shop.service';
import { ToasterService } from '../../services/toaster/toaster.service';
import { ValidationService } from '../../services/validation/validation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-shop-settings',
  templateUrl: './shop-settings.component.html',
  styleUrls: ['./shop-settings.component.scss'],
})
export class ShopSettingsComponent implements OnInit, AfterViewInit {
  constructor(
    private readonly shopService: ShopService,
    private readonly validationService: ValidationService,
    private readonly toasterService: ToasterService,
    private readonly route: ActivatedRoute
  ) {}

  @ViewChild('shopButton') shopButton!: ElementRef;
  @ViewChild('checkoutButton') checkoutButton!: ElementRef;
  @ViewChild('accountButton') accountButton!: ElementRef;

  shopDetails: ShopDetails | null = null;
  paymentAppId: string | null = null;
  paymentAppKey: string | null = null;
  loading: boolean = false;
  supportEmail = environment.supportEmail;
  selectedTab: 'shop' | 'checkout' | 'account' = 'shop';

  ngOnInit(): void {
    this.shopDetails = this.shopService.shopDetails;
    if (this.shopDetails?.customField === null) {
      this.shopDetails.customField = new CustomField();
    }
  }

  ngAfterViewInit(): void {
    this.checkForScrollAndActiveTab();
  }

  async saveShopDetails() {
    if (!this.shopDetails) {
      return;
    }

    const validationError = this.validationService.isValidShopDetails(
      this.shopDetails
    );

    if (!validationError) {
      this.loading = true;
      try {
        const updated: ShopDetails = await this.shopService.updateShopDetails({
          ...this.shopDetails,
          adminEmail: this.shopDetails.shopContact.adminEmail,
        });
        this.shopDetails = updated;
        this.loading = false;
        this.toasterService.success('Settings updated', undefined, 4000);
      } catch (err) {
        this.loading = false;
        this.toasterService.error('Failed to update settings');
        console.error(err);
      }
    } else {
      this.toasterService.error(validationError);
      this.loading = false;
    }
  }

  async savePaymentDetails() {
    if (!this.shopDetails) {
      return;
    }
    const validationError = this.validationService.isValidPaymentDetails(
      this.paymentAppId,
      this.paymentAppKey
    );

    if (!validationError) {
      this.loading = true;
      try {
        const result: any = await this.shopService.connectPaymentAccount(
          this.paymentAppId,
          this.paymentAppKey
        );
        this.shopDetails.paymentAppId = result.paymentAppId;
        this.shopDetails.paymentAppKey = result.paymentAppKey;
        this.loading = false;
        this.toasterService.success('Payment Account Connected');
      } catch (err: any) {
        this.loading = false;
        this.toasterService.error(err.error.message);
        console.error(err);
      }
    } else {
      this.toasterService.error(validationError);
    }
  }

  async disconnectAccount() {
    this.loading = true;
    if (!this.shopDetails) {
      return;
    }

    try {
      const result = await this.shopService.disconnectPaymentAccount();
      this.shopDetails.paymentAppId = undefined;
      this.paymentAppId = null;

      this.shopDetails.paymentAppKey = undefined;
      this.paymentAppKey = null;

      this.shopDetails.checkoutEnabled = false;
      await this.saveShopDetails();
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.toasterService.error('Failed to disconnect payment account.');
      console.error(err);
    }
  }

  async updateSeoSettings(value: { keywords: string[]; description: string }) {
    if (this.shopDetails) {
      this.shopDetails.seoKeywords = value.keywords;
      this.shopDetails.seoDescription = value.description;
    }
  }

  validatePrice() {
    if (this.shopDetails) {
      if (this.shopDetails.deliveryFlatRate > 1000000) {
        this.shopDetails.deliveryFlatRate = 1000000;
      }
      this.shopDetails.deliveryFlatRate = Number(
        this.shopDetails?.deliveryFlatRate.toFixed(2)
      );
    }
  }

  isPaymentDetailsSet(): boolean {
    return (
      this.shopDetails?.paymentAppId != null &&
      this.shopDetails.paymentAppKey != null
    );
  }

  checkForScrollAndActiveTab(): void {
    this.route.queryParams.subscribe((params) => {
      const { activeTab, scrollTo } = params;

      if (activeTab && activeTab !== 'null') {
        Promise.resolve().then(() => {
          this.clickTab(activeTab);
        });
      }

      if (scrollTo && scrollTo !== 'null') {
        this.scrollToElement(scrollTo);
      }
    });
  }

  clickTab(tabName: string): void {
    switch (tabName) {
      case 'shop':
        this.shopButton.nativeElement.click();
        break;
      case 'checkout':
        this.checkoutButton.nativeElement.click();
        break;
      case 'account':
        this.accountButton.nativeElement.click();
        break;
      default:
        console.error('Invalid tab name');
    }
  }

  scrollToElement(className: string): void {
    const element = document.querySelector(`.${className}`);

    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }
  }

  goToPaystack() {
    window.open('https://dashboard.paystack.com/#/signup', '_blank');
  }
}
